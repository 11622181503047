import {useEffect , useState , useContext} from 'react'
import { Outlet , useNavigate ,Navigate } from "react-router-dom";
import axios from 'axios';
import AuthContext from '../Context/auth';

const ProtectedRouteUser = ({Role}) => {

    console.log(Role);

    const { AuthUser, setAuthUser } = useContext(AuthContext);


    const navigate = useNavigate();
    const [ValidToken, setValidToken] = useState(null)

    
    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
    const getToken = isJsonString(localStorage.getItem('TokenUser')) ? JSON.parse(localStorage.getItem('TokenUser')) || ''  : '';
    

    console.log(getToken.token);

    const ValidateToken = async () => {
        console.log('run');
        try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/checktoken-user`,{},  {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken?.token}`
                },
            });

            console.log(response);
            if (response['Valid'] == false) {
                setAuthUser(false)
                return navigate('/login')
            }

            if (response['Valid'] == true) {
                // return navigate('/')
                setValidToken(true)
            }else{

                setValidToken(true)


                // setValidToken(false)
               

            }





        } catch (error) {
            setAuthUser(false)
            return navigate('/')
        }
    }

    useEffect(() => {

        if (!getToken) {
            // navigate('/')
            setValidToken(false)
            setAuthUser(false)

            
        }else{
            
            ValidateToken();
        }


    })

    if (ValidToken === null) // waiting..
    return null;



  return (

    
    ValidToken ? <Outlet /> : <Navigate to={"/login"} replace={true} />
  )
}

export default ProtectedRouteUser