import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAlsalam/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';

import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import downloadjs from "downloadjs";
import jsPDF from 'jspdf';

const EtatProjet = () => {

    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            format: 'a4',
            unit: 'pt',
        });

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

        // doc.viewerPreferences({"Direction": "R2L"}, true);
        // doc.setLanguage('ar');


        // Adding the fonts.
        // doc.setFont('Arial', 'normal');
        // doc.addFont("/fonts/ARIAL.ttf", "MyFont", "normal");
        // doc.addFileToVFS("/fonts/ARIAL.ttf", "font");
        // doc.setFont("font")
        doc.addFont("/fonts/hhh.ttf", "hhh", "normal");

        doc.setFont("hhh"); // set font
        doc.addImage("/img/annexe11-1.png", "png", 0, 0, width, height);

        // if (AlsalamList) {

        //     firstPage.drawText(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, {
        //         x: 45,
        //         y: (height / 2) + 295,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        //     firstPage.drawText(`${AlsalamList}`, {
        //         x: 410,
        //         y: (height / 2) + 275,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })


        //     firstPage.drawText(`${reverseNumbers(alsalamListJson?.[AlsalamList]?.['Colonne2'])}`, {
        //         x: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 27 : 80,
        //         y: (height / 2) + 277,
        //         size: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 9 : 11,
        //         font: helveticaFont,
        //         // features: ['rtla'],
        //         // rtl: true
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }

        if (AlsalamList) {

            doc.setFontSize(11);
            doc.text(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, 173, 127, { align: 'right' });
            doc.text(`${AlsalamList}`, 505, 145, { align: 'right' });
            doc.setFontSize(10);
            const lineshh = doc.splitTextToSize(`${alsalamListJson?.[AlsalamList]?.['Colonne2']}`, 330)
            doc.text(lineshh, 308, 145, { align: 'right' });
        }
        if (CatName) {
            doc.text(`${CatName}`, 360, 177, { align: 'left' });
        }
        doc.setFontSize(15);
        if (MaxAmount) {

            doc.text(`${parseFloat(MaxAmount).toLocaleString('fr')}`, 364, 369, { align: 'center' });
        }

        if (Duree) {
            doc.text(`${Duree}`, 233, 391, { align: 'left' });
            doc.text(`شهر`, 200, 391, { align: 'left' });
        }







        //         var text2 = `
        //         - توقيع الوعد باالستئجار.
        // - إمضاء سند ألمر بإجمالي األقساط اإليجارية.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.
        //         - إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله إيداع هامش ضمان الجدية حسبما هو محدد أعاله.

        //         `;

        //         var loremipsum = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id eros turpis. Vivamus tempor urna vitae sapien mollis molestie. Vestibulum in lectus non enim bibendum laoreet at at libero. Etiam malesuada erat sed sem blandit in varius orci porttitor. Sed at sapien urna. Fusce augue ipsum, molestie et adipiscing at, varius quis enim. Morbi sed magna est, vel vestibulum urna. Sed tempor ipsum vel mi pretium at elementum urna tempor. Nulla faucibus consectetur felis, elementum venenatis mi mollis gravida. Aliquam mi ante, accumsan eu tempus vitae, viverra quis justo.\n\nProin feugiat augue in augue rhoncus eu cursus tellus laoreet. Pellentesque eu sapien at diam porttitor venenatis nec vitae velit. Donec ultrices volutpat lectus eget vehicula. Nam eu erat mi, in pulvinar eros. Mauris viverra porta orci, et vehicula lectus sagittis id. Nullam at magna vitae nunc fringilla posuere. Duis volutpat malesuada ornare. Nulla in eros metus. Vivamus a posuere libero.'

        // This line works. Try generating PDF.
        doc.setFontSize(12);
        // doc.setR2L(true)
        doc.text("- توقيع الوعد بالإستئجار", 522, 560, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: true, isInputRtl: true, });
        doc.text("- إمضاء سند لأمر بإجمالي الأقساط الإيجارية", 522, 572, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: true, isInputRtl: true, });

        if (Condition) {
            const lines = doc.splitTextToSize(Condition, 570)
            doc.text(lines, 522, 584, { align: 'right', isSymmetricSwapping: false, isInputVisual: false, isOutputVisual: true, isInputRtl: true, })
            // doc.text(lines, 517, 588, {align: 'right', isSymmetricSwapping: true, isInputVisual: false, isOutputVisual: true,isInputRtl : true ,})
        }

        doc.text(`صفحة 1`, 300, 820, { align: 'center' });



        doc.addPage("a4", "p");
        doc.addImage("/img/annexe11-2.png", "png", 0, 0, width, height);

        // doc.text("Do you like that?", 20, 20);
        doc.text(`صفحة 2`, 300, 820, { align: 'center' });


        doc.save(`Annexe11 ${CatName}.pdf`);
        // doc.html(reportTemplateRef.current, {
        // 	async callback(doc) {
        // 		await 
        // 	}, x: 10,
        //     y: 10,
        //     autoPaging: 'text',
        // });
    };

    async function createPdf3() {


        // Embed our custom font in the document
        // const customFont = await pdfDoc.embedFont(fontBytes)

        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'


        const pdfFileOriginale = `/annexe11.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        pdfDoc.registerFontkit(fontkit)

        const urlFont = `/fonts/ARIALBD 1.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())

        const urlFontAR = `/fonts/Fairuz-Bold.ttf`;
        const fontBytesAR = await fetch(urlFontAR).then((res) => res.arrayBuffer())
        // Register the `fontkit` instance
        // pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFontAR = await pdfDoc.embedFont(fontBytesAR, { subset: true })


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        function reverseNumbers(string) {
            var regex = /\d+(?:\.\d+)?/g;
            var matches = string.match(regex);
            var res = string;
            for (var i = 0; i < matches.length; i++) {
                res = res.replace(matches[i], matches[i].split("").reverse().join(" "))
            }
            return res;
        }




        if (AlsalamList) {

            firstPage.drawText(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, {
                x: 45,
                y: (height / 2) + 295,
                size: 11,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

            firstPage.drawText(`${AlsalamList}`, {
                x: 410,
                y: (height / 2) + 275,
                size: 11,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })


            firstPage.drawText(`${reverseNumbers(alsalamListJson?.[AlsalamList]?.['Colonne2'])}`, {
                x: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 27 : 80,
                y: (height / 2) + 277,
                size: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 8 : 11,
                font: helveticaFont,
                // features: ['rtla'],
                // rtl: true
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (CatName) {

            firstPage.drawText(`${CatName}`, {
                x: 360,
                y: (height / 2) + 244,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        // if (Capital) {

        //     firstPage.drawText(`${Capital}`, {
        //         x: 280,
        //         y: (height / 2) + 244,
        //         size: 8,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        if (MaxAmount) {

            firstPage.drawText(`${MaxAmount}`, {
                x: 300,
                y: (height / 2) + 52,
                size: 13,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        // if (LAssurance) {

        // firstPage.drawText(`00`, {
        //     x: 290,
        //     y: (height / 2) + -18,
        //     size: 13,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })

        // }


        if (Duree) {

            firstPage.drawText(` ${reverseNumbers(Duree)} شهر`, {
                x: 197,
                y: (height / 2) + 30,
                size: 13,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (Condition) {

            firstPage.drawText(`${Condition}`, {
                x: 300,
                y: (height / 2) + -180,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        // let rightText = setPageTextCenter(firstPage, helveticaFont, 10)

        const setTextRight = (page, font, size) => {
            return (text, x, y) => {
                const widthText = font.widthOfTextAtSize(Condition, size)
                const props = {
                    x: x - widthText,
                    y: y,
                    size,
                    font,
               }
               page.drawText(text, props)
            }
        }

        setTextRight(firstPage, helveticaFont, 20)


        // const text = Condition.toString();
        // const fontSize = 8;
        // const textWidth = helveticaFont.widthOfTextAtSize(text, fontSize);
        // const boxWidth = 250;
        // const boxHeight = 100;
        // const boxX = 50;
        // const boxY = 300;

        // //   const page = pdfDoc.addPage([350, 350]);
        // firstPage.drawRectangle({
        //     x: boxX,
        //     y: boxY,
        //     width: boxWidth,
        //     height: boxHeight,
        //     borderColor: rgb(1, 0, 0),
        // });
        // firstPage.drawText(text, {
        //     x: boxX + boxWidth - textWidth,
        //     y: boxY,
        //     font: helveticaFont,
        //     size: fontSize,
        // });


        // if (Salaire) {

        // firstPage.drawText(`09`, {
        //     x: 210,
        //     y: (height / 2) + -60,
        //     size: 13,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })

        // }



        // if (Civilite && (Civilite == 'M' || Civilite == 'Mme' || Civilite == 'Mle')) {

        //     if (Civilite == 'M') {
        //         firstPage.drawText(`/`, {
        //             x: 283,
        //             y: (height / 2) + 4,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })
        //     } else if (Civilite == 'Mme' || Civilite == 'Mle') {

        //         firstPage.drawText(`/`, {
        //             x: 432,
        //             y: (height / 2) + 4,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })

        //     }


        // }

        // if (Nom) {

        //     firstPage.drawText(`${Nom}`, {
        //         x: 72,
        //         y: (height / 2) + -37,
        //         size: 7,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Prenom) {

        //     firstPage.drawText(`${Prenom}`, {
        //         x: 170,
        //         y: (height / 2) + -37,
        //         size: 7,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${new Date(Datedenaissance).toLocaleDateString('fr')}`, {
        //         x: 289,
        //         y: (height / 2) + -37,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }




        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 400,
        //         y: (height / 2) + -37,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // firstPage.drawText(`Algerienne`, {
        //     x: 70,
        //     y: (height / 2) + -77,
        //     size: 9,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 70,
        //         y: (height / 2) + -113,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (CodePostal) {

        //     firstPage.drawText(`${CodePostal}`, {
        //         x: 70,
        //         y: (height / 2) + -153,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Mobile) {

        //     firstPage.drawText(`${Mobile}`, {
        //         x: 70,
        //         y: (height / 2) + -230,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Mail) {

        //     firstPage.drawText(`${Mail}`, {
        //         x: 70,
        //         y: (height / 2) + -270,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (GreenCard && (GreenCard == 'oui' || GreenCard == 'non')) {

        //     if (GreenCard == 'oui') {
        //         firstPage.drawText(`/`, {
        //             x: 297,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })
        //     } else {

        //         firstPage.drawText(`/`, {
        //             x: 430,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })

        //     }

        // }



        // if (Nom && Prenom && Civilite) {

        //     firstPage.drawText(`${Civilite} ${Nom} ${Prenom}`, {
        //         x: 100,
        //         y: (height / 2) + 189,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${Datedenaissance}`, {
        //         x: 290,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 120,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 120,
        //         y: (height / 2) + 66,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `Annexe11 ${CatName}.pdf`, "application/pdf");


        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }



    async function createPdf8() {


        // Embed our custom font in the document
        // const customFont = await pdfDoc.embedFont(fontBytes)

        const Localurl = 'http://localhost:3000/';
        const Onlinelurl = 'http://localhost:3000/'


        const pdfFileOriginale = `/ordre de virement.pdf`
        const existingPdfBytes = await fetch(pdfFileOriginale).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        pdfDoc.registerFontkit(fontkit)

        const urlFont = `/fonts/ARIALBD 1.ttf`;
        const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer())

        const urlFontAR = `/fonts/Fairuz-Bold.ttf`;
        const fontBytesAR = await fetch(urlFontAR).then((res) => res.arrayBuffer())
        // Register the `fontkit` instance
        // pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFontAR = await pdfDoc.embedFont(fontBytesAR, { subset: true })


        // Register the `fontkit` instance
        pdfDoc.registerFontkit(fontkit)
        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(fontBytes, { subset: true })

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page

        function reverseNumbers(string) {
            var regex = /\d+(?:\.\d+)?/g;
            var matches = string.match(regex);
            var res = string;
            for (var i = 0; i < matches.length; i++) {
                res = res.replace(matches[i], matches[i].split("").reverse().join(""))
            }
            return res;
        }




        // if (AlsalamList) {

        //     firstPage.drawText(`${alsalamListJson?.[AlsalamList]?.['Colonne1']}`, {
        //         x: 45,
        //         y: (height / 2) + 295,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        //     firstPage.drawText(`${AlsalamList}`, {
        //         x: 410,
        //         y: (height / 2) + 275,
        //         size: 11,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })


        //     firstPage.drawText(`${reverseNumbers(alsalamListJson?.[AlsalamList]?.['Colonne2']) }`, {
        //         x: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 27 : 58,
        //         y: (height / 2) + 277,
        //         size: alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length > 50 ? 9 : 11,
        //         font: helveticaFont,
        //         // features: ['rtla'],
        //         // rtl: true
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }

        function addSpace(str) {
            return str.split('').join('    ');
        }


        if (Rib) {

            firstPage.drawText(`${addSpace(Rib)}`, {
                x: 48,
                y: (height / 2) + -3,
                size: 10,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (CatName) {

            firstPage.drawText(`${CatName}`, {
                x: 24,
                y: (height / 2) + -51,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })

        }

        if (AllUserInfo?.adresse) {

            firstPage.drawText(`${AllUserInfo?.adresse}`, {
                x: 61,
                y: (height / 2) + -63,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (AllUserInfo?.daira) {

            firstPage.drawText(`${AllUserInfo?.daira}`, {
                x: 260,
                y: (height / 2) + -78,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (AllUserInfo?.commune) {

            firstPage.drawText(`${AllUserInfo?.commune}`, {
                x: 78,
                y: (height / 2) + -78,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (AllUserInfo?.wilaya) {

            firstPage.drawText(`${AllUserInfo?.wilaya}`, {
                x: 67,
                y: (height / 2) + -93,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        if (AllUserInfo?.codepostal) {

            firstPage.drawText(`${AllUserInfo?.codepostal}`, {
                x: 278,
                y: (height / 2) + -93,
                size: 8,
                font: helveticaFont,
                // color: rgb(0.95, 0.1, 0.1),
            })
        }

        firstPage.drawText(`${new Date().toLocaleDateString('fr')}`, {
            x: 320,
            y: (height / 2) + -210,
            size: 9,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })

        firstPage.drawText(`Alger`, {
            x: 220,
            y: (height / 2) + -210,
            size: 9,
            font: helveticaFont,
            // color: rgb(0.95, 0.1, 0.1),
        })

        // if (Capital) {

        //     firstPage.drawText(`${Capital}`, {
        //         x: 280,
        //         y: (height / 2) + 244,
        //         size: 8,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (MaxAmount) {

        //     firstPage.drawText(`${MaxAmount}`, {
        //         x: 300,
        //         y: (height / 2) + 47,
        //         size: 13,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }

        // if (LAssurance) {

        //     firstPage.drawText(`${LAssurance}`, {
        //         x: 290,
        //         y: (height / 2) + -18,
        //         size: 13,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Duree) {

        //     firstPage.drawText(` ${reverseNumbers(Duree)} شهر`, {
        //         x: 180,
        //         y: (height / 2) + 27,
        //         size: 13,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Salaire) {

        //     firstPage.drawText(`${Salaire}`, {
        //         x: 210,
        //         y: (height / 2) + -60,
        //         size: 13,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Civilite && (Civilite == 'M' || Civilite == 'Mme' || Civilite == 'Mle')) {

        //     if (Civilite == 'M') {
        //         firstPage.drawText(`/`, {
        //             x: 283,
        //             y: (height / 2) + 4,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })
        //     } else if (Civilite == 'Mme' || Civilite == 'Mle') {

        //         firstPage.drawText(`/`, {
        //             x: 432,
        //             y: (height / 2) + 4,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })

        //     }


        // }

        // if (Nom) {

        //     firstPage.drawText(`${Nom}`, {
        //         x: 72,
        //         y: (height / 2) + -37,
        //         size: 7,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Prenom) {

        //     firstPage.drawText(`${Prenom}`, {
        //         x: 170,
        //         y: (height / 2) + -37,
        //         size: 7,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${new Date(Datedenaissance).toLocaleDateString('fr')}`, {
        //         x: 289,
        //         y: (height / 2) + -37,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }




        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 400,
        //         y: (height / 2) + -37,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // firstPage.drawText(`Algerienne`, {
        //     x: 70,
        //     y: (height / 2) + -77,
        //     size: 9,
        //     font: helveticaFont,
        //     // color: rgb(0.95, 0.1, 0.1),
        // })



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 70,
        //         y: (height / 2) + -113,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (CodePostal) {

        //     firstPage.drawText(`${CodePostal}`, {
        //         x: 70,
        //         y: (height / 2) + -153,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Mobile) {

        //     firstPage.drawText(`${Mobile}`, {
        //         x: 70,
        //         y: (height / 2) + -230,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Mail) {

        //     firstPage.drawText(`${Mail}`, {
        //         x: 70,
        //         y: (height / 2) + -270,
        //         size: 9,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (GreenCard && (GreenCard == 'oui' || GreenCard == 'non')) {

        //     if (GreenCard == 'oui') {
        //         firstPage.drawText(`/`, {
        //             x: 297,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })
        //     } else {

        //         firstPage.drawText(`/`, {
        //             x: 430,
        //             y: (height / 2) + -302,
        //             size: 12,
        //             font: helveticaFont,

        //             // color: rgb(0.95, 0.1, 0.1),
        //         })

        //     }

        // }



        // if (Nom && Prenom && Civilite) {

        //     firstPage.drawText(`${Civilite} ${Nom} ${Prenom}`, {
        //         x: 100,
        //         y: (height / 2) + 189,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Datedenaissance) {

        //     firstPage.drawText(`${Datedenaissance}`, {
        //         x: 290,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }


        // if (Wilayadenaissance) {

        //     firstPage.drawText(`${Wilayadenaissance}`, {
        //         x: 120,
        //         y: (height / 2) + 129,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }



        // if (Adresse) {

        //     firstPage.drawText(`${Adresse}`, {
        //         x: 120,
        //         y: (height / 2) + 66,
        //         size: 15,
        //         font: helveticaFont,
        //         // color: rgb(0.95, 0.1, 0.1),
        //     })

        // }









        // if (sourcesderevenus && (sourcesderevenus == 'oui' || sourcesderevenus == 'non')) {

        //     firstPage.drawText(`/`, {
        //         x: 221,
        //         y: (height / 2) + 36,
        //         size: 12,
        //         font: helveticaFont,

        //         // color: rgb(0.95, 0.1, 0.1),
        //     })
        // }





        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        downloadjs(pdfBytes, `ORDRE DE VIREMENT ${CatName}.pdf`, "application/pdf");


        // const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        // const url = URL.createObjectURL(blob);
        // window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }

    const ComuneMount = useRef(true);

    const { id } = useParams();
    // const [BoutiqueData, setBoutiqueData] = useState('')

    const [IdDemande, setIdDemande] = useState('')
    const [Status, setStatus] = useState(0)
    const [Mobile, setMobile] = useState('')
    // const [Date, setDate] = useState('');
    const [OldRecuCCPIamge, setOldRecuCCPIamge] = useState('')
    const [AllUserInfo, setAllUserInfo] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/alsalam/getuserinfo/admin/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                console.log(response['data']);
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')
                }
                // setBoutiqueData(response['data'])

                setAllUserInfo(response['data'][0])


                setIdDemande(response['data'][0]['id'])
                setStatus(response['data'][0]['status'] ? response['data'][0]['status'] : 0)
                setMobile(response['data'][0]['mobile'])

                setShortdescription(response['data'][0]['reason_for_rejection'] || '')

                setCompte_de_financement(response['data'][0]['compte_de_financement'] || '')
                setRib(response['data'][0]['compte_epargne_hirfati'] || '')


                setCatName(response['data'][0]['nom'] + " " + response['data'][0]['prenom'])
                // setDate(response['data'][0]['payment_date'] != null ? response['data'][0]['payment_date'] : null)

                setAlsalamList(response['data'][0]['branch_alsalam'] || "فـرع دالـي إبراهيـم")
                setMaxAmount(response['data'][0]['plafond_financier_alsalam'] || '')
                setDuree(response['data'][0]['duree_de_financement_alsalam'] || '')
                setCondition(response['data'][0]['conditions_et_garanties'] || '')


                // setValidé(response['data'][0]['payment_valid'] == 1 ? true : false)


                // if (response['data'][0]['recu_ccp'] !== 'undefined' && response['data'][0]['recu_ccp'] !== null) {
                //     setPreview1(`${process.env.REACT_APP_LOCAL_URL}/images/ccp/${response['data'][0]['recu_ccp']}`)
                //     setOldRecuCCPIamge(response['data'][0]['recu_ccp'])
                //     setImage1(true)
                //     setinitPreview1(true)
                // }


            } catch (error) {
                setLoading(false);
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);






    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();







    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);






    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [CatName, setCatName] = useState('')
    const [Validé, setValidé] = useState(false)
    const [Image1, setImage1] = useState('')
    const [Shortdescription, setShortdescription] = useState('')
    const [Condition, setCondition] = useState('')





    const [preview1, setPreview1] = useState()

    const [initpreview1, setinitPreview1] = useState(false)



    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ status: '', Email: '', Adresse: '', Site: '', Facebook: '', Instagram: '', Heurededébut: '', Heuredefin: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {


        if (Status == 2) {


            if (Shortdescription?.toString().trim().length > 100000 || Shortdescription?.toString().trim() == '') {
                seterrors((prev) => ({ ...prev, Shortdescription: 'Veuillez saisir une petite description' }))
            } else {
                seterrors((prev) => ({ ...prev, Shortdescription: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Shortdescription: '' }))
        }





    }

    useEffect(() => {

        Validate();

    }, [Status, Shortdescription])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    // useEffect(() => {

    //     if (Image1 == true) {
    //         return
    //     }
    //     if (!Image1) {
    //         setPreview1(undefined)
    //         return
    //     }

    //     const objectUrl = URL.createObjectURL(Image1)
    //     setPreview1(objectUrl)

    //     // free memory when ever this component is unmounted
    //     return () => URL.revokeObjectURL(objectUrl)
    // }, [Image1])


    // console.log(Status);



    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.status !== '' || errors.Shortdescription !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 60);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        // const formData = new FormData();

        // // console.log(Status);
        // // return

        // formData.append("status", Status);
        // // formData.append("payment_date", Date);

        // formData.append("motif", Shortdescription);
        // // formData.append("recu_ccp", Image1 || null);

        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/alsalam/update_status/${IdDemande}`, {
            "status": Status, "motif": Shortdescription, "compte_de_financement": Compte_de_financement, "rib": Rib,
            "branch_alsalam": AlsalamList, "plafond_financier_alsalam": MaxAmount, "duree_de_financement_alsalam": Duree, "conditions_et_garanties": Condition
        }, {
            headers: {
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login-alsalam')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Le statut a été modifié avec succès',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location.reload();
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }





    // const DeleteImage = async (type) => {
    //     // setLoading(true);

    //     Swal.fire({
    //         title: 'Êtes-vous sûr',
    //         text: "Êtes-vous sûr de vouloir supprimer cette Image ?!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Oui !',
    //         cancelButtonText: 'No !',
    //         showLoaderOnConfirm: true,
    //         // reverseButtons: true

    //         preConfirm: async () => {
    //             try {
    //                 const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/d_categorie_m/id`, { "type": 'img', "idDeIdDemande": IdDemande }, {
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         'Authorization': `Bearer ${Auth?.token}`
    //                     },
    //                 });
    //                 console.log(response);
    //                 if (response['message'] == 'Token invalid') {
    //                     setAuth(false);
    //                     return navigate('/account')
    //                 }

    //                 if (response['error'] == true) {
    //                     await Swal.fire(
    //                         'Deleted!',
    //                         "L'image n'a pas été supprimée avec succès",
    //                         'error'
    //                     );

    //                 }

    //                 if (response['error'] == false) {


    //                     setPreview1(null)
    //                     setImage1(null)
    //                     setinitPreview1(false)
    //                     setOldRecuCCPIamge('')



    //                     await Swal.fire(
    //                         'Deleted!',
    //                         "L'image a été supprimée avec succès",
    //                         'success'
    //                     );


    //                 }





    //             } catch (error) {
    //                 console.error(error.message);
    //             }
    //         },
    //     })
    //     // .then(async (result) => {
    //     //     if (result.isConfirmed) {
    //     //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);






    //     //     } 
    //     // })


    //     // setLoading(false);
    // }



    const Statutdefinancement = [{ id: 0, name: 'En attente' }, { id: 1, name: 'Validé' }, { id: 2, name: 'Rejeté' }];


    function ClearInputFile() {

        hiddenFileInput1.current.value = "";
        setImage1(null);

    }

    const alsalamListArray = [

        "فـرع القبـة", "فـرع دالـي إبراهيـم", "فـرع بـاب الـزوار", "فـرع البـليـدة", "فـرع حسيـبة", "فـرع سيـدي يحيـى", "فـرع اسطاوالـي"
        , "فـرع العنـاصـر", "فـرع سطيـف", "فـرع قسنطيـنة", "فـرع بـاتنـة", "فـرع عنـابة", "فـرع مسيـلة", "فـرع بجـايـة", "فـرع عيـن مليـلة", "فرع قسنطينة رتاج مول", "فرع برج بوعريريج"
        , "فـرع وهـران", "فـرع وهران العثمانيـة", "فرع الشلف", "فـرع ورقلـة", "فـرع أدرار", "فـرع بسـكرة", "فـرع عيـن وسـارة"

    ]


    const alsalamListJson = {
        "فـرع القبـة": {
            "Colonne1": "بونيل محمد ",
            "Colonne2": "24 جنـان بـن عمـر  القبـة  الجزائر",
            "Colonne3": "فـرع القبـة"
        },
        "فـرع دالـي إبراهيـم": {
            "Colonne1": "احمد ايت يونس ",
            "Colonne2": "233 شـارع أحمـد واكـد  دالـي ابراهيـم  الجزائـر",
            "Colonne3": "فـرع دالـي إبراهيـم"
        },
        "فـرع بـاب الـزوار": {
            "Colonne1": "رمضان قاوج",
            "Colonne2": "تجزئـة بوسحـاقي  ف-رقـم 186 بـاب الـزوّار  الجـزائـر",
            "Colonne3": "فـرع بـاب الـزوار"
        },
        "فـرع البـليـدة": {
            "Colonne1": "كريم درويش",
            "Colonne2": "نهـج محمـد بوضيـاف  ملكيـة مجموعـة رقـم 88 تجزئـة 102 البليـدة",
            "Colonne3": "فـرع البـليـدة"
        },
        "فـرع حسيـبة": {
            "Colonne1": "خليل بلقايد",
            "Colonne2": "حي حسيبـة مجموعـة السكنـــات HLM رقـــــم: 03 بلديـــــة سيــــدي امحمـــــد  الجزائـــر الوسطــى.",
            "Colonne3": "فـرع حسيـبة"
        },
        "فـرع سيـدي يحيـى": {
            "Colonne1": "طارق لزعر ",
            "Colonne2": "04 شـارع حمداني لحسـن سيـدي يحـي  حيـدرة  الجزائر",
            "Colonne3": "فـرع سيـدي يحيـى"
        },
        "فـرع اسطاوالـي": {
            "Colonne1": "ملك شريط",
            "Colonne2": "الطريـق الوطنـي رقـم 11   القطعـة رقـم 402   الملكيـة رقـم 04   اسطاوالـي  الجزائـر",
            "Colonne3": "فـرع اسطاوالـي"
        },
        "فـرع العنـاصـر": {
            "Colonne1": "إبراهيم بن عزي ",
            "Colonne2": "218  شـارع حسيـبة بـن بوعلـي  بلديـة محمـد بلـوزداد الجزائـر العاصمـة",
            "Colonne3": "فـرع العنـاصـر"
        },
        "فـرع سطيـف": {
            "Colonne1": "رؤوف يحي شريف ",
            "Colonne2": "حـي المنـاورات لعرارسـة تجزئـة 143 قطعـة رقـم 11  سطيـف",
            "Colonne3": "فـرع سطيـف"
        },
        "فـرع قسنطيـنة": {
            "Colonne1": "ايناس ميلي ",
            "Colonne2": "نهـج زويـش عمـار رقـم 08  سيـدي مبـروك الأعلـى  قسنطيـنة",
            "Colonne3": "فـرع قسنطيـنة"
        },
        "فـرع بـاتنـة": {
            "Colonne1": "إبراهيم اوراغ ",
            "Colonne2": "حـي المطـار رقـم 240  طريـق بسكـرة  بـاتنـة",
            "Colonne3": "فـرع بـاتنـة"
        },
        "فـرع عنـابة": {
            "Colonne1": "فاروق بابز",
            "Colonne2": "حـي 240 مسكـن  السهـل الغربـي  الحصـة ب رقـم 03  عنابة",
            "Colonne3": "فـرع عنـابة"
        },
        "فـرع مسيـلة": {
            "Colonne1": "سهيل عثماني مارابوت",
            "Colonne2": "حـي 361 قطعـة  تجزئـة رقـم 01  المسيلـة",
            "Colonne3": "فـرع مسيـلة"
        },
        "فـرع بجـايـة": {
            "Colonne1": "بوجمعة مجقون ",
            "Colonne2": "شـارع محفوظـي فـاتح  332 مسكـن عقـاري  إقامـة نوميـديـا عمـارة ب  بجاية",
            "Colonne3": "فـرع بجـايـة"
        },
        "فـرع عيـن مليـلة": {
            "Colonne1": "توفيق حسان",
            "Colonne2": "فـرز 164 قـطعـة (ترقـوي)  طريـق بـاتنـة  أم بواقـي",
            "Colonne3": "فـرع عيـن مليـلة"
        },
        "فرع قسنطينة رتاج مول": {
            "Colonne1": "شهاب الدين بوشيلا",
            "Colonne2": "المـركز التجـاري \"رتـاج مـول\" المدينـة الجديـدة علـي منجلـي رقـم 89  بلديـة الخـروب  قسنطينـة.",
            "Colonne3": "فرع قسنطينة رتاج مول"
        },
        "فرع برج بوعريريج": {
            "Colonne1": "نور الدين ميباركي ",
            "Colonne2": "حـي 05 جويليـة  تجزئـة 475  قطعـة رقـم 01  مجموعـة ملكيـة 54  قسـم 76  بلديـة بـرج بـوعريـريـج",
            "Colonne3": "فرع برج بوعريريج"
        },
        "فـرع وهـران": {
            "Colonne1": "فريد بوجابي ",
            "Colonne2": "تعـاونيـة عدنـان مصطفـى رقم 05  زون ايسـطو  بيـر الجيـر  وهـران",
            "Colonne3": "فـرع وهـران"
        },
        "فـرع وهران العثمانيـة": {
            "Colonne1": "محمد ايت يونس ",
            "Colonne2": "حـي الـعـثـمـانـيـة  الـمـجـمـع السـكـنـي 100 مـسـكـن  عــمـارة ب1  الحـصـة رقـم 134 أ و ب   وهــران",
            "Colonne3": "فـرع وهران العثمانيـة"
        },
        "فرع الشلف": {
            "Colonne1": "محمد بوروبة ",
            "Colonne2": "عمارة 03 مجمع ترقوي, القطب الحضري الجديد بن سونة الشلف.",
            "Colonne3": "فرع الشلف"
        },
        "فـرع ورقلـة": {
            "Colonne1": "محي الدين بن هلال ",
            "Colonne2": "حـي شرفـة  الطريـق الوطنـي رقـم 49  ورقلـة",
            "Colonne3": "فـرع ورقلـة"
        },
        "فـرع أدرار": {
            "Colonne1": "رضا عبد الرحمي ",
            "Colonne2": "شـارع بوزيـدي عبـد القـادر رقـم 145  قطعـة رقـم 30  أدرار",
            "Colonne3": "فـرع أدرار"
        },
        "فـرع بسـكرة": {
            "Colonne1": "لعيد الباح",
            "Colonne2": "حـي سايحـي قطعـة رقـم 69  ملكيـة رقـم 109-110  بسكرة",
            "Colonne3": "فـرع بسـكرة"
        },
        "فـرع عيـن وسـارة": {
            "Colonne1": "حسان سماري ",
            "Colonne2": "حـي المقـرانـي رقـم 01  القطعـة رقم 1309  عيـن وسـارة  الجلفـة",
            "Colonne3": "فـرع عيـن وسـارة"
        }
    }

    const [AlsalamList, setAlsalamList] = useState("فـرع دالـي إبراهيـم")

    const [Mr, setMr] = useState("")
    const [Capital, setCapital] = useState("")
    const [MaxAmount, setMaxAmount] = useState("")
    const [Salaire, setSalaire] = useState("")
    const [LAssurance, setLAssurance] = useState('')
    const [Duree, setDuree] = useState('60')

    const [Rib, setRib] = useState('')
    const [Compte_de_financement, setCompte_de_financement] = useState('')



    const [inputFields, setInputFields] = useState([{
        fullName: '',
    }]);

    const addInputField = () => {
        setInputFields([...inputFields, {
            fullName: '',
        }])

    }
    const removeInputFields = (index) => {
        console.log(index);
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
    }
    const handleChange = (index, evnt) => {

        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);



    }

    console.log(inputFields);








    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Modifier Statut de la commande <span>Iamal</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}

                        <h6>Dossier garanties après financement</h6>
                        <hr />

                        <div className="row text-start" lang="ar">
                            <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">
                                    الفرع
                                </label>
                                <select id="inputState" class="form-select mt-2 me-3" onChange={(e) => setAlsalamList(e.target.value)}>
                                    {/* <option value={'all'} selected={AlsalamList == "all" ? "selected" : ''}>Tous Les Wilayas</option> */}
                                    {alsalamListArray?.length > 0 && alsalamListArray?.map((item, i) => (
                                        <option selected={AlsalamList == item} value={item}>{item}</option>
                                    ))
                                    }
                                </select>
                            </div>
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">
                                    السيد
                                </label>
                                <input maxLength={300} type="text" onChange={(e) => setMr(e.target.value)} value={Mr} className="form-control" id="inputEmail4" />
                               
                            </div> */}
                            <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    السقف المالي
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setMaxAmount(e.target.value.replace(/[^0-9\.]+/g, ''))} value={MaxAmount} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.Mr !== '' ? errors.Mr : ''}</div> */}
                            </div>
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">
                                    رأسمال قدره
                                </label>
                                <input maxLength={300} type="text" onChange={(e) => setCapital(e.target.value)} value={Capital} className="form-control" id="inputEmail4" />
                            </div> */}
                            <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    (بالشهر)  مدة التمويل
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setDuree(e.target.value.replace(/[^0-9]+/g, ''))} value={Duree} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.Mr !== '' ? errors.Mr : ''}</div> */}
                            </div>
                            <div className="col-md-12 mt-4 text-end" dir='rtl'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    الشروط والضمانات
                                </label>
                                <div className="col-md-12">
                                    <div className="form-floating">
                                        <textarea className="form-control" onChange={(e) => setCondition(e.target.value)} value={Condition} placeholder="الشروط والضمانات" id="floatingTextarea2" style={{ height: '300px' }} />
                                        <label htmlFor="floatingTextarea2">
                                            الشروط والضمانات
                                        </label>
                                        {/* <div className='err-cart-sum'>{Showerrors && errors.Shortdescription !== '' ? errors.Shortdescription : ''}</div> */}
                                    </div>
                                </div>


                            </div>
                            {/* <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    (%) التأمينات النقدية
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setLAssurance(e.target.value.replace(/[^0-9]+/g, ''))} value={LAssurance} className="form-control" id="inputEmail4" />
                            </div> */}
                            {/* <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    (%) الأجرة
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setSalaire(e.target.value.replace(/[^0-9]+/g, ''))} value={Salaire} className="form-control" id="inputEmail4" />
                            </div> */}

                            {/* <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                Compte épargne Hirfati
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setRib(e.target.value.replace(/[^0-9]+/g, ''))} value={Rib} className="form-control" id="inputEmail4" />
                            </div> */}
                        </div>

                        {/* <div >
                            {alsalamListJson?.[AlsalamList]?.['Colonne2']}
                            {alsalamListJson?.[AlsalamList]?.['Colonne2']?.toString().length}
                        </div> */}

                       

                        {/* <div className="container">
            <div className="row">
                <div className="col-sm-8">
                  {
                      inputFields.map((data, index)=>{
                          const {fullName, emailAddress, salary}= data;
                          return(
                            <div className="row my-3" key={index}>
                    <div className="col">
                    <div className="form-group">
                    <input type="text" onChange={(evnt)=>handleChange(index, evnt)} value={fullName} name="fullName" className="form-control"  placeholder="Full Name" />
                    </div>
                    </div>
                   
                    <div className="col">
                
                
                 {(inputFields.length!==1)? <button className="btn btn-outline-danger" onClick={() => removeInputFields(index)}>x</button>:''}
                  
                 
                    </div>
                  </div>
                          )
                      })
                  }
     
                <div className="row">
                    <div className="col-sm-12">
                    <button className="btn btn-outline-success " onClick={addInputField}>Add New</button>
                    </div>
                </div>
                  </div>
                </div>
                <div className="col-sm-4">
                </div>
            </div> */}
                        <hr />



                        <form className="row g-5 mb-5 pb-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (
                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom du client</label>
                                <input type="text" readOnly value={CatName} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Mobile</label>
                                <input type="text" readOnly value={Mobile} className="form-control" id="inputEmail4" />
                            </div>

                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Compte de financement Hirfati</label>
                                <input type="text" className="form-control" onChange={(e) => setCompte_de_financement(e.target.value)} value={Compte_de_financement} id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.Position !== '' ? errors.Position : ''}</div> */}

                            </div>
                            <div className="col-md-4 mt-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label mt-2 me-3">
                                    Compte épargne Hirfati
                                </label>
                                <input maxLength={300} type="tel" onChange={(e) => setRib(e.target.value.replace(/[^0-9]+/g, ''))} value={Rib} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.Mr !== '' ? errors.Mr : ''}</div> */}
                            </div>
                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Date de Paiment</label>
                                <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} value={Date} id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                <div className='err-cart-sum'>{Showerrors && errors.Date !== '' ? errors.Date : ''}</div>

                            </div> */}
                            <div className="col-md-6 mb-1">
                                {/* <input type="text" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g , ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" /> */}
                                <label htmlFor="inputEmail4" className="form-label">Statut de financement</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setStatus(e.target.value)}>
                                    <option disabled selected={Status == null}>Statut de financement</option>
                                    {Statutdefinancement?.length > 0 && Statutdefinancement?.map((item, i) => (
                                        <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    ))
                                    }
                                </select>
                                {/* <div className='alert-form'>{Showerr && (Typeactnaissnce == "" ? (infoperssonnelle['msgerr15'][Lang]) : '')}</div> */}
                            </div>

                            {
                                Status == 2 ?
                                    <div className="col-md-12 d-flex justify-content-around flex-wrap">

                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <textarea maxLength={6000} className="form-control" onChange={(e) => setShortdescription(e.target.value)} value={Shortdescription} placeholder="Motif de rejet" id="floatingTextarea2" style={{ height: '100px' }} />
                                                <label htmlFor="floatingTextarea2">Motif de rejet</label>
                                                <div className='err-cart-sum'>{Showerrors && errors.Shortdescription !== '' ? errors.Shortdescription : ''}</div>
                                            </div>
                                        </div>


                                    </div> : ''
                            }

                            {/* 
                            <div className="col-md-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValidé(e.target.checked)} checked={Validé} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div> */}

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Modifier</button>
                            </div>
                        </form>
                   <h6 className='mt-5 pt-5'> imprimer</h6>
                    <hr className='mb-0 mt-0' />
                    <div className="d-flex btn-doc mb-5 mt-3">
                            <div className="donwload-document-btn me-4" onClick={() => handleGeneratePdf()}>ANNEXE 11</div>
                            <div className="donwload-document-btn me-4" onClick={() => createPdf8()}>Ordre De virement</div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default EtatProjet