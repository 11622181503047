import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardUser/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import LoadData from '../../components/Global/LoadData';
import UploadDocuments from './UploadDocuments';
import EditDocuments from './EditDocuments';
import CheckUserSubscribe from '../../components/DashboardUser/CheckUserSubscribe';



const MyDocuments = () => {

    const [Loading, setLoading] = useState(false)
    const [AccountStatus, setAccountStatus] = useState()

    const { AuthUser, setAuthUser } = useContext(AuthContext);
    let navigate = useNavigate();

    const CheckUserDocument = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/client_document_check`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${AuthUser?.token}`
                },
            });

            console.log(response);

            if (response['exist'] == true) {
                setAccountStatus(true)

            } else {
                setAccountStatus(false)
            }

            // console.log(response['errorCode']);
            if (response['error'] == true) {
                // return navigate('/')
            }



            setLoading(false)


        } catch (error) {
            // console.error(error.message);
            setLoading(false)

        }
    }

    useEffect(() => {

        CheckUserDocument();

    }, [])
















    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);














    return (
        <div className='dashboard'>

            {/* {Loading && 
            <Loader />
            } */}




            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Mes Documents <span>Diar Dzair</span></div>
                    {/* <CheckUserSubscribe /> */}
                    <div className='page-content'>

                        {Loading ? <LoadData /> : ''}

                        {!Loading  ? <EditDocuments /> : ''}

                        {/* {!Loading && AccountStatus ? <EditDocuments /> : !AccountStatus && !Loading ?
                            <UploadDocuments /> : ''} */}


                    </div>
                </div>
            </div>

        </div>
    )
}

export default MyDocuments