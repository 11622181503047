import { useState, useEffect, useContext, useRef } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import GoBack from '../../components/Global/GoBack';



const UpdateClientDocuments = () => {

    const { id } = useParams();
    const [ProductData, setProductData] = useState('')

    const [searchParams, setSearchParams] = useSearchParams();
    const _ROLE = searchParams.get('role');

    const [AccountStatus, setAccountStatus] = useState()

    const [progress, setProgress] = useState({ 'carte_identite': 0, 'carte_identite_verso': 0, 'acte_de_naissance': 0, 'fiche_residence': 0, 'fiche_familiale': 0, 'fiche_familiale_verso': 0, 'extrait_de_comptes': 0, 'attestation_de_travail': 0, 'fiches_de_paie': 0, 'fiches_de_paie_2': 0, 'fiches_de_paie_3': 0, 'photo': 0, 'echeancier': 0, 'cheque_barre': 0, 'carte_dartisanat': 0, 'registre_de_commerce': 0, 'diplome': 0, 'documents_supplementaires': 0, 'affiliation_cnas': 0, 'releve_de_compte_un_ans': 0, 'justificatifs_des_credits_1': 0, 'justificatifs_des_credits_2': 0, 'justificatifs_des_credits_3': 0, 'justificatifs_des_credits_4': 0, 'autres_documents_1': 0, 'autres_documents_2': 0 , 'releve_de_compte_2': 0 , 'releve_de_compte_3': 0 })
    const [StartUpload, setStartUpload] = useState({ 'carte_identite': null, 'carte_identite_verso': null, 'acte_de_naissance': null, 'fiche_residence': null, 'fiche_familiale': null, 'fiche_familiale_verso': null, 'extrait_de_comptes': null, 'attestation_de_travail': null, 'fiches_de_paie': null, 'fiches_de_paie_2': null, 'fiches_de_paie_3': null, 'photo': null, 'echeancier': null, 'cheque_barre': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'documents_supplementaires': null, 'affiliation_cnas': null, 'releve_de_compte_un_ans': null, 'justificatifs_des_credits_1': null, 'justificatifs_des_credits_2': null, 'justificatifs_des_credits_3': null, 'justificatifs_des_credits_4': null, 'autres_documents_1': null, 'autres_documents_2': null , 'releve_de_compte_2': null , 'releve_de_compte_3': null })

    const [ResponseMsgSuccsess, setResponseMsgSuccsess] = useState({ 'carte_identite': null, 'carte_identite_verso': null, 'acte_de_naissance': null, 'fiche_residence': null, 'fiche_familiale': null, 'fiche_familiale_verso': null, 'extrait_de_comptes': null, 'attestation_de_travail': null, 'fiches_de_paie': null, 'fiches_de_paie_2': null, 'fiches_de_paie_3': null, 'photo': null, 'echeancier': null, 'cheque_barre': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'documents_supplementaires': null, 'affiliation_cnas': null, 'releve_de_compte_un_ans': null, 'justificatifs_des_credits_1': null, 'justificatifs_des_credits_2': null, 'justificatifs_des_credits_3': null, 'justificatifs_des_credits_4': null, 'autres_documents_1': null, 'autres_documents_2': null , 'releve_de_compte_2': null , 'releve_de_compte_3': null })
    const [ResponseMsgFail, setResponseMsgFail] = useState({ 'carte_identite': null, 'carte_identite_verso': null, 'acte_de_naissance': null, 'fiche_residence': null, 'fiche_familiale': null, 'fiche_familiale_verso': null, 'extrait_de_comptes': null, 'attestation_de_travail': null, 'fiches_de_paie': null, 'fiches_de_paie_2': null, 'fiches_de_paie_3': null, 'photo': null, 'echeancier': null, 'cheque_barre': null, 'carte_dartisanat': null, 'registre_de_commerce': null, 'diplome': null, 'documents_supplementaires': null, 'affiliation_cnas': null, 'releve_de_compte_un_ans': null, 'justificatifs_des_credits_1': null, 'justificatifs_des_credits_2': null, 'justificatifs_des_credits_3': null, 'justificatifs_des_credits_4': null, 'autres_documents_1': null, 'autres_documents_2': null , 'releve_de_compte_2': null , 'releve_de_compte_3': null })

    const [PaymentDcumentsStatus, setPaymentDcumentsStatus] = useState(false)
    const [PaymentDcumentsTypeOnline, setPaymentDcumentsTypeOnline] = useState(false)

    const [Name, setName] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Payment, setPayment] = useState('')
    const [IdDemande, setIdDemande] = useState('')


    const carte_identiteRef = useRef(null);
    const carte_identite_versoRef = useRef(null);
    const acte_de_naissanceRef = useRef(null);
    const fiche_residenceRef = useRef(null);
    const fiche_familialeRef = useRef(null);
    const fiche_familiale_versoRef = useRef(null);
    const extrait_de_comptesRef = useRef(null);
    const attestation_de_travailRef = useRef(null);
    const fiches_de_paieRef = useRef(null);
    const fiches_de_paie_2Ref = useRef(null);
    const fiches_de_paie_3Ref = useRef(null);
    const photoRef = useRef(null);
    const echeancierRef = useRef(null);
    const cheque_barreRef = useRef(null);
    const carte_dartisanatRef = useRef(null);
    const registre_de_commerceRef = useRef(null);
    const diplomeRef = useRef(null);
    const documents_supplementairesRef = useRef(null);
    const affiliation_cnasRef = useRef(null);
    const releve_de_compte_un_ansRef = useRef(null);
    const justificatifs_des_credits_1Ref = useRef(null);
    const justificatifs_des_credits_2Ref = useRef(null);
    const justificatifs_des_credits_3Ref = useRef(null);
    const justificatifs_des_credits_4Ref = useRef(null);
    const autres_documents_1Ref = useRef(null);
    const autres_documents_2Ref = useRef(null);

    const releve_de_compte_2Ref = useRef(null);
    const releve_de_compte_3Ref = useRef(null);


    // const VerifyPaymentDocuments = async () => {
    //     setLoading(true)
    //     try {
    //         const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendeur/verifypaymentdossierstatus`, {}, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${Auth?.token}`

    //             },
    //         });
    //         console.log(response);

    //         // console.log(response['errorCode']);

    //         if (response['payment_valid'] == true) {
    //            setPaymentDcumentsStatus(true)
    //         }



    //         setLoading(false)

    //         if (response['payment_dossier_methode'] == "2") {
    //            setPaymentDcumentsTypeOnline(true)

    //         }

    //         if (response['payment_dossier_methode'] == "3") {
    //             setPaymentDcumentsTypeOnline(true)

    //         }


    //     } catch (error) {
    //         // console.error(error.message);
    //         setLoading(false)


    //     }
    // }

    // useEffect(() => {

    //     VerifyPaymentDocuments();

    // }, [])
    const [Secteur, setSecteur] = useState(0)

    const ValidateToken = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/client_document_check_admin/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Auth?.token}`
                },
            });

            console.log(response);

            if (response['exist'] == true) {
                setAccountStatus(true)

                setName(response?.data?.name || '')
                setMobile(response?.data?.mobile || '')
                setPayment(response?.data?.payment_dossier_valid || '')
                setIdDemande(response?.data?.id_demande || '')


                setOldCarteidentité(response?.data?.carte_identite || '')
                setOldCarteidentitéVerso(response?.data?.carte_identite_verso || '')
                setOldActedenaissance(response?.data?.acte_de_naissance || '')
                setOldResidence(response?.data?.fiche_residence || '')
                setOldFicheFamiliale(response?.data?.fiche_familiale || '')
                setOldFicheFamilialeVerso(response?.data?.fiche_familiale_verso || '')
                setOldExtraitdeCompte(response?.data?.extrait_de_comptes || '')
                setOldAttestationdetravail(response?.data?.attestation_de_travail || '')
                setOldFichesdePaie(response?.data?.fiches_de_paie || '')
                setOldFichesdePaie2(response?.data?.fiches_de_paie_2 || '')
                setOldFichesdePaie3(response?.data?.fiches_de_paie_3 || '')
                setOldPhoto(response?.data?.photo || '')
                setOldEcheancier(response?.data?.echeancier || '')
                setOldChèque(response?.data?.cheque_barre || '')
                setOldCarteDartisanat(response?.data?.carte_dartisanat || '')
                setOldRegistreDeCommerce(response?.data?.registre_de_commerce || '')
                setOldDiplome(response?.data?.diplome || '')
                setOldDocumentsSupplémentaires(response?.data?.documents_supplementaires || '')
                setOldAffiliationCNAS(response?.data?.affiliation_cnas || '')
                setOldRelevedecompte_une_annee(response?.data?.releve_de_compte_un_ans || '')
                setOldJustificatifsdescrédits(response?.data?.justificatifs_des_credits_1 || '')
                setOldJustificatifsdescrédits_2(response?.data?.justificatifs_des_credits_2 || '')
                setOldJustificatifsdescrédits_3(response?.data?.justificatifs_des_credits_3 || '')
                setOldJustificatifsdescrédits_4(response?.data?.justificatifs_des_credits_4 || '')
                setOldAutresdocuments_1(response?.data?.autres_documents_1 || '')
                setOldAutresdocuments_2(response?.data?.autres_documents_2 || '')


                setOldRelevédecompte2(response?.data?.releve_de_compte_2 || '')
                setOldRelevédecompte3(response?.data?.releve_de_compte_3 || '')

                setSecteur(response?.data?.secteur || 0)


            } else {
                setAccountStatus(false)
            }

            // console.log(response['errorCode']);
            if (response['error'] == true) {
                // return navigate('/')
            }



            setLoading(false)


        } catch (error) {
            // console.error(error.message);
            setLoading(false)

        }
    }

    useEffect(() => {

        ValidateToken();

    }, [])



    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)



    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Carteidentité, setCarteidentité] = useState('');
    const [CarteidentitéVerso, setCarteidentitéVerso] = useState('');
    const [Actedenaissance, setActedenaissance] = useState('')
    const [Residence, setResidence] = useState('');
    const [FicheFamiliale, setFicheFamiliale] = useState('');
    const [FicheFamilialeVerso, setFicheFamilialeVerso] = useState('');
    const [ExtraitdeCompte, setExtraitdeCompte] = useState('');
    const [Attestationdetravail, setAttestationdetravail] = useState('');
    const [FichesdePaie, setFichesdePaie] = useState('');
    const [FichesdePaie2, setFichesdePaie2] = useState('');
    const [FichesdePaie3, setFichesdePaie3] = useState('');
    const [Photo, setPhoto] = useState('');
    const [Echeancier, setEcheancier] = useState('');
    const [Chèque, setChèque] = useState('');
    const [CarteDartisanat, setCarteDartisanat] = useState('');
    const [RegistreDeCommerce, setRegistreDeCommerce] = useState('');
    const [Diplome, setDiplome] = useState('');
    const [DocumentsSupplémentaires, setDocumentsSupplémentaires] = useState('');
    const [AffiliationCNAS, setAffiliationCNAS] = useState('');
    const [Relevedecompte_une_annee, setRelevedecompte_une_annee] = useState('');
    const [Justificatifsdescrédits, setJustificatifsdescrédits] = useState('');
    const [Justificatifsdescrédits_2, setJustificatifsdescrédits_2] = useState('');
    const [Justificatifsdescrédits_3, setJustificatifsdescrédits_3] = useState('');
    const [Justificatifsdescrédits_4, setJustificatifsdescrédits_4] = useState('');
    const [Autresdocuments_1, setAutresdocuments_1] = useState('');
    const [Autresdocuments_2, setAutresdocuments_2] = useState('');

    const [Relevédecompte2 , setRelevédecompte2 ] = useState('')
    const [Relevédecompte3 , setRelevédecompte3 ] = useState('')




    const [OldCarteidentité, setOldCarteidentité] = useState('');
    const [OldCarteidentitéVerso, setOldCarteidentitéVerso] = useState('');
    const [OldActedenaissance, setOldActedenaissance] = useState('')
    const [OldResidence, setOldResidence] = useState('');
    const [OldFicheFamiliale, setOldFicheFamiliale] = useState('');
    const [OldFicheFamilialeVerso, setOldFicheFamilialeVerso] = useState('');
    const [OldExtraitdeCompte, setOldExtraitdeCompte] = useState('');
    const [OldAttestationdetravail, setOldAttestationdetravail] = useState('');
    const [OldFichesdePaie, setOldFichesdePaie] = useState('');
    const [OldFichesdePaie2, setOldFichesdePaie2] = useState('');
    const [OldFichesdePaie3, setOldFichesdePaie3] = useState('');
    const [OldPhoto, setOldPhoto] = useState('');
    const [OldEcheancier, setOldEcheancier] = useState('');
    const [OldChèque, setOldChèque] = useState('');
    const [OldCarteDartisanat, setOldCarteDartisanat] = useState('');
    const [OldRegistreDeCommerce, setOldRegistreDeCommerce] = useState('');
    const [OldDiplome, setOldDiplome] = useState('');
    const [OldDocumentsSupplémentaires, setOldDocumentsSupplémentaires] = useState('');
    const [OldAffiliationCNAS, setOldAffiliationCNAS] = useState('');
    const [OldRelevedecompte_une_annee, setOldRelevedecompte_une_annee] = useState('');
    const [OldJustificatifsdescrédits, setOldJustificatifsdescrédits] = useState('');
    const [OldJustificatifsdescrédits_2, setOldJustificatifsdescrédits_2] = useState('');
    const [OldJustificatifsdescrédits_3, setOldJustificatifsdescrédits_3] = useState('');
    const [OldJustificatifsdescrédits_4, setOldJustificatifsdescrédits_4] = useState('');
    const [OldAutresdocuments_1, setOldAutresdocuments_1] = useState('');
    const [OldAutresdocuments_2, setOldAutresdocuments_2] = useState('');

    const [OldRelevédecompte2 , setOldRelevédecompte2 ] = useState('')
    const [OldRelevédecompte3 , setOldRelevédecompte3 ] = useState('')


    const [Loading, setLoading] = useState(false)
    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])
    const [Showerrors, setShowerrors] = useState(false)


    const recaptchaRef = useRef('');
    const [CaptchaChecked, setCaptchaChecked] = useState(false)
    const [captchaerror, setcaptchaerror] = useState('')



    function Validate() {

        if (Carteidentité == '' || Carteidentité.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Carteidentité: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Carteidentité: '' }))
        }

        if (CarteidentitéVerso == '' || CarteidentitéVerso.size > 8000000) {
            // seterrors((prev) => ({ ...prev, CarteidentitéVerso: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, CarteidentitéVerso: '' }))
        }


        if (Actedenaissance == '' || Actedenaissance.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Actedenaissance: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Actedenaissance: '' }))
        }

        if (Residence == '' || Residence.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Residence: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Residence: '' }))
        }


        if (FicheFamiliale == '' || FicheFamiliale.size > 8000000) {
            // seterrors((prev) => ({ ...prev, FicheFamiliale: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FicheFamiliale: '' }))
        }

           
        if (FicheFamilialeVerso == '' || FicheFamilialeVerso.size > 8000000) {
            // seterrors((prev) => ({ ...prev, FicheFamilialeVerso: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FicheFamilialeVerso: '' }))
        }


        if (ExtraitdeCompte == '' || ExtraitdeCompte.size > 8000000) {
            // seterrors((prev) => ({ ...prev, ExtraitdeCompte: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, ExtraitdeCompte: '' }))
        }

        if (Attestationdetravail == '' || Attestationdetravail.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Attestationdetravail: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Attestationdetravail: '' }))
        }

        if (FichesdePaie == '' || FichesdePaie.size > 8000000) {
            // seterrors((prev) => ({ ...prev, FichesdePaie: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FichesdePaie: '' }))
        }

        if (FichesdePaie2 == '' || FichesdePaie2.size > 8000000) {
            // seterrors((prev) => ({ ...prev, FichesdePaie2: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FichesdePaie2: '' }))
        }
        if (FichesdePaie3 == '' || FichesdePaie3.size > 8000000) {
            // seterrors((prev) => ({ ...prev, FichesdePaie3: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, FichesdePaie3: '' }))
        }

        if (Photo == '' || Photo.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Photo: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Photo: '' }))
        }
        if (Echeancier == '' || Echeancier.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Echeancier: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Echeancier: '' }))
        }
        if (Chèque == '' || Chèque.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Chèque: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Chèque: '' }))
        }


        if (CarteDartisanat == '' || CarteDartisanat.size > 8000000) {
            // seterrors((prev) => ({ ...prev, CarteDartisanat: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, CarteDartisanat: '' }))
        }
        if (RegistreDeCommerce == '' || RegistreDeCommerce.size > 8000000) {
            // seterrors((prev) => ({ ...prev, RegistreDeCommerce: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, RegistreDeCommerce: '' }))
        }
        if (Diplome == '' || Diplome.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Diplome: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Diplome: '' }))
        }
        if (DocumentsSupplémentaires == '' || DocumentsSupplémentaires.size > 8000000) {
            // seterrors((prev) => ({ ...prev, DocumentsSupplémentaires: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, DocumentsSupplémentaires: '' }))
        }


        if (AffiliationCNAS == '' || AffiliationCNAS.size > 8000000) {
            // seterrors((prev) => ({ ...prev, AffiliationCNAS: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, AffiliationCNAS: '' }))
        }

        if (Relevedecompte_une_annee == '' || Relevedecompte_une_annee.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Relevedecompte_une_annee: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Relevedecompte_une_annee: '' }))
        }

        if (Justificatifsdescrédits == '' || Justificatifsdescrédits.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Justificatifsdescrédits: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Justificatifsdescrédits: '' }))
        }

        if (Justificatifsdescrédits_2 == '' || Justificatifsdescrédits_2.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Justificatifsdescrédits_2: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Justificatifsdescrédits_2: '' }))
        }

        
        if (Justificatifsdescrédits_3 == '' || Justificatifsdescrédits_3.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Justificatifsdescrédits_3: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Justificatifsdescrédits_3: '' }))
        }

           
        if (Justificatifsdescrédits_4 == '' || Justificatifsdescrédits_4.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Justificatifsdescrédits_4: "Le fichier requis est obligatoire et ne doit pas dépasser 4 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Justificatifsdescrédits_4: '' }))
        }

             
        if (Autresdocuments_1 == '' || Autresdocuments_1.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Autresdocuments_1: "Le fichier requis est obligatoire et ne doit pas dépasser 4 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Autresdocuments_1: '' }))
        }

        if (Autresdocuments_2 == '' || Autresdocuments_2.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Autresdocuments_2: "Le fichier requis est obligatoire et ne doit pas dépasser 4 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Autresdocuments_2: '' }))
        }

        if (Relevédecompte2 == '' || Relevédecompte2.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Relevédecompte2: "Le fichier requis est obligatoire et ne doit pas dépasser 4 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Relevédecompte2: '' }))
        }

        if (Relevédecompte3 == '' || Relevédecompte3.size > 8000000) {
            // seterrors((prev) => ({ ...prev, Relevédecompte3: "Le fichier requis est obligatoire et ne doit pas dépasser 4 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Relevédecompte3: '' }))
        }


    }


    useEffect(() => {

        Validate();

    }, [Relevédecompte2, Relevédecompte3,Carteidentité, CarteidentitéVerso, Actedenaissance, Residence, FicheFamiliale,FicheFamilialeVerso, ExtraitdeCompte, Attestationdetravail, FichesdePaie, FichesdePaie2, FichesdePaie3, Photo, Echeancier, Chèque, CarteDartisanat, RegistreDeCommerce, Diplome, DocumentsSupplémentaires, AffiliationCNAS, Relevedecompte_une_annee,Justificatifsdescrédits,Justificatifsdescrédits_2,Justificatifsdescrédits_3,Justificatifsdescrédits_4,Autresdocuments_1,Autresdocuments_2])

    useEffect(() => {

        // if (Showerrors) {
        //     const matches = document.querySelectorAll(".err-cart-sum");

        //     for (let index = 0; index < matches.length; index++) {

        //         if (matches[index].textContent.trim() !== '') {

        //             let position = matches[index].getBoundingClientRect();


        //             window.scrollTo(position.left, position.top + window.scrollY - 120);
        //             return
        //         }
        //         // if (matches[index].textContent.trim() !== '') {

        //         //     matches[index].scrollIntoView({behavior: "smooth"});
        //         //     return
        //         // }              
        //     }
        // }


    }, [Showerrors])



    function Register(e, name, value, errText) {

        e.preventDefault();

        setShowerrors(true)

        setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))
        setResponseMsgFail((prev) => ({ ...prev, [name]: null }))


        if (value == '' || value.size > 8000000) {
            seterrors((prev) => ({ ...prev, [errText]: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
            const matches = document.querySelectorAll(".err-cart-sum");
            setLoading(false)


            // for (let index = 0; index < matches.length; index++) {

            //     if (matches[index].textContent.trim() !== '') {

            //         let position = matches[index].getBoundingClientRect();


            //         window.scrollTo(position.left, position.top + window.scrollY - 120);
            //         return
            //     }
            // }

            console.log(errors);
            return;
        } else {
            seterrors((prev) => ({ ...prev, [errText]: '' }))
        }
        console.log('hhhhhhhhhh');

        setLoading(true)

        console.log(errors["Attestationdetravail"]);

        // if (errors[errText] !== '') {
        //     const matches = document.querySelectorAll(".err-cart-sum");
        //     setLoading(false)


        //     for (let index = 0; index < matches.length; index++) {

        //         if (matches[index].textContent.trim() !== '') {

        //             let position = matches[index].getBoundingClientRect();


        //             window.scrollTo(position.left, position.top + window.scrollY - 120);
        //             return
        //         }
        //     }

        //     console.log(errors);
        //     return;
        // }

        // window.scrollTo({ top: 0, behavior: 'smooth' })

        const formData = new FormData();
        formData.append('documentName', name || null);
        formData.append(name, value || null);
        setStartUpload((prev) => ({ ...prev, [name]: true }))




        axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendeur/insert_client_document_admin/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress((prev) => ({ ...prev, [name]: Math.round((100 * data.loaded) / data.total) }))
            },
        })
            .then(function (response) {
                console.log(response.data);
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/login')

                }
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    setLoading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }
                    setResponseMsgFail((prev) => ({ ...prev, [name]: "Il y a un problème lors de l'ajout de données." }))

                    setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))


                    // Swal.fire({
                    //     title: 'Problème',
                    //     type: 'error',
                    //     text: "Il y a un problème lors de l'ajout de données.",
                    //     timer: 2000,
                    //     showConfirmButton: false,
                    //     icon: 'error',
                    //     didClose: () => window.scrollTo(0, 0)



                    // });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    setLoading(false)

                    console.log(response.data['err']);
                    setResponseMsgFail((prev) => ({ ...prev, [name]: null }))

                    setResponseMsgSuccsess((prev) => ({ ...prev, [name]: "Les données ont été ajoutées avec succès." }))

                    if (name == 'carte_identite') {
                        setOldCarteidentité(response?.data?.files || '')
                        setCarteidentité('')
                        carte_identiteRef.current.value = "";
                    }

                    if (name == 'carte_identite_verso') {
                        setOldCarteidentitéVerso(response?.data?.files || '')
                        setCarteidentitéVerso('')
                        carte_identite_versoRef.current.value = "";
                    }

                    if (name == 'acte_de_naissance') {
                        setOldActedenaissance(response?.data?.files || '')
                        setActedenaissance('')
                        acte_de_naissanceRef.current.value = "";

                    }

                    if (name == 'fiche_residence') {
                        setOldResidence(response?.data?.files || '')
                        setResidence('')
                        fiche_residenceRef.current.value = "";

                    }

                    if (name == 'fiche_familiale') {
                        setOldFicheFamiliale(response?.data?.files || '')
                        setFicheFamiliale('')
                        fiche_familialeRef.current.value = "";

                    }

                    
                    if (name == 'fiche_familiale_verso') {
                        setOldFicheFamilialeVerso(response?.data?.files || '')
                        setFicheFamilialeVerso('')
                        fiche_familiale_versoRef.current.value = "";

                    }

                    if (name == 'extrait_de_comptes') {
                        setOldExtraitdeCompte(response?.data?.files || '')
                        setExtraitdeCompte('')
                        extrait_de_comptesRef.current.value = "";

                    }

                    if (name == 'attestation_de_travail') {
                        setOldAttestationdetravail(response?.data?.files || '')
                        setAttestationdetravail('')
                        attestation_de_travailRef.current.value = "";

                    }

                    if (name == 'fiches_de_paie') {
                        setOldFichesdePaie(response?.data?.files || '')
                        setFichesdePaie('')
                        fiches_de_paieRef.current.value = "";

                    }
                    if (name == 'fiches_de_paie_2') {
                        setOldFichesdePaie2(response?.data?.files || '')
                        setFichesdePaie2('')
                        fiches_de_paie_2Ref.current.value = "";

                    }
                    if (name == 'photo') {
                        setOldPhoto(response?.data?.files || '')
                        setPhoto('')
                        photoRef.current.value = "";

                    }
                    if (name == 'echeancier') {
                        setOldEcheancier(response?.data?.files || '')
                        setEcheancier('')
                        echeancierRef.current.value = "";

                    }
                    if (name == 'cheque_barre') {
                        setOldChèque(response?.data?.files || '')
                        setChèque('')
                        cheque_barreRef.current.value = "";

                    }
                    if (name == 'fiches_de_paie_3') {
                        setOldFichesdePaie3(response?.data?.files || '')
                        setFichesdePaie3('')
                        fiches_de_paie_3Ref.current.value = "";
                    }
                    if (name == 'carte_dartisanat') {
                        setOldCarteDartisanat(response?.data?.files || '')
                        setCarteDartisanat('')
                        carte_dartisanatRef.current.value = "";

                    }
                    if (name == 'registre_de_commerce') {
                        setOldRegistreDeCommerce(response?.data?.files || '')
                        setRegistreDeCommerce('')
                        registre_de_commerceRef.current.value = "";

                    }
                    if (name == 'diplome') {
                        setOldDiplome(response?.data?.files || '')
                        setDiplome('')
                        diplomeRef.current.value = "";
                    }

                    if (name == 'documents_supplementaires') {
                        setOldDocumentsSupplémentaires(response?.data?.files || '')
                        setDocumentsSupplémentaires('')
                        documents_supplementairesRef.current.value = "";

                    }

                    if (name == 'affiliation_cnas') {
                        setOldAffiliationCNAS(response?.data?.files || '')
                        setAffiliationCNAS('')
                        affiliation_cnasRef.current.value = "";

                    }
                    if (name == 'releve_de_compte_un_ans') {
                        setOldRelevedecompte_une_annee(response?.data?.files || '')
                        setRelevedecompte_une_annee('')
                        releve_de_compte_un_ansRef.current.value = "";

                    }

                    
                    if (name == 'justificatifs_des_credits_1') {
                        setOldJustificatifsdescrédits(response?.data?.files || '')
                        setJustificatifsdescrédits('')
                        justificatifs_des_credits_1Ref.current.value = "";

                    }

                    if (name == 'justificatifs_des_credits_2') {
                        setOldJustificatifsdescrédits_2(response?.data?.files || '')
                        setJustificatifsdescrédits_2('')
                        justificatifs_des_credits_2Ref.current.value = "";

                    }

                    if (name == 'justificatifs_des_credits_3') {
                        setOldJustificatifsdescrédits_3(response?.data?.files || '')
                        setJustificatifsdescrédits_3('')
                        justificatifs_des_credits_3Ref.current.value = "";

                    }
                    
                    if (name == 'justificatifs_des_credits_4') {
                        setOldJustificatifsdescrédits_4(response?.data?.files || '')
                        setJustificatifsdescrédits_4('')
                        justificatifs_des_credits_4Ref.current.value = "";

                    }

                    if (name == 'autres_documents_1') {
                        setOldAutresdocuments_1(response?.data?.files || '')
                        setAutresdocuments_1('')
                        autres_documents_1Ref.current.value = "";

                    }

                    
                    if (name == 'autres_documents_2') {
                        setOldAutresdocuments_2(response?.data?.files || '')
                        setAutresdocuments_2('')
                        autres_documents_2Ref.current.value = "";

                    }
                    
                    if (name == 'releve_de_compte_2') {
                        setOldRelevédecompte2(response?.data?.files || '')
                        setRelevédecompte2('')
                        releve_de_compte_2Ref.current.value = "";

                    }
                    if (name == 'releve_de_compte_3') {
                        setOldRelevédecompte3(response?.data?.files || '')
                        setRelevédecompte3('')
                        releve_de_compte_3Ref.current.value = "";

                    }
                }


                setStartUpload((prev) => ({ ...prev, [name]: false }))
                setProgress((prev) => ({ ...prev, [name]: 0 }))
                
            }).catch(function (error) {
                setResponseMsgSuccsess((prev) => ({ ...prev, [name]: null }))
                setResponseMsgFail((prev) => ({ ...prev, [name]: "Il y a un problème lors de l'ajout de données." }))


                setStartUpload((prev) => ({ ...prev, [name]: false }))
                setProgress((prev) => ({ ...prev, [name]: 0 }))

                setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    // Swal.fire({
                    //     title: 'Problème',
                    //     type: 'error',
                    //     text: "Il y a un problème lors de l'ajout de données.",
                    //     timer: 2000,
                    //     showConfirmButton: false,
                    //     icon: 'error',

                    // });

                }
            });






    }



    // console.log(progress);

    // console.log(errors);

    const DeleteImage = async (type) => {
        // setLoading(true);

        Swal.fire({
            title: 'يرجى التأكيد',
            text: "هل أنت متأكد من حذف الملف ؟",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'نعم !',
            cancelButtonText: 'لا !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {

                ResponseMsgSuccsess[type] = null;
                ResponseMsgFail[type] = null;

                try {
                    const { data: response } = await axios.delete(`${process.env.REACT_APP_LOCAL_URL}/vendeur/delete_client_document_admin/${id}`, {
                        data: { "documentName": type, "idcat": 'IdCat' },
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/login')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Deleted!',
                            "لم يتم حذف الصورة بنجاح",
                            'error'
                        );

                    }

                    if (response['error'] == false) {


                        // setPreview1(null)
                        // setImage1(null)
                        // setinitPreview1(false)
                        // setOldRecuCCPIamge('')

                        if (type == 'carte_identite') {
                            setOldCarteidentité('')
                            setCarteidentité('')
                            carte_identiteRef.current.value = "";
                        }


                        if (type == 'carte_identite_verso') {
                            setOldCarteidentitéVerso(response?.data?.files || '')
                            setCarteidentitéVerso('')
                            carte_identite_versoRef.current.value = "";
                        }

                        if (type == 'acte_de_naissance') {
                            setOldActedenaissance('')
                            setActedenaissance('')
                            acte_de_naissanceRef.current.value = "";

                        }

                        if (type == 'fiche_residence') {
                            setOldResidence('')
                            setResidence('')
                            fiche_residenceRef.current.value = "";

                        }

                        if (type == 'fiche_familiale') {
                            setOldFicheFamiliale('')
                            setFicheFamiliale('')
                            fiche_familialeRef.current.value = "";

                        }
                        
                        if (type == 'fiche_familiale_verso') {
                            setOldFicheFamilialeVerso(response?.data?.files || '')
                            setFicheFamilialeVerso('')
                            fiche_familiale_versoRef.current.value = "";
    
                        }

                        if (type == 'extrait_de_comptes') {
                            setOldExtraitdeCompte('')
                            setExtraitdeCompte('')
                            extrait_de_comptesRef.current.value = "";

                        }

                        if (type == 'attestation_de_travail') {
                            setOldAttestationdetravail('')
                            setAttestationdetravail('')
                            attestation_de_travailRef.current.value = "";

                        }

                        if (type == 'fiches_de_paie') {
                            setOldFichesdePaie('')
                            setFichesdePaie('')
                            fiches_de_paieRef.current.value = "";

                        }
                        if (type == 'fiches_de_paie_2') {
                            setOldFichesdePaie2('')
                            setFichesdePaie2('')
                            fiches_de_paie_2Ref.current.value = "";

                        }
                        if (type == 'photo') {
                            setOldPhoto('')
                            setPhoto('')
                            photoRef.current.value = "";

                        }
                        if (type == 'echeancier') {
                            setOldEcheancier('')
                            setEcheancier('')
                            echeancierRef.current.value = "";

                        }
                        if (type == 'cheque_barre') {
                            setOldChèque('')
                            setChèque('')
                            cheque_barreRef.current.value = "";

                        }
                        if (type == 'fiches_de_paie_3') {
                            setOldFichesdePaie3('')
                            setFichesdePaie3('')
                            fiches_de_paie_3Ref.current.value = "";
                        }

                        if (type == 'carte_dartisanat') {
                            setOldCarteDartisanat(response?.data?.files || '')
                            setCarteDartisanat('')
                            carte_dartisanatRef.current.value = "";

                        }
                        if (type == 'registre_de_commerce') {
                            setOldRegistreDeCommerce(response?.data?.files || '')
                            setRegistreDeCommerce('')
                            registre_de_commerceRef.current.value = "";

                        }
                        if (type == 'diplome') {
                            setOldDiplome(response?.data?.files || '')
                            setDiplome('')
                            diplomeRef.current.value = "";
                        }
                        if (type == 'documents_supplementaires') {
                            setOldDocumentsSupplémentaires('')
                            setDocumentsSupplémentaires('')
                            documents_supplementairesRef.current.value = "";

                        }

                        if (type == 'affiliation_cnas') {
                            setOldAffiliationCNAS(response?.data?.files || '')
                            setAffiliationCNAS('')
                            affiliation_cnasRef.current.value = "";

                        }

                        if (type == 'releve_de_compte_un_ans') {
                            setOldRelevedecompte_une_annee(response?.data?.files || '')
                            setRelevedecompte_une_annee('')
                            releve_de_compte_un_ansRef.current.value = "";

                        }

                        
                        if (type == 'justificatifs_des_credits_1') {
                            setOldJustificatifsdescrédits(response?.data?.files || '')
                            setJustificatifsdescrédits('')
                            justificatifs_des_credits_1Ref.current.value = "";
    
                        }
    
                        if (type == 'justificatifs_des_credits_2') {
                            setOldJustificatifsdescrédits_2(response?.data?.files || '')
                            setJustificatifsdescrédits_2('')
                            justificatifs_des_credits_2Ref.current.value = "";
    
                        }
    
                        if (type == 'justificatifs_des_credits_3') {
                            setOldJustificatifsdescrédits_3(response?.data?.files || '')
                            setJustificatifsdescrédits_3('')
                            justificatifs_des_credits_3Ref.current.value = "";
    
                        }
                        
                        if (type == 'justificatifs_des_credits_4') {
                            setOldJustificatifsdescrédits_4(response?.data?.files || '')
                            setJustificatifsdescrédits_4('')
                            justificatifs_des_credits_4Ref.current.value = "";
    
                        }

                        if (type == 'autres_documents_1') {
                            setOldAutresdocuments_1(response?.data?.files || '')
                            setAutresdocuments_1('')
                            autres_documents_1Ref.current.value = "";
    
                        }
    
                        
                        if (type == 'autres_documents_2') {
                            setOldAutresdocuments_2(response?.data?.files || '')
                            setAutresdocuments_2('')
                            autres_documents_2Ref.current.value = "";
    
                        }

                        if (type == 'releve_de_compte_2') {
                            setOldRelevédecompte2(response?.data?.files || '')
                            setRelevédecompte2('')
                            releve_de_compte_2Ref.current.value = "";
    
                        }
                        if (type == 'releve_de_compte_3') {
                            setOldRelevédecompte3(response?.data?.files || '')
                            setRelevédecompte3('')
                            releve_de_compte_3Ref.current.value = "";
    
                        }




                        await Swal.fire(
                            'Deleted!',
                            "تم حذف الصورة بنجاح",
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                    await Swal.fire(
                        'Deleted!',
                        "لم يتم حذف الصورة بنجاح",
                        'error'
                    );
                }
            },
        })
        // .then(async (result) => {
        //     if (result.isConfirmed) {
        //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);






        //     } 
        // })


        // setLoading(false);
    }


    const [Lang, setLang] = useState("fr")

    const Label = {
        "title": { 'ar': "يرجى تحميل الملفات الخاصة بك هنا", 'fr': "Veuillez télécharger vos fichiers ici" },
        "carte": { 'ar': " بطاقة الهوية الرسميـة البيومترية قيد الصلاحية تحمل رقم التعريـف الوطني", 'fr': "La pièce d’identité biométrique " },
         "carte_recto": { 'ar': "الواجهة الأمامية", 'fr': "(Recto)" },
         "carte_verso": { 'ar': "الواجهة الخلفية", 'fr': "(Verso)" },
         "acte_de_naissance": { 'ar': "شهادة الميلاد  لا تتعدى 6 أشهر", 'fr': "Acte de naissance ( qui ne dépassent pas 6mois)" },
         "residence": { 'ar': " بطاقة الإقامة لا تتعدى 6  أشهر", 'fr': "Résidences (qui ne dépassent pas 6mois) " },
         "fiche_familiale": { 'ar': "شهـادة عائليـة للمتزوجين  لا تتعدى 6  أشهر", 'fr': "Fiche familiale (qui ne dépasse pas 6mois )" },
         "cheque_barre": { 'ar': "صك بريدي باسم المتعامل مشطوب عليه", 'fr': "Chèque barré" },
         "photos": { 'ar': "صورة شمسية", 'fr': "Photos" },
         "autorisation_de_prelevement": { 'ar': "الترخيص بالإقتطاع", 'fr': "Autorisation de prélèvement" },
         "releve_de_compte": { 'ar': "كشف الحساب للأشهر الثلاثة 3 الأخيرة", 'fr': "Relevé de compte (des trois derniers mois)" },
         "attestation_de_travail": { 'ar': <>شهادة عمل لا  تتعدى 6  أشهر  بالنسبة للعاملين في القطاع العام  <br /> ( أما بالنسبة للعاملين في القطاع الخاص لا يزيد تاريخها عن شهر) <br /> تثبت أن عقد العمل غير محدود المدة وأن العامل مرسم في منصبه </>, 'fr': "Attestation de travail  qui ne dépasse pas 6mois  (pour le secteur privé elle ne doit pas dépasser 1 mois)" },
         "fiches_de_paie": { 'ar': "كشف راتب للأشهر الثلاثة (03) الأخيرة ومصادق عليها من صاحب العمل", 'fr': "Fiches de paie des 3 derniers mois" },

        //  "fiches_de_paie": { 'ar': "رقم الهاتف", 'fr': "Fiches de paie des 3 derniers mois" },
         "attestation_de_revenu": { 'ar': "شهادة منحة التقاعد لا تتعدى 6  أشهر", 'fr': "Attestation de revenu pour les retraités (qui ne dépasse pas 6mois)" },

         "fiche_de_paie_retraites": { 'ar': "كشف الراتب للمتقاعدين العسكريين  لا يتعدى 6  أشهر", 'fr': "Fiche de paie pour les retraités militaires (qui ne dépasse pas 6mois)" },
         "justificatifs_des_credits": { 'ar': "نسخة من جدول السداد في حالة وجود قرض", 'fr': "Justificatifs des crédits ( échéancier ou attestation de solde )" },
         "la_das": { 'ar': " التصريح السنوي للأجور", 'fr': "La déclaration annuelle des salaires (LA DAS)" },
         "cnas": { 'ar': "الإنتساب للضمان الإجتماعي", 'fr': "L’affiliation CNAS" },
         "autres_documents": { 'ar': "وثائق أخرى   (نسخة من عقد العمل /شهادة  منحة المردودية / عطلة مرضية…)", 'fr': "Autres documents  (Copie du contrat / Attestation de primes / maladie …… )" },
         "autres_documents_retraites": { 'ar': "وثائق أخرى", 'fr': "Autres documents" },

         "releve_de_compte_une_annee": { 'ar': " كشف الحساب السنوي", 'fr': "Le relevé de compte d’une année" },

       




         "voir": { "ar": "شاهد الملف", "fr": "Voir Le Fichier" },
         "success": { "ar": 'تم رفع الملف بنجاح', "fr": "Les données ont été ajoutées avec succès." },

         "issue": { "ar": "هناك مشكل في رفع الملف", "fr": "Il y a un problème lors de l'ajout de données." }
        , "delete": { "ar": "حذف الملف", "fr": "Supprimer Le Fichier" }
        , "prive": { "ar": "بالنسبة للعاملين في القطاع الخاص", "fr": "Pour le secteur privé" }

        , "upload": { "ar": "رفع الملف", "fr": "Upload" }

        ,'secteur': { 'fr': 'Secteur d’activité', 'ar': 'قطاع النشاط' }
        , 'obligatoire': { 'fr': 'Obligatoire', 'ar': 'إجباري' }
    }

    const changeLang = (lng) => {
        setLang(lng);
    }

    const secteuActivitéList = [
        { 'fr': 'Salarié ', 'ar': 'موظف', 'value': "0" } ,
         { 'fr': 'Retraité ', 'ar': 'متقاعد', 'value': "1" } ,
        // { "ar": { "value": "أثاث", 'text': "أثاث" }, "fr": { "value": "Meubles", 'text': "Meubles" } },
        // { "ar": { "value": "أجهزة كهرومنزلية وأثاث", 'text': "أجهزة كهرومنزلية وأثاث" }, "fr": { "value": "Électroménager et Meubles", 'text': "Électroménager et Meubles" } },
        // { "ar": { "value": "أخرى", 'text': "أخرى" }, "fr": { "value": "Autre", 'text': "Autre" } },
    ];

    async function ChangeSecteur(etat) {


        Swal.fire({
            title: 'Êtes-vous sûr',
            text: Lang == "ar" ? "هل أنت متأكد من تغيير قطاع النشاط ?" : "Etes-vous sûr de changer de secteur d'activité ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/change_secteur_admin/${id}/${etat}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {
                        // const updatefListVentes = ListVentes.map(obj =>
                        //     obj.user_id == id ? { ...obj, active: etat } : obj
                        // );
                        // setListVentes(updatefListVentes);



                        await Swal.fire(
                            etat == 1 ? 'Modifié!' : 'Modifié!',
                            etat == 1 ? 'تم تغيير قطاع النشاط بنجاح' : 'تم تغيير قطاع النشاط بنجاح',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                    await Swal.fire(
                        'Error!',
                        "L'opération ne s'est pas terminée avec succès",
                        'error'
                    );
                }
            },
        })
    }


    return (

        <div className='dashboard'>

            {/* {Loading && 
            <Loader />
            } */}




            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Client Documents <span>Diar Dzair</span></div>
                    <GoBack  url={'_ROLE' === "user" ? "/listvendeuradmin"  : "/fournisseurs"} />

                    <div className='page-content' Lang={Lang === "ar" ? 'ar' :'fr'}>
                        {/* {err.length > 0 && err.map((item, i) => (

            <div className="alert alert-danger" role="alert">
                {item}
            </div>

        ))
        } */}

                        {Loading && !StartUpload ?
                            <Loader /> : ''
                        }

                        {/* <div className='d-flex align-items-center text-center justify-content-center mb-4'>
                          <div className='me-2'>Paiment : </div>   { Payment == 1 ?

                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>

                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                            }
                        </div> */}
                        <form className="row text-center m-auto">
                        <div className="ms-4 lang-section mb-4 text-center">
                <div className="sl-nav">
                    <ul>
                        <li>
                            <b className="lang-menu" style={{ color: 'black' }}>{Lang === "fr" ? 'Francais' : 'العربية'}</b>
                            {/* <b><img src={'/img/' + Lang + '.jpg'} className='lang-menu' alt='' /></b>  */}

                            <i className="fa fa-angle-down" style={{ color: 'black' }} aria-hidden="true" />
                            <div className="triangle" ></div>
                            <ul>
                                <div className='pad-lang'></div>
                                <li onClick={() => changeLang('fr')}><i className="sl-flag flag-fr" ><div id="French" /></i> <span className={Lang === "fr" ? "active lang-text" : 'lang-text'}> {Lang === "fr" ? 'Français' : 'الفرنسية'} </span></li>
                                <li onClick={() => changeLang('ar')}><i className="sl-flag flag-usa"><div id="English" /></i> <span className={Lang === "ar" ? "active lang-text" : 'lang-text'}> {Lang === "ar" ? 'العربية' : 'Arabe'}  </span></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='pb-hadi'></div>
                
            </div>
            
                            <div className="m-auto col-md-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom du client</label>
                                <input type="text" readOnly value={Name} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div></form>
                            <form className="row text-center m-auto mt-4">
                            <div className="m-auto col-md-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Id Demande</label>
                                <input type="text" readOnly value={IdDemande} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div></form>
                        <form className="row text-center m-auto mt-4">
                            <div className="m-auto col-md-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Mobile</label>
                                <input type="text" readOnly value={Mobile} className="form-control" id="inputEmail4" />

                            </div></form>
<div>
                            <Link className='mt-4 document-label' to={`/update_user_label/${id}`}> Étiquettes<div className='mx-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="35" viewBox="0 0 37.628 34.958">
                                                    <defs>
                                                        <linearGradient id="linear-gradient99" x1="0.17" y1="-0.458" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#58dae3" />
                                                            <stop offset="1" stop-color="#b44eee" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="label-svgrepo-com_1_" data-name="label-svgrepo-com (1)" transform="translate(0.001 -1.335)">
                                                        <path id="Tracé_252" data-name="Tracé 252" d="M36.9,23.758l-11.8,11.8a2.5,2.5,0,0,1-1.77.731A2.555,2.555,0,0,1,22.7,36.2a2.475,2.475,0,0,0,1.145-.644l11.8-11.8a2.493,2.493,0,0,0,0-3.534L17.488,2.067a2.483,2.483,0,0,0-1.2-.651l.243-.043a2.5,2.5,0,0,1,2.206.694L36.894,20.223A2.5,2.5,0,0,1,36.9,23.758Zm-2.667,0-11.8,11.8a2.506,2.506,0,0,1-3.538,0L.732,17.405A2.5,2.5,0,0,1,.038,15.2L1.822,5.181A2.5,2.5,0,0,1,3.845,3.158L13.864,1.374a2.5,2.5,0,0,1,2.206.694L34.226,20.224A2.493,2.493,0,0,1,34.228,23.758ZM9.454,7.193a2.542,2.542,0,1,0-.027,3.594A2.543,2.543,0,0,0,9.454,7.193Z" fill="url(#linear-gradient99)" />
                                                    </g>
                                                </svg>

                                            </div></Link></div>

                           


                        <form className="row g-0">
                            <div className='container py-0 pt-0'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>

                            {StartUpload && Loading ?
                                // <div className="screen">
                                //     <div className="loader">

                                //         <div>
                                //             <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="400" height="81.078" viewBox="0 0 406.418 81.078">
                                //                 <defs>
                                //                     <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                //                         <stop offset="0" stop-color="#fca01f" />
                                //                         <stop offset="1" stop-color="#f27c00" />
                                //                     </linearGradient>
                                //                     <linearGradient id="linear-gradient-5" x1="0.627" y1="-63.466" x2="2.169" y2="-63.466" xlinkHref="#linear-gradient" />
                                //                 </defs>
                                //                 <g id="Groupe_136" data-name="Groupe 136" transform="translate(-85.678 -423.155)">
                                //                     <g id="Groupe_93" data-name="Groupe 93" transform="translate(195.312 430.316)">
                                //                         <path id="Tracé_211" data-name="Tracé 211" d="M221.726,432.043h3.944l11.65,35.5,12.136-35.376h5.037l-15.171,42.779h-4.066l-11.65-34.466L211.9,474.944h-4l-15.171-42.779h5.218l12.137,35.376Z" transform="translate(-192.721 -430.101)" fill="#fff" />
                                //                         <path id="Tracé_212" data-name="Tracé 212" d="M259.9,447.352a28.292,28.292,0,0,0-4.46,1.669l-1.4-3.822a31.254,31.254,0,0,1,5.28-1.912,24.34,24.34,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.354,15.354,0,0,1-4.4,3.762,13.435,13.435,0,0,1-6.947,1.639,15.323,15.323,0,0,1-4.278-.607,11.625,11.625,0,0,1-3.762-1.851,9.462,9.462,0,0,1-2.67-3.064,8.8,8.8,0,0,1-1-4.308,9.588,9.588,0,0,1,.971-4.4,8.684,8.684,0,0,1,2.761-3.185,13.239,13.239,0,0,1,4.247-1.942,20.714,20.714,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.349,38.349,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.765,18.765,0,0,0,259.9,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.435,5.435,0,0,0,.635,2.67,6.162,6.162,0,0,0,1.724,1.972,7.854,7.854,0,0,0,2.509,1.244,10.388,10.388,0,0,0,2.995.424,12.786,12.786,0,0,0,4.081-.637,10.845,10.845,0,0,0,3.327-1.76,8.331,8.331,0,0,0,2.238-2.669,7.24,7.24,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.333,28.333,0,0,0-5.171-.425Q260.768,458.487,258.409,460.247Z" transform="translate(-191.304 -429.846)" fill="#fff" />
                                //                         <path id="Tracé_213" data-name="Tracé 213" d="M301.232,443.436v4H291.34v27.246h-4.671V447.441H282.36v-4.065h4.309v-2.731q0-5.157,2.457-7.828a9.054,9.054,0,0,1,7.01-2.67,18.92,18.92,0,0,1,2.821.182,12.046,12.046,0,0,1,2.336.607V435q-1.274-.364-2.306-.577a11.174,11.174,0,0,0-2.246-.212q-5.46,0-5.461,6.675v2.548Z" transform="translate(-190.552 -430.147)" fill="#fff" />
                                //                         <path id="Tracé_214" data-name="Tracé 214" d="M307.089,464.955a10.91,10.91,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.252,11.252,0,0,0,3.944.7,12.026,12.026,0,0,0,5.613-1.215,16.59,16.59,0,0,0,4.278-3.216l2.913,2.61a19.167,19.167,0,0,1-5.4,4.278,15.94,15.94,0,0,1-7.524,1.608,15.661,15.661,0,0,1-6.068-1.184,15.1,15.1,0,0,1-4.99-3.337,15.9,15.9,0,0,1-3.4-5.189,17.217,17.217,0,0,1-1.261-6.675,18.109,18.109,0,0,1,1.122-6.4,16.07,16.07,0,0,1,3.126-5.218,14.583,14.583,0,0,1,4.763-3.489,15.136,15.136,0,0,1,12.227.031,13.564,13.564,0,0,1,4.611,3.55,15.462,15.462,0,0,1,2.852,5.279,21.217,21.217,0,0,1,.971,6.492v.668a5.672,5.672,0,0,1-.06.789H305.876A12.886,12.886,0,0,0,307.089,464.955Zm18.872-7.98a16.169,16.169,0,0,0-.875-4.065,10.883,10.883,0,0,0-1.93-3.4,9.343,9.343,0,0,0-3.017-2.336,9.22,9.22,0,0,0-4.132-.88,8.852,8.852,0,0,0-3.8.819,9.913,9.913,0,0,0-3.076,2.245,11.81,11.81,0,0,0-2.171,3.368,14.289,14.289,0,0,0-1.085,4.247Z" transform="translate(-190.098 -429.852)" fill="#fff" />
                                //                         <path id="Tracé_215" data-name="Tracé 215" d="M339.141,464.955a10.893,10.893,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.254,11.254,0,0,0,3.945.7,12.022,12.022,0,0,0,5.612-1.215,16.588,16.588,0,0,0,4.278-3.216l2.913,2.61a19.168,19.168,0,0,1-5.4,4.278,15.939,15.939,0,0,1-7.524,1.608,15.662,15.662,0,0,1-6.068-1.184,15.077,15.077,0,0,1-4.99-3.337,15.918,15.918,0,0,1-3.4-5.189,17.235,17.235,0,0,1-1.26-6.675,18.107,18.107,0,0,1,1.122-6.4,16.087,16.087,0,0,1,3.125-5.218,14.6,14.6,0,0,1,4.764-3.489,15.137,15.137,0,0,1,12.227.031,13.6,13.6,0,0,1,4.612,3.55,15.459,15.459,0,0,1,2.851,5.279,21.218,21.218,0,0,1,.971,6.492v.668a5.675,5.675,0,0,1-.06.789H337.927A12.9,12.9,0,0,0,339.141,464.955Zm18.872-7.98a16.082,16.082,0,0,0-.876-4.065,10.839,10.839,0,0,0-1.929-3.4,9.361,9.361,0,0,0-3.016-2.336,9.227,9.227,0,0,0-4.133-.88,8.852,8.852,0,0,0-3.8.819,9.889,9.889,0,0,0-3.076,2.245,11.811,11.811,0,0,0-2.171,3.368,14.319,14.319,0,0,0-1.086,4.247Z" transform="translate(-189.323 -429.852)" fill="#fff" />
                                //                         <path id="Tracé_216" data-name="Tracé 216" d="M371.754,474.388h-4.673V443.016h4.673v8.193a16.711,16.711,0,0,1,2.123-3.581,14.278,14.278,0,0,1,2.913-2.822,12.4,12.4,0,0,1,3.581-1.79,12.855,12.855,0,0,1,4.187-.546v5.037h-.365a12.876,12.876,0,0,0-4.824.909,10.956,10.956,0,0,0-3.975,2.731,12.944,12.944,0,0,0-2.669,4.521,18.318,18.318,0,0,0-.971,6.22Z" transform="translate(-188.501 -429.849)" fill="#fff" />
                                //                         <path id="Tracé_217" data-name="Tracé 217" d="M419.842,452.732a12.98,12.98,0,0,1-3.65,4.55,16.245,16.245,0,0,1-5.413,2.761,22.716,22.716,0,0,1-6.631.94h-8.453v13.654h-7.4V432.162h16.748a21.111,21.111,0,0,1,6.7,1,14.262,14.262,0,0,1,5.1,2.883,12.741,12.741,0,0,1,3.217,4.489,14.778,14.778,0,0,1,1.121,5.887A14.165,14.165,0,0,1,419.842,452.732ZM411.1,440.817a10.717,10.717,0,0,0-6.7-1.919h-8.706v15.352H404.4a10.041,10.041,0,0,0,6.728-2.1,6.961,6.961,0,0,0,2.466-5.575A6.809,6.809,0,0,0,411.1,440.817Z" transform="translate(-187.988 -430.098)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_218" data-name="Tracé 218" d="M430.945,447.352a28.3,28.3,0,0,0-4.46,1.669l-1.4-3.822a31.285,31.285,0,0,1,5.28-1.912,24.327,24.327,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.362,15.362,0,0,1-4.4,3.762,13.443,13.443,0,0,1-6.949,1.639,15.326,15.326,0,0,1-4.277-.607,11.636,11.636,0,0,1-3.762-1.851,9.478,9.478,0,0,1-2.671-3.064,8.811,8.811,0,0,1-1-4.308,9.587,9.587,0,0,1,.971-4.4,8.7,8.7,0,0,1,2.76-3.185,13.254,13.254,0,0,1,4.248-1.942,20.709,20.709,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.345,38.345,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.764,18.764,0,0,0,430.945,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.447,5.447,0,0,0,.635,2.67,6.149,6.149,0,0,0,1.724,1.972,7.853,7.853,0,0,0,2.509,1.244,10.387,10.387,0,0,0,2.994.424,12.788,12.788,0,0,0,4.082-.637,10.851,10.851,0,0,0,3.328-1.76,8.341,8.341,0,0,0,2.237-2.669,7.239,7.239,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.33,28.33,0,0,0-5.17-.425Q431.81,458.487,429.451,460.247Z" transform="translate(-187.165 -429.846)" fill="#fff" />
                                //                         <path id="Tracé_219" data-name="Tracé 219" d="M470.072,475.407q-2,4.853-4.46,6.857a9.16,9.16,0,0,1-5.977,2,14.556,14.556,0,0,1-3.519-.394,15.565,15.565,0,0,1-3.156-1.183l1.578-3.7a9.977,9.977,0,0,0,2.275.88,11.3,11.3,0,0,0,2.64.272,5.243,5.243,0,0,0,3.58-1.274,12.764,12.764,0,0,0,2.852-4.612L451.625,443h5.157l11.409,26.154L478.263,443h4.976Z" transform="translate(-186.455 -429.836)" fill="#fff" />
                                //                     </g>
                                //                     <g id="Groupe_94" data-name="Groupe 94" transform="translate(85.678 423.155)">
                                //                         <path id="Tracé_220" data-name="Tracé 220" d="M85.678,425.32,120,481.1a20.381,20.381,0,0,0,23.926,8.612l2.582-.88-33.4-55.47a21.075,21.075,0,0,0-26.307-8.52Z" transform="translate(-85.678 -423.155)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_221" data-name="Tracé 221" d="M119.672,425.305l19.846,33.506a21.692,21.692,0,0,0,27.023,8.963h0l-20.59-33.7a21.488,21.488,0,0,0-26.279-8.764Z" transform="translate(-84.855 -423.14)" fill="url(#linear-gradient)" />
                                //                         <path id="Tracé_222" data-name="Tracé 222" d="M169.04,451.926l-9.851-17.172a4.051,4.051,0,0,1,.346-4.54c3.081-3.881,10.769-10.561,24.235-4.324Z" transform="translate(-83.912 -423.151)" fill="url(#linear-gradient)" />
                                //                     </g>
                                //                     <text id="وفير" transform="translate(411.464 494.233)" fill="#fff" font-size="24" font-family="GESSTwoLight-Light, GE SS Two" font-weight="300"><tspan x="0" y="0">وفير</tspan></text>
                                //                     <path id="Tracé_223" data-name="Tracé 223" d="M397.344,497.674l-9.32.143a1.141,1.141,0,0,1-1.186-1.375c.192-2.507,1.556-7.966,9.222-12.751Z" transform="translate(7.289 1.465)" fill="url(#linear-gradient-5)" />
                                //                 </g>
                                //             </svg>


                                //         </div>
                                //         <div className="dot" />
                                //         <div className="dot" />
                                //         <div className="dot" />
                                //         <div className='loader-wait'>S'il vous plaît, attendez</div>
                                //         <div
                                //             className="progress"
                                //             role="progressbar"
                                //             aria-label="Example with label"
                                //             aria-valuenow={progress}
                                //             aria-valuemin={0}
                                //             aria-valuemax={100}
                                //         >
                                //             <div className="progress-bar" style={{ width: progress + '%' }}>
                                //                 {progress + '%'}
                                //             </div>
                                //         </div>

                                //     </div>
                                // </div>
                                ""
                                : ''}





                            <section className='pt-0 mt-4'>
                                <div className="container-fluid">

                                    <div className='d-flex login-div'>



                                        <div className="wafeer-form pt-0">

                                            <div className="container text-center">
                                                <div style={{ fontWeight: 500 }} data-aos="fade-up" className="section-sub mt-4">
                                                    Client Documents
                                                </div>
                                                <div data-aos="fade-down" className="wafeer-vertical-separation mt-3" />
                                            </div>

                                            <div className="col-lg-4 text-center mb-1" dir={Lang === 'ar' ? 'rtl' : 'ltr'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang == 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.secteur?.[Lang] }</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setSecteur(e.target.value)}>
                                <option disabled selected={Secteur == "" ? "selected" : ''} value={''} >{Label?.secteur?.[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.secteur?.[Lang]}</label>
                                {secteuActivitéList.length > 0 && secteuActivitéList.map((item, i) => (

                                    <option selected={Secteur == item?.value} value={item?.value}>{item?.[Lang]}</option>

                                ))
                                }

                            </select>
                            {/* <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div> */}

                        </div>
                            <a className='btn-update-secteur mb-5 mt-2' onClick={() => ChangeSecteur(Secteur)} >
                                {Lang == "ar" ? "تحديث قطاع النشاط" : "Changer de secteur d'activité"}
                              
                            </a>




                        <div className="col-lg-12 mt-4">
                                <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Label?.carte[Lang]}</div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    <hr />
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">  {Label?.carte_recto[Lang]} 

                                            {OldCarteidentité ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    {OldCarteidentité ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldCarteidentité}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('carte_identite')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    {/* {OldCarteidentité ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldCarteidentité}`}> Voir le Fichier actuel </a> : ''} */}
                                    {/* {OldCarteidentité ? <div onClick={() => DeleteImage('carte_identite')} className='delete-current-file' >Supprimer</div> : ''} */}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={carte_identiteRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setCarteidentité(e.target.files[0]) : setCarteidentité('')} className="upload-input-section form-control" id="inputCity" />

                                        </div>
                                        {Carteidentité ? <div onClick={(e) => !StartUpload.carte_identite ? Register(e, 'carte_identite', Carteidentité, 'Carteidentité') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            Upload
                                        </div> : ""}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Carteidentité !== '' ? errors.Carteidentité : ''}</div>
                                    {ResponseMsgSuccsess.carte_identite != null ? <div className='ok'>{ResponseMsgSuccsess.carte_identite}</div> : ''}
                                    {ResponseMsgFail.carte_identite != null ? <div className='no'>{ResponseMsgFail.carte_identite}</div> : ''}
                                    {
                                        StartUpload.carte_identite ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.carte_identite}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.carte_identite + '%' }}>
                                                    {progress.carte_identite + '%'}
                                                </div>
                                            </div> : ''}
                                </div>

                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">  {Label?.carte_verso[Lang]} 

                                            {OldCarteidentitéVerso ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    {OldCarteidentitéVerso ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldCarteidentitéVerso}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('carte_identite_verso')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    {/* {OldCarteidentité ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldCarteidentité}`}> Voir le Fichier actuel </a> : ''} */}
                                    {/* {OldCarteidentité ? <div onClick={() => DeleteImage('carte_identite')} className='delete-current-file' >Supprimer</div> : ''} */}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={carte_identite_versoRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setCarteidentitéVerso(e.target.files[0]) : setCarteidentitéVerso('')} className="upload-input-section form-control" id="inputCity" />

                                        </div>
                                        {CarteidentitéVerso ? <div onClick={(e) => !StartUpload.carte_identite_verso ? Register(e, 'carte_identite_verso', CarteidentitéVerso, 'CarteidentitéVerso') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            Upload
                                        </div> : ""}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.CarteidentitéVerso !== '' ? errors.CarteidentitéVerso : ''}</div>
                                    {ResponseMsgSuccsess.carte_identite_verso != null ? <div className='ok'>{ResponseMsgSuccsess.carte_identite_verso}</div> : ''}
                                    {ResponseMsgFail.carte_identite_verso != null ? <div className='no'>{ResponseMsgFail.carte_identite_verso}</div> : ''}
                                    {
                                        StartUpload.carte_identite_verso ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.carte_identite_verso}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.carte_identite_verso + '%' }}>
                                                    {progress.carte_identite_verso + '%'}
                                                </div>
                                            </div> : ''}

                                            <hr />
                                </div>

                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">  {Label?.acte_de_naissance[Lang]} 
                                            {OldActedenaissance ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldActedenaissance ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldActedenaissance}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('acte_de_naissance')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={acte_de_naissanceRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setActedenaissance(e.target.files[0]) : setActedenaissance('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldActedenaissance ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldActedenaissance}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Actedenaissance ? <div onClick={(e) => !StartUpload.acte_de_naissance ? Register(e, 'acte_de_naissance', Actedenaissance, 'Actedenaissance') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Actedenaissance !== '' ? errors.Actedenaissance : ''}</div>
                                    {ResponseMsgSuccsess.acte_de_naissance != null ? <div className='ok'>{ResponseMsgSuccsess.acte_de_naissance}</div> : ''}
                                    {ResponseMsgFail.acte_de_naissance != null ? <div className='no'>{ResponseMsgFail.acte_de_naissance}</div> : ''}
                                    {
                                        StartUpload.acte_de_naissance ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.acte_de_naissance}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.acte_de_naissance + '%' }}>
                                                    {progress.acte_de_naissance + '%'}
                                                </div>
                                            </div> : ''}
                                </div>

                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">  {Label?.residence[Lang]} 

                                            {OldResidence ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldResidence ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldResidence}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiche_residence')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={fiche_residenceRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setResidence(e.target.files[0]) : setResidence('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldResidence ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldResidence}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Residence ? <div onClick={(e) => !StartUpload.fiche_residence ? Register(e, 'fiche_residence', Residence, 'Residence') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Residence !== '' ? errors.Residence : ''}</div>
                                    {ResponseMsgSuccsess.fiche_residence != null ? <div className='ok'>{ResponseMsgSuccsess.fiche_residence}</div> : ''}
                                    {ResponseMsgFail.fiche_residence != null ? <div className='no'>{ResponseMsgFail.fiche_residence}</div> : ''}
                                    {
                                        StartUpload.fiche_residence ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiche_residence}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiche_residence + '%' }}>
                                                    {progress.fiche_residence + '%'}
                                                </div>
                                            </div> : ''}
                                </div>

                                <div className="col-lg-12 mt-5">
                                <div className='d-flex text-center justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Label?.fiche_familiale[Lang]}</div>
                                    <hr />
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0"> {Label?.carte_recto[Lang]} 

                                            {OldFicheFamiliale ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    {OldFicheFamiliale ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFicheFamiliale}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiche_familiale')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={fiche_familialeRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setFicheFamiliale(e.target.files[0]) : setFicheFamiliale('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldFicheFamiliale ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFicheFamiliale}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {FicheFamiliale ? <div onClick={(e) => !StartUpload.fiche_familiale ? Register(e, 'fiche_familiale', FicheFamiliale, 'FicheFamiliale') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.FicheFamiliale !== '' ? errors.FicheFamiliale : ''}</div>
                                    {ResponseMsgSuccsess.fiche_familiale != null ? <div className='ok'>{ResponseMsgSuccsess.fiche_familiale}</div> : ''}
                                    {ResponseMsgFail.fiche_familiale != null ? <div className='no'>{ResponseMsgFail.fiche_familiale}</div> : ''}
                                    {
                                        StartUpload.fiche_familiale ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiche_familiale}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiche_familiale + '%' }}>
                                                    {progress.fiche_familiale + '%'}
                                                </div>
                                            </div> : ''}
                                </div>


                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0"> {Label?.carte_verso[Lang]} 

                                            {OldFicheFamilialeVerso ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    {OldFicheFamilialeVerso ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFicheFamilialeVerso}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiche_familiale_verso')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>
                                            <input type="file" ref={fiche_familiale_versoRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setFicheFamilialeVerso(e.target.files[0]) : setFicheFamilialeVerso('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldFicheFamiliale ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFicheFamiliale}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {FicheFamilialeVerso ? <div onClick={(e) => !StartUpload.fiche_familiale_verso ? Register(e, 'fiche_familiale_verso', FicheFamilialeVerso, 'FicheFamilialeVerso') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.FicheFamilialeVerso !== '' ? errors.FicheFamilialeVerso : ''}</div>
                                    {ResponseMsgSuccsess.fiche_familiale_verso != null ? <div className='ok'>{ResponseMsgSuccsess.fiche_familiale_verso}</div> : ''}
                                    {ResponseMsgFail.fiche_familiale_verso != null ? <div className='no'>{ResponseMsgFail.fiche_familiale_verso}</div> : ''}
                                    {
                                        StartUpload.fiche_familiale_verso ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiche_familiale_verso}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiche_familiale_verso + '%' }}>
                                                    {progress.fiche_familiale_verso + '%'}
                                                </div>
                                            </div> : ''}

                                            <hr />
                                </div>


                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.cheque_barre[Lang]}
                                            {OldChèque ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldChèque ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldChèque}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('cheque_barre')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={cheque_barreRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setChèque(e.target.files[0]) : setChèque('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldChèque ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldChèque}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Chèque ? <div onClick={(e) => !StartUpload.cheque_barre ? Register(e, 'cheque_barre', Chèque, 'cheque_barre') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.cheque_barre !== '' ? errors.cheque_barre : ''}</div>
                                    {ResponseMsgSuccsess.cheque_barre != null ? <div className='ok'>{ResponseMsgSuccsess.cheque_barre}</div> : ''}
                                    {ResponseMsgFail.cheque_barre != null ? <div className='no'>{ResponseMsgFail.cheque_barre}</div> : ''}
                                    {
                                        StartUpload.cheque_barre ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.cheque_barre}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.cheque_barre + '%' }}>
                                                    {progress.cheque_barre + '%'}
                                                </div>
                                            </div> : ''}

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.photos[Lang]} 

                                            {OldPhoto ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldPhoto ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldPhoto}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('photo')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={photoRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setPhoto(e.target.files[0]) : setPhoto('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldPhoto ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldPhoto}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Photo ? <div onClick={(e) => !StartUpload.photo ? Register(e, 'photo', Photo, 'Photo') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Photo !== '' ? errors.Photo : ''}</div>
                                    {ResponseMsgSuccsess.photo != null ? <div className='ok'>{ResponseMsgSuccsess.photo}</div> : ''}
                                    {ResponseMsgFail.photo != null ? <div className='no'>{ResponseMsgFail.photo}</div> : ''}
                                    {
                                        StartUpload.photo ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.photo}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.photo + '%' }}>
                                                    {progress.photo + '%'}
                                                </div>
                                            </div> : ''}

                                </div>
                                <div className="col-lg-12 mt-5 mb-4">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.autorisation_de_prelevement[Lang]} 

                                            {OldEcheancier ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    {OldEcheancier ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldEcheancier}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('echeancier')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={echeancierRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setEcheancier(e.target.files[0]) : setEcheancier('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldEcheancier ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldEcheancier}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Echeancier ? <div onClick={(e) => !StartUpload.echeancier ? Register(e, 'echeancier', Echeancier, 'echeancier') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.echeancier !== '' ? errors.echeancier : ''}</div>
                                    {ResponseMsgSuccsess.echeancier != null ? <div className='ok'>{ResponseMsgSuccsess.echeancier}</div> : ''}
                                    {ResponseMsgFail.echeancier != null ? <div className='no'>{ResponseMsgFail.echeancier}</div> : ''}
                                    {
                                        StartUpload.echeancier ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.echeancier}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.echeancier + '%' }}>
                                                    {progress.echeancier + '%'}
                                                </div>
                                            </div> : ''}

                                </div>


                                <div className="col-lg-12 mt-5">
                                <div className='d-flex text-center justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Label?.releve_de_compte[Lang]}</div>
                                        <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>
                                    <hr />
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">(1)
                                            {OldExtraitdeCompte ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    

                                    {OldExtraitdeCompte ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldExtraitdeCompte}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.voir[Lang]}
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('extrait_de_comptes')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                        {Label?.delete[Lang]}
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{ width: 'fit-content' }} >
                                        <div>
                                            <input type="file" ref={extrait_de_comptesRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setExtraitdeCompte(e.target.files[0]) : setExtraitdeCompte('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldExtraitdeCompte ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldExtraitdeCompte}`}> Voir le Fichier actuel </a> : ''} */}

                                        </div>

                                        {ExtraitdeCompte ? <div onClick={(e) => !StartUpload.extrait_de_comptes ? Register(e, 'extrait_de_comptes', ExtraitdeCompte, 'ExtraitdeCompte') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.ExtraitdeCompte !== '' ? errors.ExtraitdeCompte : ''}</div>
                                    {ResponseMsgSuccsess.extrait_de_comptes != null ? <div className='ok'>{ResponseMsgSuccsess.extrait_de_comptes}</div> : ''}
                                    {ResponseMsgFail.extrait_de_comptes != null ? <div className='no'>{ResponseMsgFail.extrait_de_comptes}</div> : ''}
                                    {
                                        StartUpload.extrait_de_comptes ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.extrait_de_comptes}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.extrait_de_comptes + '%' }}>
                                                    {progress.extrait_de_comptes + '%'}
                                                </div>
                                            </div> : ''}
                                </div>

                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">(2)
                                            {OldRelevédecompte2 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    {/* <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div> */}

                                    {OldRelevédecompte2 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldRelevédecompte2}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.voir[Lang]}
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('releve_de_compte_2')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                        {Label?.delete[Lang]}
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{ width: 'fit-content' }} >
                                        <div>
                                            <input type="file" ref={releve_de_compte_2Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setRelevédecompte2(e.target.files[0]) : setRelevédecompte2('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldExtraitdeCompte ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldExtraitdeCompte}`}> Voir le Fichier actuel </a> : ''} */}

                                        </div>

                                        {Relevédecompte2 ? <div onClick={(e) => !StartUpload.releve_de_compte_2 ? Register(e, 'releve_de_compte_2', Relevédecompte2, 'Relevédecompte2') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Relevédecompte2 !== '' ? errors.Relevédecompte2 : ''}</div>
                                    {ResponseMsgSuccsess.releve_de_compte_2 != null ? <div className='ok'>{ResponseMsgSuccsess.releve_de_compte_2}</div> : ''}
                                    {ResponseMsgFail.releve_de_compte_2 != null ? <div className='no'>{ResponseMsgFail.releve_de_compte_2}</div> : ''}
                                    {
                                        StartUpload.releve_de_compte_2 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.releve_de_compte_2}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.releve_de_compte_2 + '%' }}>
                                                    {progress.releve_de_compte_2 + '%'}
                                                </div>
                                            </div> : ''}
                                </div>

                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">(3)
                                            {OldRelevédecompte3 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    {/* <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div> */}

                                    {OldRelevédecompte3 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldRelevédecompte3}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.voir[Lang]}
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('releve_de_compte_3')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                        {Label?.delete[Lang]}
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{ width: 'fit-content' }} >
                                        <div>
                                            <input type="file" ref={releve_de_compte_3Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setRelevédecompte3(e.target.files[0]) : setRelevédecompte3('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldExtraitdeCompte ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldExtraitdeCompte}`}> Voir le Fichier actuel </a> : ''} */}

                                        </div>

                                        {Relevédecompte3 ? <div onClick={(e) => !StartUpload.releve_de_compte_3 ? Register(e, 'releve_de_compte_3', Relevédecompte3, 'Relevédecompte3') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Relevédecompte3 !== '' ? errors.Relevédecompte3 : ''}</div>
                                    {ResponseMsgSuccsess.releve_de_compte_3 != null ? <div className='ok'>{ResponseMsgSuccsess.releve_de_compte_3}</div> : ''}
                                    {ResponseMsgFail.releve_de_compte_3 != null ? <div className='no'>{ResponseMsgFail.releve_de_compte_3}</div> : ''}
                                    {
                                        StartUpload.releve_de_compte_3 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.releve_de_compte_3}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.releve_de_compte_3 + '%' }}>
                                                    {progress.releve_de_compte_3 + '%'}
                                                </div>
                                            </div> : ''}
                                            <hr />
                                </div>

                              
                              

                                {Secteur == "0" ? <>
                                    <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.attestation_de_travail[Lang]} 

                                            {OldAttestationdetravail ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldAttestationdetravail ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldAttestationdetravail}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('attestation_de_travail')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={attestation_de_travailRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setAttestationdetravail(e.target.files[0]) : setAttestationdetravail('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldAttestationdetravail ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldAttestationdetravail}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Attestationdetravail ? <div onClick={(e) => !StartUpload.attestation_de_travail ? Register(e, 'attestation_de_travail', Attestationdetravail, 'Attestationdetravail') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.Attestationdetravail !== '' ? errors.Attestationdetravail : ''}</div>
                                    {ResponseMsgSuccsess.attestation_de_travail != null ? <div className='ok'>{ResponseMsgSuccsess.attestation_de_travail}</div> : ''}
                                    {ResponseMsgFail.attestation_de_travail != null ? <div className='no'>{ResponseMsgFail.attestation_de_travail}</div> : ''}
                                    {
                                        StartUpload.attestation_de_travail ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.attestation_de_travail}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.attestation_de_travail + '%' }}>
                                                    {progress.attestation_de_travail + '%'}
                                                </div>
                                            </div> : ''}

                                </div> 


                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Label?.fiches_de_paie[Lang]}</div>
                                        <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    <hr />
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(1)

                                            {OldFichesdePaie ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    {OldFichesdePaie ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiches_de_paie')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={fiches_de_paieRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setFichesdePaie(e.target.files[0]) : setFichesdePaie('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldFichesdePaie ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {FichesdePaie ? <div onClick={(e) => !StartUpload.fiches_de_paie ? Register(e, 'fiches_de_paie', FichesdePaie, 'FichesdePaie') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.FichesdePaie !== '' ? errors.FichesdePaie : ''}</div>
                                    {ResponseMsgSuccsess.fiches_de_paie != null ? <div className='ok'>{ResponseMsgSuccsess.fiches_de_paie}</div> : ''}
                                    {ResponseMsgFail.fiches_de_paie != null ? <div className='no'>{ResponseMsgFail.fiches_de_paie}</div> : ''}
                                    {
                                        StartUpload.fiches_de_paie ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiches_de_paie}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiches_de_paie + '%' }}>
                                                    {progress.fiches_de_paie + '%'}
                                                </div>
                                            </div> : ''}

                                </div>
                                <div className="col-lg-6 mt-2">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0"> (2)
                                            {OldFichesdePaie2 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }

                                        </label>
                                    </div>
                                    {OldFichesdePaie2 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie2}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiches_de_paie_2')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={fiches_de_paie_2Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setFichesdePaie2(e.target.files[0]) : setFichesdePaie2('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldFichesdePaie2 ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie2}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {FichesdePaie2 ? <div onClick={(e) => !StartUpload.fiches_de_paie_2 ? Register(e, 'fiches_de_paie_2', FichesdePaie2, 'FichesdePaie2') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.FichesdePaie2 !== '' ? errors.FichesdePaie2 : ''}</div>
                                    {ResponseMsgSuccsess.fiches_de_paie_2 != null ? <div className='ok'>{ResponseMsgSuccsess.fiches_de_paie_2}</div> : ''}
                                    {ResponseMsgFail.fiches_de_paie_2 != null ? <div className='no'>{ResponseMsgFail.fiches_de_paie_2}</div> : ''}
                                    {
                                        StartUpload.fiches_de_paie_2 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiches_de_paie_2}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiches_de_paie_2 + '%' }}>
                                                    {progress.fiches_de_paie_2 + '%'}
                                                </div>
                                            </div> : ''}

                                </div>

                                <div className="col-lg-6 mt-2">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(3)

                                            {OldFichesdePaie3 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }


                                        </label>
                                    </div>
                                    {OldFichesdePaie3 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie3}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('fiches_de_paie_3')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={fiches_de_paie_3Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setFichesdePaie3(e.target.files[0]) : setFichesdePaie3('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldFichesdePaie3 ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldFichesdePaie3}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {FichesdePaie3 ? <div onClick={(e) => !StartUpload.fiches_de_paie_3 ? Register(e, 'fiches_de_paie_3', FichesdePaie3, 'FichesdePaie3') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.FichesdePaie3 !== '' ? errors.FichesdePaie3 : ''}</div>
                                    {ResponseMsgSuccsess.fiches_de_paie_3 != null ? <div className='ok'>{ResponseMsgSuccsess.fiches_de_paie_3}</div> : ''}
                                    {ResponseMsgFail.fiches_de_paie_3 != null ? <div className='no'>{ResponseMsgFail.fiches_de_paie_3}</div> : ''}
                                    {
                                        StartUpload.fiches_de_paie_3 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.fiches_de_paie_3}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.fiches_de_paie_3 + '%' }}>
                                                    {progress.fiches_de_paie_3 + '%'}
                                                </div>
                                            </div> : ''}

                                <hr />
                                </div>
</>
                               :''}



                               

                                <div className="col-lg-12 mt-5 d-none">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">Copie du contrat (Si l’attestation de travail ne contient pas la mention cdi )
                                            {OldCarteDartisanat ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldCarteDartisanat ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldCarteDartisanat}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('carte_dartisanat')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={carte_dartisanatRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setCarteDartisanat(e.target.files[0]) : setCarteDartisanat('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldChèque ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldChèque}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {CarteDartisanat ? <div onClick={(e) => !StartUpload.carte_dartisanat ? Register(e, 'carte_dartisanat', CarteDartisanat, 'carte_dartisanat') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.carte_dartisanat !== '' ? errors.carte_dartisanat : ''}</div>
                                    {ResponseMsgSuccsess.carte_dartisanat != null ? <div className='ok'>{ResponseMsgSuccsess.carte_dartisanat}</div> : ''}
                                    {ResponseMsgFail.carte_dartisanat != null ? <div className='no'>{ResponseMsgFail.carte_dartisanat}</div> : ''}
                                    {
                                        StartUpload.carte_dartisanat ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.carte_dartisanat}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.carte_dartisanat + '%' }}>
                                                    {progress.carte_dartisanat + '%'}
                                                </div>
                                            </div> : ''}

                                </div>

                                { Secteur == "1" ?
                                    <>
                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.attestation_de_revenu[Lang]}
                                            {OldDiplome ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldDiplome ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDiplome}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('diplome')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={diplomeRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setDiplome(e.target.files[0]) : setDiplome('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldChèque ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldChèque}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Diplome ? <div onClick={(e) => !StartUpload.diplome ? Register(e, 'diplome', Diplome, 'diplome') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.diplome !== '' ? errors.diplome : ''}</div>
                                    {ResponseMsgSuccsess.diplome != null ? <div className='ok'>{ResponseMsgSuccsess.diplome}</div> : ''}
                                    {ResponseMsgFail.diplome != null ? <div className='no'>{ResponseMsgFail.diplome}</div> : ''}
                                    {
                                        StartUpload.diplome ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.diplome}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.diplome + '%' }}>
                                                    {progress.diplome + '%'}
                                                </div>
                                            </div> : ''}

                                </div>
                               

                              

                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.fiche_de_paie_retraites[Lang]}
                                            {OldDocumentsSupplémentaires ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>


                                    {OldDocumentsSupplémentaires ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldDocumentsSupplémentaires}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('documents_supplementaires')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={documents_supplementairesRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setDocumentsSupplémentaires(e.target.files[0]) : setDocumentsSupplémentaires('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {DocumentsSupplémentaires ? <div onClick={(e) => !StartUpload.documents_supplementaires ? Register(e, 'documents_supplementaires', DocumentsSupplémentaires, 'DocumentsSupplémentaires') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.documents_supplementaires !== '' ? errors.documents_supplementaires : ''}</div>
                                    {ResponseMsgSuccsess.documents_supplementaires != null ? <div className='ok'>{ResponseMsgSuccsess.documents_supplementaires}</div> : ''}
                                    {ResponseMsgFail.documents_supplementaires != null ? <div className='no'>{ResponseMsgFail.documents_supplementaires}</div> : ''}
                                    {
                                        StartUpload.documents_supplementaires ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.documents_supplementaires}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.documents_supplementaires + '%' }}>
                                                    {progress.documents_supplementaires + '%'}
                                                </div>
                                            </div> : ''}

                                </div></> : ''
                                }

                                <div className="col-lg-12 mt-5">
                                <div className='d-flex text-center justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Label?.justificatifs_des_credits[Lang]}</div>
                                    <hr />
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(1)
                                            {OldJustificatifsdescrédits ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldJustificatifsdescrédits ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldJustificatifsdescrédits}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('justificatifs_des_credits_1')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={justificatifs_des_credits_1Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setJustificatifsdescrédits(e.target.files[0]) : setJustificatifsdescrédits('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Justificatifsdescrédits ? <div onClick={(e) => !StartUpload.justificatifs_des_credits_1 ? Register(e, 'justificatifs_des_credits_1', Justificatifsdescrédits, 'Justificatifsdescrédits') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.justificatifs_des_credits_1 !== '' ? errors.justificatifs_des_credits_1 : ''}</div>
                                    {ResponseMsgSuccsess.justificatifs_des_credits_1 != null ? <div className='ok'>{ResponseMsgSuccsess.justificatifs_des_credits_1}</div> : ''}
                                    {ResponseMsgFail.justificatifs_des_credits_1 != null ? <div className='no'>{ResponseMsgFail.justificatifs_des_credits_1}</div> : ''}
                                    {
                                        StartUpload.justificatifs_des_credits_1 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.justificatifs_des_credits_1}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.justificatifs_des_credits_1 + '%' }}>
                                                    {progress.justificatifs_des_credits_1 + '%'}
                                                </div>
                                            </div> : ''}

                                </div>


                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(2)
                                            {OldJustificatifsdescrédits_2 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldJustificatifsdescrédits_2 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldJustificatifsdescrédits_2}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('justificatifs_des_credits_2')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={justificatifs_des_credits_2Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setJustificatifsdescrédits_2(e.target.files[0]) : setJustificatifsdescrédits_2('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Justificatifsdescrédits_2 ? <div onClick={(e) => !StartUpload.justificatifs_des_credits_2 ? Register(e, 'justificatifs_des_credits_2', Justificatifsdescrédits_2, 'Justificatifsdescrédits_2') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.justificatifs_des_credits_2 !== '' ? errors.justificatifs_des_credits_2 : ''}</div>
                                    {ResponseMsgSuccsess.justificatifs_des_credits_2 != null ? <div className='ok'>{ResponseMsgSuccsess.justificatifs_des_credits_2}</div> : ''}
                                    {ResponseMsgFail.justificatifs_des_credits_2 != null ? <div className='no'>{ResponseMsgFail.justificatifs_des_credits_2}</div> : ''}
                                    {
                                        StartUpload.justificatifs_des_credits_2 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.justificatifs_des_credits_2}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.justificatifs_des_credits_2 + '%' }}>
                                                    {progress.justificatifs_des_credits_2 + '%'}
                                                </div>
                                            </div> : ''}

                                </div>


                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(3)
                                            {OldJustificatifsdescrédits_3 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldJustificatifsdescrédits_3 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldJustificatifsdescrédits_3}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('justificatifs_des_credits_3')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={justificatifs_des_credits_3Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setJustificatifsdescrédits_3(e.target.files[0]) : setJustificatifsdescrédits_3('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Justificatifsdescrédits_3 ? <div onClick={(e) => !StartUpload.justificatifs_des_credits_3 ? Register(e, 'justificatifs_des_credits_3', Justificatifsdescrédits_3, 'Justificatifsdescrédits_3') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.justificatifs_des_credits_3 !== '' ? errors.justificatifs_des_credits_3 : ''}</div>
                                    {ResponseMsgSuccsess.justificatifs_des_credits_3 != null ? <div className='ok'>{ResponseMsgSuccsess.justificatifs_des_credits_3}</div> : ''}
                                    {ResponseMsgFail.justificatifs_des_credits_3 != null ? <div className='no'>{ResponseMsgFail.justificatifs_des_credits_3}</div> : ''}
                                    {
                                        StartUpload.justificatifs_des_credits_3 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.justificatifs_des_credits_3}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.justificatifs_des_credits_3 + '%' }}>
                                                    {progress.justificatifs_des_credits_3 + '%'}
                                                </div>
                                            </div> : ''}

                                </div>



                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(4)
                                            {OldJustificatifsdescrédits_4 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldJustificatifsdescrédits_4 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldJustificatifsdescrédits_4}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('justificatifs_des_credits_4')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={justificatifs_des_credits_4Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setJustificatifsdescrédits_4(e.target.files[0]) : setJustificatifsdescrédits_4('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Justificatifsdescrédits_4 ? <div onClick={(e) => !StartUpload.justificatifs_des_credits_4 ? Register(e, 'justificatifs_des_credits_4', Justificatifsdescrédits_4, 'Justificatifsdescrédits_4') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.justificatifs_des_credits_4 !== '' ? errors.justificatifs_des_credits_4 : ''}</div>
                                    {ResponseMsgSuccsess.justificatifs_des_credits_4 != null ? <div className='ok'>{ResponseMsgSuccsess.justificatifs_des_credits_4}</div> : ''}
                                    {ResponseMsgFail.justificatifs_des_credits_4 != null ? <div className='no'>{ResponseMsgFail.justificatifs_des_credits_4}</div> : ''}
                                    {
                                        StartUpload.justificatifs_des_credits_4 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.justificatifs_des_credits_4}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.justificatifs_des_credits_4 + '%' }}>
                                                    {progress.justificatifs_des_credits_4 + '%'}
                                                </div>
                                            </div> : ''}

                                            <hr />

                                </div>

                                {Secteur == "0" ? <>
                                <h5 className='text-center mt-5 pt-5'>
                               
                                {Label?.prive?.[Lang]}
                                <hr />
                                </h5>
                                <div className="col-lg-12">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.la_das[Lang]}
                                            {OldRegistreDeCommerce ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>

                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>

                                    {OldRegistreDeCommerce ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldRegistreDeCommerce}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('registre_de_commerce')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={registre_de_commerceRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setRegistreDeCommerce(e.target.files[0]) : setRegistreDeCommerce('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldChèque ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldChèque}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {RegistreDeCommerce ? <div onClick={(e) => !StartUpload.registre_de_commerce ? Register(e, 'registre_de_commerce', RegistreDeCommerce, 'registre_de_commerce') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.registre_de_commerce !== '' ? errors.registre_de_commerce : ''}</div>
                                    {ResponseMsgSuccsess.registre_de_commerce != null ? <div className='ok'>{ResponseMsgSuccsess.registre_de_commerce}</div> : ''}
                                    {ResponseMsgFail.registre_de_commerce != null ? <div className='no'>{ResponseMsgFail.registre_de_commerce}</div> : ''}
                                    {
                                        StartUpload.registre_de_commerce ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.registre_de_commerce}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.registre_de_commerce + '%' }}>
                                                    {progress.registre_de_commerce + '%'}
                                                </div>
                                            </div> : ''}

                                </div>
                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.cnas[Lang]}
                                            {OldAffiliationCNAS ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>


                                    {OldAffiliationCNAS ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldAffiliationCNAS}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('affiliation_cnas')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={affiliation_cnasRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setAffiliationCNAS(e.target.files[0]) : setAffiliationCNAS('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {AffiliationCNAS ? <div onClick={(e) => !StartUpload.affiliation_cnas ? Register(e, 'affiliation_cnas', AffiliationCNAS, 'AffiliationCNAS') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.affiliation_cnas !== '' ? errors.affiliation_cnas : ''}</div>
                                    {ResponseMsgSuccsess.affiliation_cnas != null ? <div className='ok'>{ResponseMsgSuccsess.affiliation_cnas}</div> : ''}
                                    {ResponseMsgFail.affiliation_cnas != null ? <div className='no'>{ResponseMsgFail.affiliation_cnas}</div> : ''}
                                    {
                                        StartUpload.affiliation_cnas ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.affiliation_cnas}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.affiliation_cnas + '%' }}>
                                                    {progress.affiliation_cnas + '%'}
                                                </div>
                                            </div> : ''}

                                </div>


                                <div className="col-lg-12 mt-5">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label mb-0">{Label?.releve_de_compte_une_annee[Lang]} 
                                            {OldRelevedecompte_une_annee ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>
                                    <div className="obligatoire-btn">{Label?.obligatoire?.[Lang]}</div>


                                    {OldRelevedecompte_une_annee ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldRelevedecompte_une_annee}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('releve_de_compte_un_ans')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={releve_de_compte_un_ansRef} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setRelevedecompte_une_annee(e.target.files[0]) : setRelevedecompte_une_annee('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Relevedecompte_une_annee ? <div onClick={(e) => !StartUpload.releve_de_compte_un_ans ? Register(e, 'releve_de_compte_un_ans', Relevedecompte_une_annee, 'Relevedecompte_une_annee') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.releve_de_compte_un_ans !== '' ? errors.releve_de_compte_un_ans : ''}</div>
                                    {ResponseMsgSuccsess.releve_de_compte_un_ans != null ? <div className='ok'>{ResponseMsgSuccsess.releve_de_compte_un_ans}</div> : ''}
                                    {ResponseMsgFail.releve_de_compte_un_ans != null ? <div className='no'>{ResponseMsgFail.releve_de_compte_un_ans}</div> : ''}
                                    {
                                        StartUpload.releve_de_compte_un_ans ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.releve_de_compte_un_ans}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.releve_de_compte_un_ans + '%' }}>
                                                    {progress.releve_de_compte_un_ans + '%'}
                                                </div>
                                            </div> : ''}

                                </div></> : ''}



                               


                                <div className="col-lg-12 mt-5 pt-5">

                                <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>{Secteur == '0' ? Label?.autres_documents?.[Lang] : Label?.autres_documents_retraites?.[Lang]}</div>
                                    <hr />

                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(1)
                                            {OldAutresdocuments_1 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldAutresdocuments_1 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldAutresdocuments_1}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('autres_documents_1')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={autres_documents_1Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setAutresdocuments_1(e.target.files[0]) : setAutresdocuments_1('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Autresdocuments_1 ? <div onClick={(e) => !StartUpload.autres_documents_1 ? Register(e, 'autres_documents_1', Autresdocuments_1, 'Autresdocuments_1') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.autres_documents_1 !== '' ? errors.autres_documents_1 : ''}</div>
                                    {ResponseMsgSuccsess.autres_documents_1 != null ? <div className='ok'>{ResponseMsgSuccsess.autres_documents_1}</div> : ''}
                                    {ResponseMsgFail.autres_documents_1 != null ? <div className='no'>{ResponseMsgFail.autres_documents_1}</div> : ''}
                                    {
                                        StartUpload.autres_documents_1 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.autres_documents_1}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.autres_documents_1 + '%' }}>
                                                    {progress.autres_documents_1 + '%'}
                                                </div>
                                            </div> : ''}

                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className='d-flex justify-content-center align-items-center upload-file-section'>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3 svg-uplaodsection' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg> */}
                                        <label htmlFor="inputState" className="form-label mb-0">(2)
                                            {OldAutresdocuments_2 ?
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#7acf7d" />
                                                            <stop offset="1" stop-color="#84e0be" />
                                                        </linearGradient>
                                                    </defs>
                                                    <g id="noun-true-1896685" transform="translate(-84 -14)">
                                                        <path id="Tracé_316" data-name="Tracé 316" d="M103,14a19,19,0,1,0,19,19A19.017,19.017,0,0,0,103,14Zm9.04,14.24L101.88,39.8a1.007,1.007,0,0,1-.8.36h-.04a1.093,1.093,0,0,1-.76-.32L94,33.6a1.1,1.1,0,0,1,1.56-1.56L101,37.48l9.4-10.68a1.123,1.123,0,0,1,1.56-.08,1.074,1.074,0,0,1,.08,1.52Z" fill="url(#linear-gradient)" />
                                                    </g>
                                                </svg>
                                                :
                                                <svg className='ms-1 svg-upload-true-or-false' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="38" viewBox="0 0 38 38">
                                                    <defs>
                                                        <linearGradient id="linear-gradient2" x1="0.771" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                                            <stop offset="0" stop-color="#ff5858" />
                                                            <stop offset="1" stop-color="#e084a6" />
                                                        </linearGradient>
                                                    </defs>
                                                    <path id="Soustraction_11" data-name="Soustraction 11" d="M19,38A19,19,0,1,1,38,19,19.021,19.021,0,0,1,19,38Zm.048-16.6h0L23.416,27.4a1.5,1.5,0,0,0,1.17.6,1.459,1.459,0,0,0,.832-.286,1.325,1.325,0,0,0,.557-.87,1.472,1.472,0,0,0-.245-1.106l-4.94-6.76,4.94-6.709a1.508,1.508,0,0,0,.253-1.066,1.316,1.316,0,0,0-.566-.91,1.379,1.379,0,0,0-2,.311l-4.369,5.981-4.394-5.981A1.34,1.34,0,0,0,13.53,10,1.514,1.514,0,0,0,12.1,11.2a1.468,1.468,0,0,0,.241,1.066l4.94,6.709-4.94,6.76A1.438,1.438,0,0,0,12.1,26.82a1.362,1.362,0,0,0,.575.894A1.339,1.339,0,0,0,13.51,28a1.473,1.473,0,0,0,1.144-.6L19.047,21.4Z" fill="url(#linear-gradient2)" />
                                                </svg>
                                            }
                                        </label>
                                    </div>


                                    {OldAutresdocuments_2 ?
                                        <div className='document-btn'>

                                            <a className='voir-btn my-1 d-flex mx-1' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/pdf?test=${OldAutresdocuments_2}`}>
                                                <div style={{ color: 'white' }} className='me-2'>
                                                   {Label?.voir[Lang]}  
                                                </div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                        </g>
                                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a>

                                            <a onClick={() => DeleteImage('autres_documents_2')} >
                                                <div className='delete-btn my-1 d-flex'>
                                                    <div style={{ color: 'white' }} className='me-2'>
                                                    {Label?.delete[Lang]}   
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                        <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                                    </svg>
                                                </div></a>

                                        </div>
                                        : ''}
                                    <div className='d-md-flex justify-content-center m-auto' style={{width : 'fit-content'}} >
                                        <div>

                                            <input type="file" ref={autres_documents_2Ref} accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf;capture=camera" onChange={(e) => e.target.files.length > 0 ? setAutresdocuments_2(e.target.files[0]) : setAutresdocuments_2('')} className="upload-input-section form-control" id="inputCity" />
                                            {/* {OldDocumentsSupplémentaires ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL_Documents}/scan?test=${OldDocumentsSupplémentaires}`}> Voir le Fichier actuel </a> : ''} */}
                                        </div>
                                        {Autresdocuments_2 ? <div onClick={(e) => !StartUpload.autres_documents_2 ? Register(e, 'autres_documents_2', Autresdocuments_2, 'Autresdocuments_2') : ''} className="wafeer-login-btn-up mt-3 mt-md-1 ms-md-4">
                                            {Label?.upload[Lang]}   
                                        </div> : ''}
                                    </div>
                                    <div className='err-cart-sum'>{Showerrors && errors.autres_documents_2 !== '' ? errors.autres_documents_2 : ''}</div>
                                    {ResponseMsgSuccsess.autres_documents_2 != null ? <div className='ok'>{ResponseMsgSuccsess.autres_documents_2}</div> : ''}
                                    {ResponseMsgFail.autres_documents_2 != null ? <div className='no'>{ResponseMsgFail.autres_documents_2}</div> : ''}
                                    {
                                        StartUpload.autres_documents_2 ?


                                            <div
                                                className="progress"
                                                role="progressbar"
                                                aria-label="Example with label"
                                                aria-valuenow={progress.autres_documents_2}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <div className="progress-bar" style={{ width: progress.autres_documents_2 + '%' }}>
                                                    {progress.autres_documents_2 + '%'}
                                                </div>
                                            </div> : ''}

                                            <hr />

                                </div>
                                


                                            {/* <div className="have-already mt-3">
                        <a href="/login" style={{ color: '#4b4b4b' }}>
                            J'ai déjà un compte ? Log in
                        </a>
                    </div> */}






                                        </div>



                                    </div>
                                </div>
                            </section>












                        </form>
                    </div ></div>
            </div>

        </div>
    )
}

export default UpdateClientDocuments