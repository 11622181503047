import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Routes, Route, useLocation } from "react-router-dom";
import Boutique from './pages/Boutique';
import Nav from './components/Global/Nav';
import Aide from './pages/Aide';
import CreateAccount from './pages/CreateAccount';
import MyAccount from './pages/MyAccount';
import Dashboard from './pages/DashboardUser/Dashboard';
import Listedesventes from './pages/DashboardUser/ListedesventesUser';
import Listedescommendes from './pages/DashboardUser/Listedescommendes';
import CreateVent from './pages/DashboardUser/CreateVentUser';
import VenteDetail from './pages/DashboardUser/VenteDetail';
import SearchPage from './pages/SearchPage';

import DashboardAdmin from './pages/DashboardAdmin/Dashboard';
import VentAdmin from './pages/DashboardAdmin/CreateVent';
import CreateBoutique from './pages/DashboardAdmin/CreateBoutique';
import ListedesventesAdmin from './pages/DashboardAdmin/Listedesventes';
import Product from './pages/Product';
import UpdateVent from './pages/DashboardAdmin/UpdateVent';
import Listedesboutiques from './pages/DashboardAdmin/Listedesboutique';
import UpdateBoutique from './pages/DashboardAdmin/UpdateBoutique.';
import ListVendeur from './pages/DashboardAdmin/ListVendeur';
import VendeurVoir from './pages/DashboardAdmin/VendeurVoir';
import UpdateVendeur from './pages/DashboardAdmin/UpdateVendeur';
import { Layout } from './components/Global/Layout';
import ListAdmins from './pages/DashboardAdmin/ListAdmins';
import UpdateAdmin from './pages/DashboardAdmin/UpdateAdmin';
import NewAdmin from './pages/DashboardAdmin/NewAdmin';
import LinkAdminVendeur from './pages/DashboardAdmin/LinkAdminVendeur';
import ListUsers from './pages/DashboardAdmin/ListUsers';
import CreateBoutiqueUser from './pages/DashboardUser/CreateBoutiqueUser';
import ListedesboutiquesUser from './pages/DashboardUser/ListedesboutiqueUser';
import UpdateBoutiqueUser from './pages/DashboardUser/UpdateBoutiqueUser.';
import ListedesventesUser from './pages/DashboardUser/ListedesventesUser';
import CreateVentUser from './pages/DashboardUser/CreateVentUser';
import UpdateVentUser from './pages/DashboardUser/UpdateVentUser';
import VendeurVoirUser from './pages/DashboardUser/VendeurVoirUser';
import UpdateUser from './pages/DashboardUser/UpdateUser';
import ListedesboutiquesAdmin from './pages/DashboardAdmin/ListedesboutiqueAdmin';
import ListedesventesAdminUser from './pages/DashboardAdmin/ListedesventesAdmin';
import UpdateVentesUsers from './pages/DashboardAdmin/UpdateVentesUsers';
import ForgetPassword from './pages/ForgetPassword';
import UpdatePassword from './pages/UpdatePassword';
import ProtectedRoute from './pages/ProtectedRoute';
import ListeCat from './pages/DashboardAdmin/ListCat';
import CreateCat from './pages/DashboardAdmin/CreateCat';
import UpdateCat from './pages/DashboardAdmin/UpdateCat';
// import Simulation from './pages/Simulation';
// import CreateQrCode from './pages/DashboardUser/CreateQrCode';
// import SimulationAchats from './pages/DashboardUser/Simulation';
import FormRegister from './components/CreateAccount/FormRegister';
import Inscription from './pages/DashboardAdmin/Inscription';
import InscriptionByid from './pages/DashboardAdmin/InscriptionById';
import MakePdf from './components/MakePdf';
import IncubationRequests from './pages/DashboardAdmin/IncubationRequests';
import IncubationRequestsByid from './pages/DashboardAdmin/IncubationRequestsById';
import ConsultingRequests from './pages/DashboardAdmin/ConsultingRequests';
import ConsultingRequestsByid from './pages/DashboardAdmin/ConsultingRequestsByid';
import TranslateRequests from './pages/DashboardAdmin/TranslateRequests';
import TranslateRequestsByid from './pages/DashboardAdmin/TranslateRequestsById';
import SocialMediaRequests from './pages/DashboardAdmin/SocialMediaRequests';
import SocialMediaRequestsByid from './pages/DashboardAdmin/SocialMediaRequestsByid';
import ContactRequests from './pages/DashboardAdmin/ContactRequests';
import ContactRequestsByid from './pages/DashboardAdmin/ContactRequestsById';
import RateRequests from './pages/DashboardAdmin/RateRequests';
import RateRequestsByid from './pages/DashboardAdmin/RateRequestsById';
import UpdateAdminPage from './pages/DashboardAdmin/UpdateAdminPage';
import PaymentDossier from './pages/DashboardAdmin/PaymentDossier';
import UpdatePaymentDossier from './pages/DashboardAdmin/UpdatePaymentDossier';
import ProjectsCategories from './pages/DashboardAdmin/ProjectsCategories';
import UpdateProjectCategorie from './pages/DashboardAdmin/UpdateProjectsCategories';
import CreateProject from './pages/DashboardAdmin/CreateProject';
import ProjectsList from './pages/DashboardAdmin/ProjectsList';
import UpdateProject from './pages/DashboardAdmin/UpdateProject';
import UpdateClientDocuments from './pages/DashboardAdmin/UpdateUserDocuments';
import AddAction from './pages/DashboardAdmin/AddAction';
import ClientActions from './pages/DashboardAdmin/ClientsActions';
import SimulationIamal from './pages/DashboardAdmin/Simulation';
import PDF from './pages/PDF';
import GalleryProjectsList from './pages/DashboardAdmin/GalleryProjectsList';
import CreateGalleryProject from './pages/DashboardAdmin/CreateGalleryProject';
import UpdateGalleryProject from './pages/DashboardAdmin/UpdateGalleryProject';
import ProtectedRouteAlsalam from './pages/ProtectedRouteAlsalam';
import LoginAlsalam from './pages/LoginAlsalam';
import DashboardAlsalam from './pages/DashboardAlsalam/Dashboard';
import ListDemandes from './pages/DashboardAlsalam/ListDemandes';
import VoirDemande from './pages/DashboardAlsalam/VoirDemande';
import ClientDocuments from './pages/DashboardAlsalam/ClientDocuments';
import EtatProjet from './pages/DashboardAlsalam/EtatProjet';
import ScanDocuments from './pages/DashboardAdmin/ScanDocuments';
import ScanedDocuments from './pages/DashboardAlsalam/ScanedDocuments';
import FormationsList from './pages/DashboardAdmin/FormationsList';
import CreateFormation from './pages/DashboardAdmin/CreateFormation';
import UpdateFormation from './pages/DashboardAdmin/UpdateFormation';
import PaymentFormation from './pages/DashboardAdmin/PaymentFormation';
import UpdatePaymentFormation from './pages/DashboardAdmin/UpdatePaymentFormation';
import ProtectedRouteUser from './pages/ProtectedRouteUser';

import UploadDocuments from './pages/DashboardUser/UploadDocuments';
import MyDocuments from './pages/DashboardUser/MyDocuments';
import LoginUser from './pages/LoginUser';
import AlsalamEchéanciers from './pages/DashboardAdmin/Echéanciers';
import AlsalamEchéanciersList from './pages/DashboardAdmin/EchéanciersList';
import UserLabel from './pages/DashboardAdmin/UserLabel';
import VerifyCode from './pages/VerifyCode';
import Analytic from './pages/DashboardAdmin/Analytic';
import UpdateCommande from './pages/DashboardAdmin/UpdateCommande';
import WhatsappMessage from './pages/DashboardAdmin/WhatsappMessage';
import CreateQrCode from './pages/DashboardAdmin/CreateQrCode';
import QrCodesList from './pages/DashboardAdmin/QrCodesList';
import QrCodeById from './pages/DashboardAdmin/QrCodeById';
import QrCodeAddAction from './pages/DashboardAdmin/QrCodeAddAction';
import QrCodeActions from './pages/DashboardAdmin/QrCodeActions';
import QrCodeScanner from './pages/DashboardAdmin/QrCodeScanner';

function App() {

  const location = useLocation();

  console.log(location);


  useEffect(() => {

    var nav = document.querySelector('nav'); // Identify target

    if (nav) {

      window.addEventListener('scroll', function (event) { // To listen for event
        event.preventDefault();

        if (window.scrollY <= 150) { // Just an example
          nav.style.backgroundColor = 'transparent';
        } else {
          nav.style.backgroundColor = '#3838388c';
        }
      });


    }

  }, [location])

  console.log(process.env.REACT_APP_LOCAL_URL);


  return (
    <>

      {/* {location.pathname !== "/account" && location.pathname !== "/dashboard" && location.pathname !== "/listventes" && location.pathname !== "/listcommendes" && location.pathname !== "/createvent" && location.pathname !== "/listventesadmin" && location.pathname !== "/vente" ? <Nav /> : ''} */}

      <Routes>

        <Route path="*" element={<LoginUser />} />
        <Route path="/" element={<LoginUser />} />

        <Route path="/login-client" element={<FormRegister />} />
      {/* <Route path="/makepdf" element={<MakePdf />} /> */}


        {/* <Route path="/" element={<Layout />}>
           
          <Route index element={<Home />} />
          <Route path="/boutique/:slug" element={<Boutique />} />
         
          <Route path="/aide" element={<Aide />} />
          
          <Route path="/createaccount" element={<CreateAccount />} />
          <Route path="/search" element={<SearchPage />} />
        <Route path="/product/:boutique/:vente" element={<Product />} />

        </Route> */}


        <Route path="/account" element={<MyAccount />} />
        <Route path='/forgetpassword' element={< ForgetPassword />} />
        <Route path='/verifycode' element={< VerifyCode />} />
        <Route path='/updatepassword' element={< UpdatePassword />} />


        <Route path="/login-alsalam" element={<LoginAlsalam />} />
        <Route path="/login" element={<LoginUser />} />

        <Route element={<ProtectedRouteAlsalam Role={"adminBoutique"} />} >

          {/* Dashborad Alsalam */}
          <Route path="/dashboard-alsalam" element={<DashboardAlsalam />} />
          <Route path="/list-demandes" element={<ListDemandes />} />
          <Route path="/voir-demande/:id" element={<VoirDemande />} />
          <Route path="/client-documents/:id" element={<ClientDocuments />} />
          <Route path="/scanned-documents/:id" element={<ScanedDocuments />} />

          <Route path="/status-project/:id" element={<EtatProjet />} />



          {/* Dashborad Alsalam */}

        </Route>

        <Route element={<ProtectedRouteUser Role={"adminBoutique"} />} >
                {/* <Route path='/verifypayment' element={<VerifyPayment />} />
                <Route path='/verifydossierpayment' element={<VerifyPaymentDossier />} />


                <Route path='/payment' element={<Payment />} />
                <Route path='/payment-dossier' element={<PaymentDossier />} />


                <Route path='/paymentformation/:id' element={<PaymentFormation />} />
                <Route path='/verifypaymentformation/:id' element={<VerifyPaymentFormation />} />
                <Route path='/recu-formation-payment/:id' element={<ReceivedPaymentFormation />} />


                <Route path='/i3malform/:hash' element={<SimulationI3mal />} />
                <Route path='/validationi3mal/:id' element={<ValidationI3mal />} /> */}
                  {/* Dashborad User */}
                  <Route path="/dashboard" element={<Dashboard />} />


                  {/* <Route path="/createboutiqueuser" element={<CreateBoutiqueUser />} />
                  <Route path="/listboutiquesuser" element={<ListedesboutiquesUser />} />
                  <Route path="/updateboutiqueuser/:id_btq" element={<UpdateBoutiqueUser />} />

                  <Route path="/createvent" element={<CreateVentUser />} />
                  <Route path="/listventes" element={<ListedesventesUser />} />
                  <Route path="/mesformations" element={<MesFormations />} />

                  <Route path="/paymentuserdetails/:id" element={<PaymentUserDetails />} />

                  <Route path="/updatevent/:id_btq/:id_vente" element={<UpdateVentUser />} />

                  <Route path="/vendeur" element={<VendeurVoirUser />} /> */}

                  <Route path="/updateuser" element={<UpdateUser />} />
                  <Route path="/uploaddocumets" element={<UploadDocuments />} />
                  <Route path="/mydocuments" element={<MyDocuments />} />
                  {/* <Route path="/myinfo" element={<MesInfrormation />} /> */}
                  {/* <Route path="/subscription" element={<UserSubscripion />} /> */}





                  {/* <Route path="/vente" element={<VenteDetail />} /> */}
                  {/* <Route path="/listcommendes" element={<Listedescommendes />} /> */}
                  {/* Dashborad User */}

                </Route>


        {/* <Route element={<ProtectedRouteUser Role={"adminBoutique"} />} >

          
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/createboutiqueuser" element={<CreateBoutiqueUser />} />
          <Route path="/listboutiquesuser" element={<ListedesboutiquesUser />} />
          <Route path="/updateboutiqueuser/:id_btq" element={<UpdateBoutiqueUser />} />

          <Route path="/createvent" element={<CreateVentUser />} />
          <Route path="/createqrcode" element={<CreateQrCode />} />
          <Route path="/simulation" element={<SimulationAchats />} />


          <Route path="/listventes" element={<ListedesventesUser />} />
          <Route path="/updatevent/:id_btq/:id_vente" element={<UpdateVentUser />} />

          <Route path="/vendeur" element={<VendeurVoirUser />} />

          <Route path="/updateuser" element={<UpdateUser />} />

          <Route path="/vente" element={<VenteDetail />} />
          <Route path="/listcommendes" element={<Listedescommendes />} />
         

        </Route> */}





        <Route element={<ProtectedRoute Role={"adminDZ"} />} >
          {/* Dashborad Admin */}
          <Route path="/dashboardadmin" element={<Analytic />} />

          <Route path="/analytic" element={<Analytic />} />


          <Route path="/createventadmin" element={<VentAdmin />} />
          <Route path="/updateventadmin/:id_btq/:id_vente" element={<UpdateVent />} />
          <Route path="/updateventadminusers/:id_btq/:id_vente" element={<UpdateVentesUsers />} />


          <Route path="/createboutique" element={<CreateBoutique />} />
          <Route path="/listboutiquesadmin" element={<ListedesboutiquesAdmin />} />
          <Route path="/updateboutiqueadmin/:id_btq" element={<UpdateBoutique />} />

          <Route path="/listventesadmin" element={<ListedesventesAdmin />} />
          <Route path="/listventesuseradmin" element={<ListedesventesAdminUser />} />



          <Route path="/listadmins" element={<ListAdmins />} />
          <Route path="/newadmin" element={< NewAdmin />} />
          <Route path="/linkadminvendeur/:id" element={< LinkAdminVendeur />} />
          <Route path="/updateadmin/:id" element={<UpdateAdmin />} />
          <Route path="/updateadminpage/:id" element={<UpdateAdminPage />} />


          <Route path="/listusers" element={<ListUsers />} />


          <Route path="/inscription" element={<Inscription />} />
          <Route path="/inscriptionbyid/:id" element={<InscriptionByid />} />


          <Route path="/incubation-requests" element={<IncubationRequests />} />
          <Route path="/incubation-requests/:id" element={<IncubationRequestsByid />} />


          <Route path="/consulting-requests" element={<ConsultingRequests />} />
          <Route path="/consulting-requests/:id" element={<ConsultingRequestsByid />} />

          <Route path="/translate-requests" element={<TranslateRequests />} />
          <Route path="/translate-requests/:id" element={<TranslateRequestsByid />} />

          <Route path="/social-media-requests" element={<SocialMediaRequests />} />
          <Route path="/social-media-requests/:id" element={<SocialMediaRequestsByid />} />


          <Route path="/contact-requests" element={<ContactRequests />} />
          <Route path="/contact-requests/:id" element={<ContactRequestsByid />} />
          <Route path="/updatecommande/:id" element={<UpdateCommande />} />

          <Route path="/whatsapp-message" element={<WhatsappMessage />} />



          <Route path="/rate-requests" element={<RateRequests />} />
          <Route path="/rate-requests/:id" element={<RateRequestsByid />} />


          <Route path="/listvendeuradmin" element={<ListVendeur />} />
          <Route path="/vendeurbyone/:id" element={<VendeurVoir />} />
          <Route path="/updatevendeur/:id" element={<UpdateVendeur />} />



          <Route path="/listboutiques" element={<Listedesboutiques />} />


          <Route path="/category" element={<ListeCat />} />
          <Route path="/updatecategory/:id" element={<UpdateCat />} />


          <Route path="/paymentdossier" element={<PaymentDossier />} />
          <Route path="/updatepaymentdossier/:id" element={<UpdatePaymentDossier />} />

          <Route path="/paymentformation" element={<PaymentFormation />} />
          <Route path="/updatepaymentformation/:id" element={<UpdatePaymentFormation />} />



          <Route path="/projectscategories" element={<ProjectsCategories />} />
          <Route path="/createcategory" element={<CreateCat />} />
          <Route path="/updateprojectcategory/:id" element={<UpdateProjectCategorie />} />

          <Route path="/projects" element={<ProjectsList />} />
          <Route path="/createproject" element={<CreateProject />} />
          <Route path="/updateproject/:id_vente" element={<UpdateProject />} />

          <Route path="/update_user_label/:id_vente" element={<UserLabel />} />


          <Route path="/formations" element={<FormationsList />} />
          <Route path="/createformation" element={<CreateFormation />} />
          <Route path="/updateformation/:id_vente" element={<UpdateFormation />} />



          <Route path="/updatedocuments/:id" element={<UpdateClientDocuments />} />
          <Route path="/scandocuments/:id" element={<ScanDocuments />} />
          <Route path="/addaction/:id" element={<AddAction />} />
          <Route path="/clientaction/:id" element={<ClientActions />} />

          <Route path="/simulationiamal" element={<SimulationIamal />} />


          <Route path="/galleryprojects" element={<GalleryProjectsList />} />
          <Route path="/creategalleryproject" element={<CreateGalleryProject />} />
          <Route path="/updategalleryproject/:id_vente" element={<UpdateGalleryProject />} />





        <Route path="/echeanciers" element={<AlsalamEchéanciers />} />
        <Route path="/echeanciersexcel" element={<AlsalamEchéanciersList />} />

        <Route path="/createqrcode" element={<CreateQrCode />} />
        <Route path="/qrcodescanner" element={<QrCodeScanner />} />

        <Route path="/qrcodes" element={<QrCodesList />} />
        <Route path="/qrcode/:id" element={<QrCodeById />} />

        <Route path="/qrcode_addaction/:id" element={<QrCodeAddAction />} />
        <Route path="/qrcode_actions/:id" element={<QrCodeActions />} />






          {/* <Route path="/vente" element={<VenteDetail />} />
    <Route path="/listcommendes" element={<Listedescommendes />} /> */}
          {/* Dashborad Admin */}
        </Route>


        <Route path="/pdftest" element={<PDF />} />




      </Routes>
    </>
  );
}

export default App;
