import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardUser/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const UploadDocuments = () => {

    const { id } = useParams();
    const [ProductData, setProductData] = useState('')

    const [searchParams, setSearchParams] = useSearchParams();
    const _ROLE = searchParams.get('role');

    const [AccountStatus, setAccountStatus] = useState()

    const [progress, setProgress] = useState(0)
    const [StartUpload, setStartUpload] = useState(false)


    const ValidateToken = async () => {
        setLoading(true)
        try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/client_document_check`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${AuthUser?.token}`
                },
            });

            console.log(response);

            if (response['exist'] == true) {
                setAccountStatus(true)

            } else {
                setAccountStatus(false)
            }

            // console.log(response['errorCode']);
            if (response['error'] == true) {
                // return navigate('/')
            }



            setLoading(false)


        } catch (error) {
            // console.error(error.message);
            setLoading(false)

        }
    }

    useEffect(() => {

        ValidateToken();

    }, [])



    // useEffect(() => {

    //     const fetchData = async () => {
    //         setLoading(true);
    //         try {
    //             const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/${id}`, {
    //                 headers: {
    //                     'Authorization': `Bearer ${Auth?.token}`
    //                 }
    //             });
    //             if (response['message'] == 'Token invalid') {
    //                 setAuth(false);
    //                 return navigate('/account')

    //             }
    //             console.log(response['data']);
    //             setProductData(response['data'])
    //             setNom(response['data']['name'])
    //             setEmail(response['data']['email'])
    //             setMobile(response['data']['mobile'])

    //             setRole(response['data']['role'])


    //         } catch (error) {
    //             setLoading(false);
    //             console.error(error.message);
    //         }
    //         setLoading(false);
    //     }

    //     fetchData();

    // }, []);




    const { AuthUser, setAuthUser } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)











    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Carteidentité, setCarteidentité] = useState('');
    const [CarteCIB, setCarteCIB] = useState('')
    const [ReleveBancaire, setReleveBancaire] = useState('');
    const [AttestationAffiliation, setAttestationAffiliation] = useState('');
    const [Contratdetravail, setContratdetravail] = useState('');
    const [Attestationdetravail, setAttestationdetravail] = useState('');



    const [Loading, setLoading] = useState(false)
    const [errors, seterrors] = useState({ Titreduproduit: '', Réferenceduproduit: '', Marque: '' })
    const [err, seterr] = useState([])
    const [Showerrors, setShowerrors] = useState(false)


    const recaptchaRef = useRef('');
    const [CaptchaChecked, setCaptchaChecked] = useState(false)
    const [captchaerror, setcaptchaerror] = useState('')



    function Validate() {

        if (!Carteidentité || Carteidentité.size > 2097152) {
            seterrors((prev) => ({ ...prev, Carteidentité: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Carteidentité: '' }))
        }

        if (!CarteCIB || CarteCIB.size > 2097152) {
            seterrors((prev) => ({ ...prev, CarteCIB: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, CarteCIB: '' }))
        }

        if (!ReleveBancaire || ReleveBancaire.size > 2097152) {
            seterrors((prev) => ({ ...prev, ReleveBancaire: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, ReleveBancaire: '' }))
        }


        if (!AttestationAffiliation || AttestationAffiliation.size > 2097152) {
            seterrors((prev) => ({ ...prev, AttestationAffiliation: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, AttestationAffiliation: '' }))
        }

        if (!Contratdetravail || Contratdetravail.size > 2097152) {
            seterrors((prev) => ({ ...prev, Contratdetravail: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Contratdetravail: '' }))
        }

        if (!Attestationdetravail || Attestationdetravail.size > 2097152) {
            seterrors((prev) => ({ ...prev, Attestationdetravail: "Le fichier requis est obligatoire et ne doit pas dépasser 3 Mo" }))
        } else {
            seterrors((prev) => ({ ...prev, Attestationdetravail: '' }))
        }


    }


    useEffect(() => {

        Validate();

    }, [Carteidentité, CarteCIB, ReleveBancaire, AttestationAffiliation, Contratdetravail, Attestationdetravail])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    function GoNext() {

        setShowerrors(true)

        if (errors.Attestationdetravail !== '' || errors.Carteidentité !== '' || errors.CarteCIB !== '' || errors.ReleveBancaire !== '' || errors.AttestationAffiliation !== '' || errors.Contratdetravail !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }

            console.log(errors);
            return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }



    function Register(e) {

        e.preventDefault();

        setLoading(true)

        setShowerrors(true)

        if (errors.Attestationdetravail !== '' || errors.Carteidentité !== '' || errors.CarteCIB !== '' || errors.ReleveBancaire !== '' || errors.AttestationAffiliation !== '' || errors.Contratdetravail !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");
            setLoading(false)


            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }

            console.log(errors);
            return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' })

        const formData = new FormData();
        formData.append("carte_identite", Carteidentité);
        formData.append("carte_cib", CarteCIB);
        formData.append("releve_bancaire", ReleveBancaire);
        formData.append("attestation_affiliation", AttestationAffiliation);
        formData.append("contrat_de_travail", Contratdetravail);
        formData.append("attestation_de_travail", Attestationdetravail);

        setStartUpload(true)

        axios.post(`${process.env.REACT_APP_LOCAL_URL}/vendeur/insert_client_document`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${AuthUser?.token}`
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        })
            .then(function (response) {
                console.log(response.data);
                if (response.data['message'] == 'Token invalid') {
                    setAuthUser(false);
                    return navigate('/login')

                }
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    setLoading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    setLoading(false)

                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été ajoutées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location = "/mydocuments";
                    });
                }

                setStartUpload(false)


            }).catch(function (error) {
                setStartUpload(false)

                setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });






    }








    return (
        <div className=''>
            {/* {err.length > 0 && err.map((item, i) => (

            <div className="alert alert-danger" role="alert">
                {item}
            </div>

        ))
        } */}

        {Loading && !StartUpload ?
            <Loader /> : ''
            }
            <form className="row g-0">
                <div className='container py-0 pt-0'>
                    {err?.length > 0 && err?.map((item, i) => (

                        item.constructor == String ?
                            <div className="alert-store" role="alert">
                                - {item}
                            </div>
                            : ''

                    ))
                    }

                </div>



                {StartUpload && Loading ?
                    <div className="screen">
                        <div className="loader">

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="400" height="81.078" viewBox="0 0 406.418 81.078">
                                    <defs>
                                        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                                            <stop offset="0" stop-color="#fca01f" />
                                            <stop offset="1" stop-color="#f27c00" />
                                        </linearGradient>
                                        <linearGradient id="linear-gradient-5" x1="0.627" y1="-63.466" x2="2.169" y2="-63.466" xlinkHref="#linear-gradient" />
                                    </defs>
                                    <g id="Groupe_136" data-name="Groupe 136" transform="translate(-85.678 -423.155)">
                                        <g id="Groupe_93" data-name="Groupe 93" transform="translate(195.312 430.316)">
                                            <path id="Tracé_211" data-name="Tracé 211" d="M221.726,432.043h3.944l11.65,35.5,12.136-35.376h5.037l-15.171,42.779h-4.066l-11.65-34.466L211.9,474.944h-4l-15.171-42.779h5.218l12.137,35.376Z" transform="translate(-192.721 -430.101)" fill="#fff" />
                                            <path id="Tracé_212" data-name="Tracé 212" d="M259.9,447.352a28.292,28.292,0,0,0-4.46,1.669l-1.4-3.822a31.254,31.254,0,0,1,5.28-1.912,24.34,24.34,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.354,15.354,0,0,1-4.4,3.762,13.435,13.435,0,0,1-6.947,1.639,15.323,15.323,0,0,1-4.278-.607,11.625,11.625,0,0,1-3.762-1.851,9.462,9.462,0,0,1-2.67-3.064,8.8,8.8,0,0,1-1-4.308,9.588,9.588,0,0,1,.971-4.4,8.684,8.684,0,0,1,2.761-3.185,13.239,13.239,0,0,1,4.247-1.942,20.714,20.714,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.349,38.349,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.765,18.765,0,0,0,259.9,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.435,5.435,0,0,0,.635,2.67,6.162,6.162,0,0,0,1.724,1.972,7.854,7.854,0,0,0,2.509,1.244,10.388,10.388,0,0,0,2.995.424,12.786,12.786,0,0,0,4.081-.637,10.845,10.845,0,0,0,3.327-1.76,8.331,8.331,0,0,0,2.238-2.669,7.24,7.24,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.333,28.333,0,0,0-5.171-.425Q260.768,458.487,258.409,460.247Z" transform="translate(-191.304 -429.846)" fill="#fff" />
                                            <path id="Tracé_213" data-name="Tracé 213" d="M301.232,443.436v4H291.34v27.246h-4.671V447.441H282.36v-4.065h4.309v-2.731q0-5.157,2.457-7.828a9.054,9.054,0,0,1,7.01-2.67,18.92,18.92,0,0,1,2.821.182,12.046,12.046,0,0,1,2.336.607V435q-1.274-.364-2.306-.577a11.174,11.174,0,0,0-2.246-.212q-5.46,0-5.461,6.675v2.548Z" transform="translate(-190.552 -430.147)" fill="#fff" />
                                            <path id="Tracé_214" data-name="Tracé 214" d="M307.089,464.955a10.91,10.91,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.252,11.252,0,0,0,3.944.7,12.026,12.026,0,0,0,5.613-1.215,16.59,16.59,0,0,0,4.278-3.216l2.913,2.61a19.167,19.167,0,0,1-5.4,4.278,15.94,15.94,0,0,1-7.524,1.608,15.661,15.661,0,0,1-6.068-1.184,15.1,15.1,0,0,1-4.99-3.337,15.9,15.9,0,0,1-3.4-5.189,17.217,17.217,0,0,1-1.261-6.675,18.109,18.109,0,0,1,1.122-6.4,16.07,16.07,0,0,1,3.126-5.218,14.583,14.583,0,0,1,4.763-3.489,15.136,15.136,0,0,1,12.227.031,13.564,13.564,0,0,1,4.611,3.55,15.462,15.462,0,0,1,2.852,5.279,21.217,21.217,0,0,1,.971,6.492v.668a5.672,5.672,0,0,1-.06.789H305.876A12.886,12.886,0,0,0,307.089,464.955Zm18.872-7.98a16.169,16.169,0,0,0-.875-4.065,10.883,10.883,0,0,0-1.93-3.4,9.343,9.343,0,0,0-3.017-2.336,9.22,9.22,0,0,0-4.132-.88,8.852,8.852,0,0,0-3.8.819,9.913,9.913,0,0,0-3.076,2.245,11.81,11.81,0,0,0-2.171,3.368,14.289,14.289,0,0,0-1.085,4.247Z" transform="translate(-190.098 -429.852)" fill="#fff" />
                                            <path id="Tracé_215" data-name="Tracé 215" d="M339.141,464.955a10.893,10.893,0,0,0,2.488,3.338,10.506,10.506,0,0,0,3.4,2.063,11.254,11.254,0,0,0,3.945.7,12.022,12.022,0,0,0,5.612-1.215,16.588,16.588,0,0,0,4.278-3.216l2.913,2.61a19.168,19.168,0,0,1-5.4,4.278,15.939,15.939,0,0,1-7.524,1.608,15.662,15.662,0,0,1-6.068-1.184,15.077,15.077,0,0,1-4.99-3.337,15.918,15.918,0,0,1-3.4-5.189,17.235,17.235,0,0,1-1.26-6.675,18.107,18.107,0,0,1,1.122-6.4,16.087,16.087,0,0,1,3.125-5.218,14.6,14.6,0,0,1,4.764-3.489,15.137,15.137,0,0,1,12.227.031,13.6,13.6,0,0,1,4.612,3.55,15.459,15.459,0,0,1,2.851,5.279,21.218,21.218,0,0,1,.971,6.492v.668a5.675,5.675,0,0,1-.06.789H337.927A12.9,12.9,0,0,0,339.141,464.955Zm18.872-7.98a16.082,16.082,0,0,0-.876-4.065,10.839,10.839,0,0,0-1.929-3.4,9.361,9.361,0,0,0-3.016-2.336,9.227,9.227,0,0,0-4.133-.88,8.852,8.852,0,0,0-3.8.819,9.889,9.889,0,0,0-3.076,2.245,11.811,11.811,0,0,0-2.171,3.368,14.319,14.319,0,0,0-1.086,4.247Z" transform="translate(-189.323 -429.852)" fill="#fff" />
                                            <path id="Tracé_216" data-name="Tracé 216" d="M371.754,474.388h-4.673V443.016h4.673v8.193a16.711,16.711,0,0,1,2.123-3.581,14.278,14.278,0,0,1,2.913-2.822,12.4,12.4,0,0,1,3.581-1.79,12.855,12.855,0,0,1,4.187-.546v5.037h-.365a12.876,12.876,0,0,0-4.824.909,10.956,10.956,0,0,0-3.975,2.731,12.944,12.944,0,0,0-2.669,4.521,18.318,18.318,0,0,0-.971,6.22Z" transform="translate(-188.501 -429.849)" fill="#fff" />
                                            <path id="Tracé_217" data-name="Tracé 217" d="M419.842,452.732a12.98,12.98,0,0,1-3.65,4.55,16.245,16.245,0,0,1-5.413,2.761,22.716,22.716,0,0,1-6.631.94h-8.453v13.654h-7.4V432.162h16.748a21.111,21.111,0,0,1,6.7,1,14.262,14.262,0,0,1,5.1,2.883,12.741,12.741,0,0,1,3.217,4.489,14.778,14.778,0,0,1,1.121,5.887A14.165,14.165,0,0,1,419.842,452.732ZM411.1,440.817a10.717,10.717,0,0,0-6.7-1.919h-8.706v15.352H404.4a10.041,10.041,0,0,0,6.728-2.1,6.961,6.961,0,0,0,2.466-5.575A6.809,6.809,0,0,0,411.1,440.817Z" transform="translate(-187.988 -430.098)" fill="url(#linear-gradient)" />
                                            <path id="Tracé_218" data-name="Tracé 218" d="M430.945,447.352a28.3,28.3,0,0,0-4.46,1.669l-1.4-3.822a31.285,31.285,0,0,1,5.28-1.912,24.327,24.327,0,0,1,6.129-.7q6.431,0,9.891,3.185t3.459,9.436v19.175h-4.49v-4.733a15.362,15.362,0,0,1-4.4,3.762,13.443,13.443,0,0,1-6.949,1.639,15.326,15.326,0,0,1-4.277-.607,11.636,11.636,0,0,1-3.762-1.851,9.478,9.478,0,0,1-2.671-3.064,8.811,8.811,0,0,1-1-4.308,9.587,9.587,0,0,1,.971-4.4,8.7,8.7,0,0,1,2.76-3.185,13.254,13.254,0,0,1,4.248-1.942,20.709,20.709,0,0,1,5.431-.668,33.423,33.423,0,0,1,5.279.365,38.345,38.345,0,0,1,4.369.971v-1.093q0-4.246-2.458-6.4a10.108,10.108,0,0,0-6.887-2.154A18.764,18.764,0,0,0,430.945,447.352Zm-1.494,12.895a5.66,5.66,0,0,0-2.359,4.793,5.447,5.447,0,0,0,.635,2.67,6.149,6.149,0,0,0,1.724,1.972,7.853,7.853,0,0,0,2.509,1.244,10.387,10.387,0,0,0,2.994.424,12.788,12.788,0,0,0,4.082-.637,10.851,10.851,0,0,0,3.328-1.76,8.341,8.341,0,0,0,2.237-2.669,7.239,7.239,0,0,0,.816-3.429v-3.034q-1.754-.486-4.022-.911a28.33,28.33,0,0,0-5.17-.425Q431.81,458.487,429.451,460.247Z" transform="translate(-187.165 -429.846)" fill="#fff" />
                                            <path id="Tracé_219" data-name="Tracé 219" d="M470.072,475.407q-2,4.853-4.46,6.857a9.16,9.16,0,0,1-5.977,2,14.556,14.556,0,0,1-3.519-.394,15.565,15.565,0,0,1-3.156-1.183l1.578-3.7a9.977,9.977,0,0,0,2.275.88,11.3,11.3,0,0,0,2.64.272,5.243,5.243,0,0,0,3.58-1.274,12.764,12.764,0,0,0,2.852-4.612L451.625,443h5.157l11.409,26.154L478.263,443h4.976Z" transform="translate(-186.455 -429.836)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_94" data-name="Groupe 94" transform="translate(85.678 423.155)">
                                            <path id="Tracé_220" data-name="Tracé 220" d="M85.678,425.32,120,481.1a20.381,20.381,0,0,0,23.926,8.612l2.582-.88-33.4-55.47a21.075,21.075,0,0,0-26.307-8.52Z" transform="translate(-85.678 -423.155)" fill="url(#linear-gradient)" />
                                            <path id="Tracé_221" data-name="Tracé 221" d="M119.672,425.305l19.846,33.506a21.692,21.692,0,0,0,27.023,8.963h0l-20.59-33.7a21.488,21.488,0,0,0-26.279-8.764Z" transform="translate(-84.855 -423.14)" fill="url(#linear-gradient)" />
                                            <path id="Tracé_222" data-name="Tracé 222" d="M169.04,451.926l-9.851-17.172a4.051,4.051,0,0,1,.346-4.54c3.081-3.881,10.769-10.561,24.235-4.324Z" transform="translate(-83.912 -423.151)" fill="url(#linear-gradient)" />
                                        </g>
                                        <text id="وفير" transform="translate(411.464 494.233)" fill="#fff" font-size="24" font-family="GESSTwoLight-Light, GE SS Two" font-weight="300"><tspan x="0" y="0">وفير</tspan></text>
                                        <path id="Tracé_223" data-name="Tracé 223" d="M397.344,497.674l-9.32.143a1.141,1.141,0,0,1-1.186-1.375c.192-2.507,1.556-7.966,9.222-12.751Z" transform="translate(7.289 1.465)" fill="url(#linear-gradient-5)" />
                                    </g>
                                </svg>


                            </div>
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                            <div className='loader-wait'>S'il vous plaît, attendez</div>
                            <div
                                className="progress"
                                role="progressbar"
                                aria-label="Example with label"
                                aria-valuenow={progress}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            >
                                <div className="progress-bar" style={{ width: progress + '%' }}>
                                { progress + '%' }
                                </div>
                            </div>

                        </div>
                    </div>

                    : ''}



                <section className='pt-0 mt-0'>
                    <div className="container-fluid">
                        <div className='d-flex login-div'>



                            <div className="wafeer-form pt-0">

                                <div className="container text-center">
                                    <div style={{ fontWeight: 500 }} data-aos="fade-up" className="section-sub mt-4">Pour activer votre compte, veuillez télécharger les fichiers suivants</div>
                                    <div data-aos="fade-down" className="wafeer-vertical-separation mt-3" />
                                </div>


                                <div className="col-lg-6">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Carte d'identité </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setCarteidentité(e.target.files[0]) : setCarteidentité('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.Carteidentité !== '' ? errors.Carteidentité : ''}</div>

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Carte CIB ou EDAHABIA </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setCarteCIB(e.target.files[0]) : setCarteCIB('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.CarteCIB !== '' ? errors.CarteCIB : ''}</div>

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Relevé bancaire de six mois </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setReleveBancaire(e.target.files[0]) : setReleveBancaire('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.ReleveBancaire !== '' ? errors.ReleveBancaire : ''}</div>

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Attestation d’affiliation assurance </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setAttestationAffiliation(e.target.files[0]) : setAttestationAffiliation('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.AttestationAffiliation !== '' ? errors.AttestationAffiliation : ''}</div>

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Contrat de travail </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setContratdetravail(e.target.files[0]) : setContratdetravail('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.Contratdetravail !== '' ? errors.Contratdetravail : ''}</div>

                                </div>

                                <div className="col-lg-6 mt-5">
                                    <div className='d-flex'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                            <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                                <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                                <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                            </g>
                                        </svg>
                                        <label htmlFor="inputState" className="form-label">Attestation de travail </label>
                                    </div>
                                    <input type="file" accept="image/png, image/jpg ,image/jpeg ,image/webp ,application/pdf" onChange={(e) => e.target.files.length > 0 ? setAttestationdetravail(e.target.files[0]) : setAttestationdetravail('')} className="form-control" id="inputCity" />
                                    <div className='err-cart-sum'>{Showerrors && errors.Attestationdetravail !== '' ? errors.Attestationdetravail : ''}</div>

                                </div>
                                <div onClick={Register} className="wafeer-login-btn mt-5">
                                    Télécharger
                                </div>
                                {/* <div className="have-already mt-3">
                        <a href="/login" style={{ color: '#4b4b4b' }}>
                            J'ai déjà un compte ? Log in
                        </a>
                    </div> */}
                            </div>


                        </div>
                    </div>
                </section>












            </form>
        </div>
    )
}

export default UploadDocuments