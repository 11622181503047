import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';



const UpdatePaymentFormation = () => {

    const ComuneMount = useRef(true);

    const { id } = useParams();
    // const [BoutiqueData, setBoutiqueData] = useState('')

    const [IdCat, setIdCat] = useState('')
    const [PayemntType, setPayemntType] = useState('')
    const [Mobile, setMobile] = useState('')
    const [Date, setDate] = useState('');
    const [OldRecuCCPIamge, setOldRecuCCPIamge] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/payment_formation/${id}`);
                console.log(response['data']);
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // setBoutiqueData(response['data'])


                setIdCat(response['data']['id'])
                setPayemntType(response['data']['payment_methode'] != null ? response['data']['payment_methode'] : null)
                setMobile(response['data']['mobile'])
                setFormation(response['data']['title'])


                setCatName(response['data']['name'])
                setDate(response['data']['payment_date'] != null ? response['data']['payment_date'] : null)


                setValidé(response['data']['payment_valid'] == 1 ? true : false)


                if (response['data']['recu_ccp'] !== 'undefined' && response['data']['recu_ccp'] !== null) {
                    setPreview1(`${process.env.REACT_APP_LOCAL_URL}/images/ccpformation/${response['data']['recu_ccp']}`)
                    setOldRecuCCPIamge(response['data']['recu_ccp'])
                    setImage1(true)
                    setinitPreview1(true)
                }


            } catch (error) {
                setLoading(false);
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);






    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();







    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);






    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [CatName, setCatName] = useState('')
    const [Formation, setFormation] = useState("")
    const [Validé, setValidé] = useState(false)
    const [Image1, setImage1] = useState('')




    const [preview1, setPreview1] = useState()

    const [initpreview1, setinitPreview1] = useState(false)



    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ CatName: '', Email: '', Adresse: '', Site: '', Facebook: '', Instagram: '', Heurededébut: '', Heuredefin: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (CatName?.toString().trim().length > 300 || CatName?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, CatName: 'Veuillez écrire le nom de la Catégorie !! (Max 300 Caractères)' }))
        } else {
            seterrors((prev) => ({ ...prev, CatName: '' }))
        }



        if (PayemntType == 2) {
            if (Image1?.size > 2097152 || !Image1) {
                seterrors((prev) => ({ ...prev, Image1: "Veuillez joindre votre reçu de paiement CCP Image - (Max 2 Mo)" }))
            } else {
                seterrors((prev) => ({ ...prev, Image1: '' }))
            }

        } else {
            seterrors((prev) => ({ ...prev, Image1: '' }))
        }

        if (Date?.toString().trim().length > 300 || Date == '' || Date == null) {
            seterrors((prev) => ({ ...prev, Date: 'Date incorrect' }))
        } else {
            seterrors((prev) => ({ ...prev, Date: '' }))
        }

    }

    useEffect(() => {

        Validate();

    }, [CatName,Date, Image1,PayemntType])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])


    useEffect(() => {

        if (Image1 == true) {
            return
        }
        if (!Image1) {
            setPreview1(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(Image1)
        setPreview1(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [Image1])


console.log(PayemntType);



    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.CatName !== '' || errors.Image1 !== '' || errors.Date !== ''
        ) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 60);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }



        const formData = new FormData();

        // console.log(PayemntType);
        // return

        formData.append("payment_methode", PayemntType);
        formData.append("payment_date", Date);

        formData.append("active", Validé == true ? 1 : 0);
        formData.append("recu_ccp", Image1 || null);

        setLoading(true)


        axios.put(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/update_payment_formaion/${IdCat}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)

                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Le statut du paiement a été modifié avec succès',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {
                        window.location.reload();
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }





    const DeleteImage = async (type) => {
        // setLoading(true);

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Êtes-vous sûr de vouloir supprimer cette Image ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/categoriedz/delete_payment_formation_ccp/${IdCat}`, { "type": 'img', "idcat": IdCat }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Deleted!',
                            "L'image n'a pas été supprimée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {


                        setPreview1(null)
                        setImage1(null)
                        setinitPreview1(false)
                        setOldRecuCCPIamge('')



                        await Swal.fire(
                            'Deleted!',
                            "L'image a été supprimée avec succès",
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })
        // .then(async (result) => {
        //     if (result.isConfirmed) {
        //         // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);






        //     } 
        // })


        // setLoading(false);
    }



    const paymenttype = [{ id: 0, name: 'CIB' }, { id: 1, name: 'Edahabia' }, { id: 2, name: 'CCP' }, { id: 3, name: 'Espèces' }];


    function ClearInputFile() {

            hiddenFileInput1.current.value = "";
            setImage1(null);

    }




    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Modifier le paiement de formation <span>Iamal</span></div>
                    <div className='page-content'>
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        <form className="row g-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>
                            <div className="col-md-12">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Formation</label>
                                <input type="text" readOnly  value={Formation} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom du client</label>
                                <input type="text" readOnly  value={CatName} className="form-control" id="inputEmail4" />
                                {/* <div className='err-cart-sum'>{Showerrors && errors.CatName !== '' ? errors.CatName : ''}</div> */}
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Mobile</label>
                                <input type="text" readOnly  value={Mobile} className="form-control" id="inputEmail4" />
                                
                            </div>
                            <div className="col-md-6 mb-5">
                                {/* <input type="text" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g , ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" /> */}
                                <label htmlFor="inputEmail4" className="form-label">Type de paiement</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setPayemntType(e.target.value)}>
                                    <option disabled selected={PayemntType == null}>Type de paiement</option>
                                    {paymenttype?.length > 0 && paymenttype?.map((item, i) => (
                                    <option selected={PayemntType == item?.id} value={item?.id}>{item?.name}</option>
                                ))
                                }
                                </select>
                                {/* <div className='alert-form'>{Showerr && (Typeactnaissnce == "" ? (infoperssonnelle['msgerr15'][Lang]) : '')}</div> */}
                            </div>
                            <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputAddress2" className="form-label">Date de Paiement</label>
                                <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} value={Date} id="inputAddress2" placeholder="Apartment, studio, or floor" />
                                <div className='err-cart-sum'>{Showerrors && errors.Date !== '' ? errors.Date : ''}</div>

                            </div>

                            {
                                PayemntType == 2 ?
                            <div className="col-md-12 d-flex justify-content-around flex-wrap">
                                <div className="mb-3">

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                        <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                            <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                            <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                        </g>
                                    </svg>
                                    <label for="inputState" class="form-label">Reçu paiement CCP</label>
                                    {OldRecuCCPIamge ? <a className='vendeur-old-version' target="_blank" href={`${process.env.REACT_APP_LOCAL_URL}/images/ccpformation/${OldRecuCCPIamge}`}> Afficher le reçu de paiement</a> : ''}

                                    <div className='img-up mt-3'>
                                        {Image1 && <div className='up-close' onClick={() => initpreview1 ? DeleteImage('logo') : ClearInputFile()}>X</div>}
                                        <svg onClick={() => { hiddenFileInput1.current.click() }} xmlns="http://www.w3.org/2000/svg" width="200" height="198" viewBox="0 0 200 198">
                                            <text id="_" data-name="+" transform="translate(82 118)" fill="#707070" font-size="52" font-family="Poppins-Regular, Poppins" opacity="0.5"><tspan x="0" y="0">+</tspan></text>
                                            <g id="Rectangle_516" data-name="Rectangle 516" fill="none" stroke="#b2b4b5" stroke-width="4">
                                                <rect width="200" height="198" rx="44" stroke="none" />
                                                <rect x="2" y="2" width="196" height="194" rx="42" fill="none" />
                                            </g>
                                        </svg>
                                        {Image1 &&
                                            <div onClick={() => { hiddenFileInput1.current.click() }} className='img-perview'>
                                                <img className='img-fluid' src={preview1} />

                                            </div>}
                                    </div>
                                    <div className='err-cart-sum mt-3'>{Showerrors && errors.Image1 !== '' ? errors.Image1 : ''}</div>

                                    <input ref={hiddenFileInput1} accept="image/png, image/jpg ,image/jpeg ,image/webp" className="form-control d-none" type="file" onChange={(e) => e.target.files.length > 0 ? setImage1(e.target.files[0]) : setImage1()} id="formFile" />
                                </div>

                            </div> : ''
                            }


                            <div className="col-md-12 text-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>
                                <label htmlFor="inputEmail4" className="form-label">Validé</label>
                                <div>

                                    <label className="switch">
                                        <input type="checkbox" onChange={(e) => setValidé(e.target.checked)} checked={Validé} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                            </div>

                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Modifier</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdatePaymentFormation