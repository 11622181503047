import { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Loader from '../Global/Loader';
import Swal from 'sweetalert2'
import AuthContext from '../../Context/auth';

const FormRegister = () => {
    const [error, seterror] = useState([]);
    const { AuthUser, setAuthUser } = useContext(AuthContext);



    console.log(error);

    useEffect(() => {
        if (AuthUser?.token) {
          ValidateToken()
        }
        // console.log(Auth.token);
    
    
      }, [])
    
    
      const ValidateToken = async () => {
        console.log('run');
    
        setLoading(true)
        try {
          const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/checktoken-user`, {}, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${AuthUser?.token}`
            },
          });
    
          console.log(response);
          // if (response['Valid'] == false) {
          //     return navigate('/')
          // }
    
          if (response['Valid'] == true) {
            // return navigate('/')
    
            // if (response['Role'] == 'adminDZ') {
    
            window.location = '/dashboard'
    
            // }else{
            //   window.location ='/dashboard'
            // }
    
          } else {
    
            setLoading(false)
    
    
          }
    
    
    
    
    
        } catch (error) {
          setLoading(false)
    
          // return navigate('/')
        }
      }


    const [NometPrenom, setNometPrenom] = useState('');
    const [secteuActivité, setsecteuActivité] = useState('');
    const [Mobile, setMobile] = useState('')

    const [Lang, setLang] = useState("ar")
    const [Email, setEmail] = useState('');
    const [ID, setID] = useState('');
    const [Password, setPassword] = useState('')
    const [RePassword, setRePassword] = useState('')

    const [Wilaya, setWilaya] = useState('')

    const [Commune, setCommune] = useState('')
    const [Communesdenaissence, setCommunesdenaissence] = useState([])

    useEffect(() => {

        const GetCommune = async () => {
            try {
                const { data: response } = await axios.get(`https://opr.diardzair.com.dz/ws/region/wilayatocommune?valeur=${Wilaya}`);


                setCommunesdenaissence(response['communes'])
                setCommune('')

            } catch (error) {
                // console.error(error.message);
            }
        }

        if (Wilaya !== '') {
            GetCommune();
        }


    }, [Wilaya])





    function GoNext() {

        setShowerrors(true)

        if (errors.NometPrenom[Lang] !== '' || errors.Mobile[Lang] !== '' || errors.Email[Lang] !== '' || errors.id[Lang] !== '' || errors.Password[Lang] !== '' || errors.rePassword[Lang] !== '' || errors.secteuActivité[Lang] !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
            return;
        }

        const SentForm = async () => {
            setLoading(true)
            try {
                const { data: response } = await axios.post(`${process.env.REACT_APP_LOCAL_URL}/login/register`, { "nom": NometPrenom, "email": Email, "id_demande": ID, 'mobile': Mobile, "password": Password , "secteur" : secteuActivité }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // console.log(response['errorCode']);
                // console.log(response);
                if (response['error'] == false && response['msg']) {
                    // console.log(response.data['err']);
                    setAuthUser({ token: response['token'] })
                    Swal.fire({
                        title: Lang === "fr" ? 'Succès' : 'تم بنجاح',
                        type: 'success',
                        text: Lang === "fr" ? 'Votre compte a été créé avec succès' : 'تم إنشاء حسابك بنجاح ',
                        // timer: 4000,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function (isConfirm) {
                        if (isConfirm) {
                            window.location = "/dashboard";
                        }
                    })


                    // .then(function() {
                    //     window.location = "/";
                    // });


                } else {

                    if (response['errorList'].constructor == Array) {
                        seterror(response['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: Lang === "fr" ? "Il y a un problème lors de l'ajout de données." : "هناك مشكل في إنشاء حسابك يرجى المحاولة مرة أخرى",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });
                }

                setLoading(false)
                // localStorage.removeItem('cart')



            } catch (error) {
                setLoading(false)
                // console.error(error.message);
            }
        }

        SentForm();



    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }


    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Titreduproduit: { "fr": '', "ar": "" }, Réferenceduproduit: { "fr": '', "ar": "" }, Marque: { "fr": '', "ar": "" } })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        if (NometPrenom?.toString().trim().length > 300 || NometPrenom?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": 'Veuillez écrire votre nom et prénom', "ar": "يرجى كتابة الإسم واللقب" } }))
        } else {
            seterrors((prev) => ({ ...prev, NometPrenom: { "fr": '', "ar": "" } }))
        }

        if (Mobile?.toString().trim().length !== 10 || Mobile?.toString().trim() == '' || !onlyNumbers(Mobile) || (Mobile?.slice(0, 2) !== "07" && Mobile?.slice(0, 2) !== "05" && Mobile?.slice(0, 2) !== "06")) {
            seterrors((prev) => ({ ...prev, Mobile: { "fr": 'Vous devez entrer un numéro de téléphone valide (Djezzy Ooredoo Mobilis) (10 chiffres) ', "ar": 'يجب إدخال رقم هاتف صالح (جازي أوريدو موبيليس)' } }))
        } else {
            seterrors((prev) => ({ ...prev, Mobile: { "fr": '', "ar": "" } }))
        }
        if (Email?.toString().trim().length > 300 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
            seterrors((prev) => ({ ...prev, Email: { 'fr': 'Vous devez entrer un email valide.', 'ar': 'يجب عليك إدخال بريد إلكتروني صالح' } }))
        } else {
            seterrors((prev) => ({ ...prev, Email: { "fr": '', "ar": "" } }))
        }
        if (ID?.toString().trim().length > 12 || !onlyNumbers(ID) || ID <= 0) {
            seterrors((prev) => ({ ...prev, id: { 'fr': "S'il vous plait, entrez un ID valide", 'ar': 'يرجى إدخال ID صحيح' } }))
        } else {
            seterrors((prev) => ({ ...prev, id: { "fr": '', "ar": "" } }))
        }


        if (Password?.toString().length > 300 || Password?.toString().length < 6 || Password?.toString() == '') {
            seterrors((prev) => ({ ...prev, Password: { 'fr': 'Mot de passe incorrect (Min 6 lettres ou chiffres)', 'ar': 'كلمة السر ليست قوية - 8 كلمات على الأقل' } }))
        } else {
            seterrors((prev) => ({ ...prev, Password: { "fr": '', "ar": "" } }))
        }

        if (RePassword?.toString().length > 300 || RePassword?.toString().length < 6 || RePassword?.toString() == '' || RePassword?.toString() !== Password?.toString()) {
            seterrors((prev) => ({ ...prev, rePassword: { 'fr': 'Les deux mots de passe doivent être identiques', 'ar': 'كلمات السر يجب أن تكون متطابقة ' } }))
        } else {
            seterrors((prev) => ({ ...prev, rePassword: { "fr": '', "ar": "" } }))
        }
        if (!onlyNumbers(secteuActivité) || (secteuActivité !== "0" && secteuActivité !== "1" ) || secteuActivité?.toString().trim() == '') {
            seterrors((prev) => ({ ...prev, secteuActivité: { "fr": "Merci de préciser le secteur d'activité", "ar": "برجى تحديد قطاع النشاط" } }))
        } else {
            seterrors((prev) => ({ ...prev, secteuActivité: { "fr": '', "ar": "" } }))
        }

        // if (Wilaya?.toString().trim() == '' || Wilaya?.toString().trim().length > 300) {
        //     seterrors((prev) => ({ ...prev, Wilaya: { "fr": 'Veuillez sélectionner La Wilaya ', "ar": "يرجى تحديد الولاية" } }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Wilaya: { "fr": '', "ar": "" } }))
        // }

        // if (Commune?.toString().trim() == '' || Commune?.toString().trim().length > 300) {
        //     seterrors((prev) => ({ ...prev, Commune: { "fr": 'Veuillez sélectionner La Commune', "ar": "يرجى تحديد البلدية" } }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Commune: { "fr": '', "ar": "" } }))
        // }


    }

    useEffect(() => {

        Validate();

    }, [NometPrenom, Email, ID, Mobile, Password, RePassword , secteuActivité])

    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");
            for (let index = 0; index < matches.length; index++) {
                if (matches[index].textContent.trim() !== '') {
                    let position = matches[index].getBoundingClientRect();
                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
            }
        }
    }, [Showerrors])



    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    const Label = {
        "name": { 'ar': "الإسم واللقب", 'fr': "Nom et Prénom" }, "Mobile": { 'ar': "رقم الهاتف", 'fr': "Mobile" }, "Email": { "ar": "الإيميل", "fr": "Email" }
        , "Password": { "ar": "كلمة السر", "fr": "Mot de passe" }, "Repassword": { "ar": "إعادة كلمة السر", "fr": "Répéter le mot de passe" },'secteur': { 'fr': 'Secteur d’activité', 'ar': 'قطاع النشاط' },
    }

    const changeLang = (lng) => {
        setLang(lng);
    }

    const secteuActivitéList = [
        { 'fr': 'Salarié ', 'ar': 'موظف', 'value': "0" } ,
         { 'fr': 'Retraité ', 'ar': 'متقاعد', 'value': "1" } ,
        // { "ar": { "value": "أثاث", 'text': "أثاث" }, "fr": { "value": "Meubles", 'text': "Meubles" } },
        // { "ar": { "value": "أجهزة كهرومنزلية وأثاث", 'text': "أجهزة كهرومنزلية وأثاث" }, "fr": { "value": "Électroménager et Meubles", 'text': "Électroménager et Meubles" } },
        // { "ar": { "value": "أخرى", 'text': "أخرى" }, "fr": { "value": "Autre", 'text': "Autre" } },
    ];
    return (
        <div className="create-account Aide-page pt-5 pb-5 form-store-register" lang={Lang === 'ar' ? "ar" : 'fr'}>
            <a href='login' className="text-center m-auto login-register mt-4 mb-4">
                    J'ai un compte ?
                    </a>
            <div className="text-center mt-4 mb-5">
                <img alt="logodiardzair" src="/img/logo-fr.svg" width={186} height="59.911" />
            </div>
            <div className="ms-4 lang-section mb-4 text-center">
                <div className="sl-nav">
                    <ul>
                        <li>
                            <b className="lang-menu" style={{ color: 'black' }}>{Lang === "fr" ? 'Francais' : 'العربية'}</b>
                            {/* <b><img src={'/img/' + Lang + '.jpg'} className='lang-menu' alt='' /></b>  */}

                            <i className="fa fa-angle-down" style={{ color: 'black' }} aria-hidden="true" />
                            <div className="triangle" ></div>
                            <ul>
                                <div className='pad-lang'></div>
                                <li onClick={() => changeLang('fr')}><i className="sl-flag flag-fr" ><div id="French" /></i> <span className={Lang === "fr" ? "active lang-text" : 'lang-text'}> {Lang === "fr" ? 'Français' : 'الفرنسية'} </span></li>
                                <li onClick={() => changeLang('ar')}><i className="sl-flag flag-usa"><div id="English" /></i> <span className={Lang === "ar" ? "active lang-text" : 'lang-text'}> {Lang === "ar" ? 'العربية' : 'Arabe'}  </span></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='pb-hadi'></div>
                
            </div>

            {Loading &&
                <Loader />
            }
            {/* <h6 className="text-center mb-5 mt-4">
    Veuillez remplir le formulaire pour vous inscrire au service (Store)
    </h6> */}
            <div className='container py-3'>
                {error?.length > 0 && error?.map((item, i) => (

                    item.constructor == String ?
                        <div className="alert-store" role="alert">
                            - {item}
                        </div>
                        : ''

                ))
                }

            </div>
            <div className={`container  mt-2 d-flex `} lang={Lang === 'ar' ? "ar" : 'fr'} >
                <div className='form-store'>

                    <form className="row g-5"
                        dir={Lang === 'ar' ? 'rtl' : 'ltr'}
                    >

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.name[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="text" onChange={(e) => setNometPrenom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={NometPrenom} className="form-control" id="inputEmail4" />
                            <span className='note-form'>
                            { Lang == 'ar' ?  "  - الكتابة باللغة الفرنسية"   : "- Écrire en français"}
                                </span>
                            <div className='err-cart-sum'>{Showerrors && errors.NometPrenom[Lang] !== '' ? errors.NometPrenom[Lang] : ''}</div>
                        </div>



                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">{Label?.Mobile[Lang]}</label>
                            <input dir="ltr" type="tel" maxLength={10} onChange={(e) => setMobile(e.target.value.replace(/[^0-9]+/g, ''))} value={Mobile} className="form-control" id="inputAddress2" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.Mobile[Lang] !== '' ? errors.Mobile[Lang] : ''}</div>
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputCity" className="form-label">{Label?.Email[Lang]}</label>
                            <input dir="ltr" maxLength={300} type="email" onChange={(e) => setEmail(e.target.value.replace(/[^a-zA-Z@.0-9]+/g, ''))} value={Email} className="form-control" id="inputCity" />
                            <div className='err-cart-sum'>{Showerrors && errors.Email[Lang] !== '' ? errors.Email[Lang] : ''}</div>
                        </div>
                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress2" className="form-label">Id Demande</label>
                            <input dir="ltr" type="tel" maxLength={12} onChange={(e) => setID(e.target.value.replace(/[^0-9]+/g, ''))} value={ID} className="form-control" id="inputAddress2" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.id[Lang] !== '' ? errors.id[Lang] : ''}</div>
                        </div>

                        <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.secteur?.[Lang] }</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setsecteuActivité(e.target.value)}>
                                <option disabled selected={secteuActivité == "" ? "selected" : ''} value={''} >{Label?.secteur?.[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.secteur?.[Lang]}</label>
                                {secteuActivitéList.length > 0 && secteuActivitéList.map((item, i) => (

                                    <option value={item?.value}>{item?.[Lang]}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>

                        </div>

                        <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Password[Lang]}</label>
                            <input dir="ltr" type="password" maxLength={300} onChange={(e) => setPassword(e.target.value)} value={Password} className="form-control" id="inputPassword4" />
                            <div className='err-cart-sum'>{Showerrors && errors.Password[Lang] !== '' ? errors.Password[Lang] : ''}</div>

                        </div>

                        <div className="col-md-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.Repassword[Lang]}</label>
                            <input dir="ltr" type="password" maxLength={300} onChange={(e) => setRePassword(e.target.value)} value={RePassword} className="form-control" id="inputRePassword4" />
                            <div className='err-cart-sum'>{Showerrors && errors.rePassword[Lang] !== '' ? errors.rePassword[Lang] : ''}</div>

                        </div>
                        {/* <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.wilaya[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setWilaya(e.target.value)}>
                                <option disabled selected={Wilaya == "" ? "selected" : ''} value={''} >{Label?.wilaya[Lang]}</option>
                                <label htmlFor="inputState" className="form-label">{Label?.wilaya[Lang]}</label>
                                {WilayaList.length > 0 && WilayaList.map((item, i) => (

                                    <option value={item.value}>{item.text}</option>

                                ))
                                }

                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Wilaya[Lang] !== '' ? errors.Wilaya[Lang] : ''}</div>

                        </div> */}
                        {/* <div className="col-lg-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputEmail4" className="form-label">{Label?.commune[Lang]}</label>
                            <select class="form-select" aria-label="Default select example" onChange={(e) => setCommune(e.target.value)}>
                                <option disabled selected={Commune == "" ? "selected" : ''} value={''}>{Communesdenaissence.length > 0 ? Lang === "fr" ? "Commune" : "البلدية" : Lang === "fr" ? "Veuillez sélectionner La Wilaya" : "يرجى تحديد الولاية لإختيار البلدية"}</option>


                                {Communesdenaissence.length > 0 && Communesdenaissence.map((item, i) => (
                                    <option selected={Commune === item?.commune} value={item?.commune}>{item?.commune}</option>
                                ))
                                }
                            </select>
                            <div className='err-cart-sum'>{Showerrors && errors.Commune[Lang] !== '' ? errors.Commune[Lang] : ''}</div>

                        </div> */}

                        
                      

                        {/* <div className="col-lg-12">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className={Lang === 'ar' ? "ms-2" : 'me-2'} viewBox="0 0 24 24">
                                <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                    <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                    <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                </g>
                            </svg>
                            <label htmlFor="inputAddress" className="form-label">{Label?.secteur[Lang]}</label>
                            <input maxLength={300} type="text" className="form-control" onChange={(e) => setsecteuActivité(e.target.value)} value={secteuActivité} id="inputAddress" placeholder="" />
                            <div className='err-cart-sum'>{Showerrors && errors.secteuActivité[Lang] !== '' ? errors.secteuActivité[Lang] : ''}</div>
                        </div> */}
                        <div className="col-12 mt-5">
                            <div onClick={() => GoNext()} className="store-form-register--btn">
                                {Lang === 'fr' ? "Inscription" : "إرسال الطلب"}
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    )
}



export default FormRegister