import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import LoadData from '../../components/Global/LoadData';
import GoBack from '../../components/Global/GoBack';

const ClientActions = () => {

    const { Auth, setAuth ,FindActionPerId} = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';
    const type = searchParams.get('type') ? searchParams.get('type') : '0';


    let { id } = useParams();




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/client_actions/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                setLoading(false);

                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

   

console.log(ListVentes);


// const Actions = ['En attente', 'Confirmé','Tentative échouée', 'Ramassage', 'Sortie ramassage', 'Sortie de livraison', 'Livré'];

// const Actions = [
//     { id: 0, name: 'En attente' ,service : "Niveau 1 (centre d'appel)"},
//     { id: 13, name: "Intéressé par formation" ,service : "Niveau 1 (centre d'appel)"},
// { id: 3, name: "Client informé en attente paiement 1000" ,service : "Niveau 1 (centre d'appel)"},
// { id: 2, name: "Tentative échouée N01" ,service : "Niveau 1 (centre d'appel)"},
// { id: 4, name: "Paiement CCP effectuè" ,service : "Niveau 1 (centre d'appel)"},
// { id: 5, name: "Paiement en espèce effectuè" ,service : "Niveau 1 (centre d'appel)"},
// { id: 27, name: "Pas intéressé" , service : "Niveau 1 (centre d'appel)"},
// { id: 28, name: "Annulation" , service : "Niveau 1 (centre d'appel)"},
// { id: 29, name: "Client informer N01" , service : "Niveau 1 (centre d'appel)"},
// { id: 30, name: "Faux numéro" , service : "Niveau 1 (centre d'appel)"},
// { id: 31, name: "Test" , service : "Niveau 1 (centre d'appel)"},



// { id: 6, name: "Confirmé en attente paiement 5000" , service : "Niveau 2 (relation client)"},
// { id: 7, name: "Confirmè WhatsApp" , service : "Niveau 2 (relation client)"},
// { id: 8, name: "Confirmè dossier incomplet" , service : "Niveau 2 (relation client)"},
// { id: 9, name: "Demande ramassage" , service : "Niveau 2 (relation client)"},
// { id: 10, name: "Demande ramassage WhatsApp" , service : "Niveau 2 (relation client)"},
// { id: 11, name: "Tentative échouée N02" , service : "Niveau 2 (relation client)"},
// { id: 32, name: "Faux numéro" , service : "Niveau 2 (relation client)"},
// { id: 33, name: "Test" , service : "Niveau 2 (relation client)"},
// { id: 12, name: "Annulation" , service : "Niveau 2 (relation client)"},

// { id: 1, name: "Accusé niveau 03" , service : "Niveau 3 (relation banque)"},
// { id: 14, name: "Dossier complet" , service : "Niveau 3 (relation banque)"},
// { id: 15, name: "Dossier incomplet" , service : "Niveau 3 (relation banque)"},
// { id: 16, name: "Dossier en cours de traitement" , service : "Niveau 3 (relation banque)"},
// { id: 17, name: "Dossier physique traité" , service : "Niveau 3 (relation banque)"},


// { id: 18, name: "Panier en préparation" , service : "Commercial"},
// { id: 19, name: "Prêt pour expédition" , service : "Commercial"},


// { id: 20, name: "Sortie ramassage dossier" ,service : "Ramassage"},
// { id: 21, name: "Tentative échouée N03" ,service : "Ramassage"},
// { id: 22, name: "Ramassage dossier reçu" ,service : "Ramassage"},
// { id: 23, name: "Sortie ramassage pick up" ,service : "Ramassage"},
// { id: 24, name: "Ramassage pick up reçu" ,service : "Ramassage"},
// { id: 25, name: "Dossier reçu incomplet" ,service : "Ramassage"},

// { id: 26, name: "Programmé pour livraison" ,service : "Livraison"}
// ];

// function FindActionPerId(action) {

//     let action_name =  Actions?.find(o => o.id == action)

//     if (action_name) {
//         return action_name?.name;
      
//     }else{
//       return 'En attente'
//     }

      
//   }

function onlyNumbers(str) {
    return /^[0-9]+$/.test(str);
}



    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'> Client Actions<span>Iamal</span></div>
                    <GoBack  url={"/listvendeuradmin"} />

                    <div className='new-admin-btn mb-0' onClick={() => navigate('/addaction/'+id)}> + Nouvelle action  </div>
                    {Loading ? <LoadData /> : ''}

                    {!Loading && ListVentes?.length != 0  ?

                        <div className='action-page'>
                            {/* <div className="conatiner mb-5">
                                <ul className="list-group list-store-admin">
                                    <li className={`list-group-item active gradiant`} aria-current="true">Information Generale</li>
                                    {ListVentes?.name ? <li className="list-group-item"> <b>Nom</b> : {ListVentes?.name}</li> : ''}
                                    {ListVentes?.email ? <li className="list-group-item"><b> Email</b> : {ListVentes?.email}</li> : ''}
                                    {ListVentes?.mobile ? <li className="list-group-item"><b> Mobile </b> : {ListVentes?.mobile}</li> : ''}
                                    <li className="list-group-item"><b>Mobile Validé </b> : {ListVentes?.mobile_valid == 1 ? 'Oui' : "Non"}</li>
                                    <li className="list-group-item"><b>Abonnement -  Payment Validé </b> : {ListVentes?.payment_valid == 1 ? 'Oui' : "Non"}</li>
                                  
                                                                 </ul>
                            </div> */}
                            <main className='mt-2'>
                    <ol className="gradient-list">
                    
                    {!Loading && ListVentes?.length > 0 && ListVentes?.map((item,i) => (
                        <>
                        <li className="i3mal-pages-li" key={`OurVision2${item['id']}`}>
                            <h6 className='mb-4'><b>Date :</b> {item?.date}</h6>
                            <div className='mb-2'><b>Agent :</b> {item?.agent}</div>

                            <div className='mb-2'><b>Status :</b> {onlyNumbers(item?.Status) ? FindActionPerId(item?.Status) : ''}</div>
                            <div><b>Déscription : </b>{item?.description ? item?.description :  <b>(Pas mentionné)</b>}</div>
                            
                            
                            </li>
                           {ListVentes?.length != i+1 ? <div data-aos="fade-down" className="wafeer-vertical-separation mt-3" /> : ''} </>
                        ))
                    }
                    </ol>
                </main>
                
                        </div>
                        : !Loading ? <div className='container mt-5 pt-5 text-center'>
                          <h5> Aucune action ajoutée</h5>
                        </div> : ''
                        
                        }

                </div>
            </div>


        </div>
    )
}

export default ClientActions