import React, { createContext, useState, useEffect } from 'react'

const AuthContext = createContext();

export function AuthProvider({ children }) {

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  const getToken = localStorage.getItem('Token') !== '' && localStorage.getItem('Token') !== null && isJsonString(localStorage.getItem('Token')) ? JSON.parse(localStorage.getItem('Token')) : false;
  const getTokenUser = localStorage.getItem('TokenUser') !== '' && localStorage.getItem('TokenUser') !== null && isJsonString(localStorage.getItem('TokenUser')) ? JSON.parse(localStorage.getItem('TokenUser')) : false;


  const [Auth, setAuth] = useState(getToken ? getToken : false);
  const [AuthUser, setAuthUser] = useState(getTokenUser ? getTokenUser : false);

  // console.log(Auth);

  useEffect(() => {
    localStorage.setItem('Token', JSON.stringify(Auth));
  }, [Auth]);

  useEffect(() => {
    localStorage.setItem('TokenUser', JSON.stringify(AuthUser));
  }, [AuthUser]);



  const Actions = [
    { id: 0, name: 'En attente' ,service : "(centre d'appel)"},
    { id: 1, name: "Confirmer" ,service : "(centre d'appel)"},
    { id: 2, name: "Rappel" ,service : "(centre d'appel)"},
    { id: 3, name: "Rappel échoué" ,service : "(centre d'appel)"},

    
    { id: 4, name: "Annuler raison personnelle" ,service : "(centre d'appel)"},
    { id: 5, name: "Annuler crédit" ,service : "(centre d'appel)"},
    { id: 6, name: "Annuler cdd " ,service : "(centre d'appel)"},
    { id: 7, name: "Annuler deuxième commande " ,service : "(centre d'appel)"},
    { id: 8, name: "Annuler commerçant" ,service : "(centre d'appel)"},
    { id: 9, name: "Tentative échouée 1" ,service : "(centre d'appel)"},
    { id: 10, name: "Tentative échouée 2" ,service : "(centre d'appel)"},
    { id: 11, name: "Tentative échouée 3" ,service : "(centre d'appel)"},
    { id: 12, name: "Client a rappelé" ,service : "(centre d'appel)"},
    { id: 13, name: "Test" ,service : "(centre d'appel)"},

    { id: 14, name: "Faux numéro" ,service : "(centre d'appel)"},
    { id: 15, name: "Annulé capacité  " ,service : "(centre d'appel)"},





];

const ActionsFiltred = Actions?.reduce((acc, option) => {
  if (!acc[option?.service]) {
    acc[option?.service] = [];
  }
  acc[option?.service].push(option);
  return acc;
}, {});

function FindActionPerId(action) {

  let action_name = Actions?.find(o => o.id == action)

  if (action_name) {
      return action_name?.name;

  } else {
      return 'En attente'
  }


}









  return <AuthContext.Provider value={{ Auth, setAuth ,AuthUser, setAuthUser, Actions,ActionsFiltred,FindActionPerId}}>{children}</AuthContext.Provider>

}


export default AuthContext