import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import LoadData from '../../components/Global/LoadData';

const SocialMediaRequests = () => {

    const { Auth, setAuth } = useContext(AuthContext);

    const navigate = useNavigate();


    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    const [Word, setWord] = useState(searchParams.get('nom') ? searchParams.get('nom') : '')
    const [Tel, setTel] = useState(searchParams.get('tel') ? searchParams.get('tel') : '')
    const [Validvendeur, setValidvendeur] = useState(searchParams.get('validvendeur') ? searchParams.get('validvendeur') : 'vide')
    const [Wilaya, setWilaya] = useState(searchParams.get('wilaya') ? searchParams.get('wilaya') : 'all')



    const [UrlPagination, setUrlPagination] = useState('')




    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);

            const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            // const valid_vendeur = Validvendeur == 1 || Validvendeur == 0 ? '&validvendeur=' + Validvendeur : '';
            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';


            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/social-media?page=${page}${keyword}${tel_vendeur}${wilaya_boutique}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    useEffect(() => {


        // if (Auth) {
        //   console.log('hi!');

        // }else{
        //   console.log('no!'); 
        //   return navigate("/");
        // }


    }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    async function removeVendeur(id) {

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Voulez-vous vraiment supprimer cette demande ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/delete_social-media/${id}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {

                        const updatefListVentes = ListVentes.filter(item => item.id !== id);
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            'Supprimée!',
                            'Cette demande a été supprimé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })

    }

    async function validVendeur(id, etat) {


        Swal.fire({
            title: 'Êtes-vous sûr',
            text: etat == 1 ? "Êtes-vous sûr d'activer ce vendeur ?" : "Êtes-vous sûr de désactiver ce vendeur ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            showLoaderOnConfirm: true,
            // reverseButtons: true

            preConfirm: async () => {
                try {
                    const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/vendeur/valid_vendeur/${id}/${etat}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${Auth?.token}`
                        },
                    });
                    console.log(response);
                    if (response['message'] == 'Token invalid') {
                        setAuth(false);
                        return navigate('/account')
                    }

                    if (response['error'] == true) {
                        await Swal.fire(
                            'Error!',
                            "L'opération ne s'est pas terminée avec succès",
                            'error'
                        );

                    }

                    if (response['error'] == false) {
                        const updatefListVentes = ListVentes.map(obj =>
                            obj.id_vendeur == id ? { ...obj, active: etat } : obj
                        );
                        setListVentes(updatefListVentes);

                        await Swal.fire(
                            etat == 1 ? 'Activé!' : 'Désactiver!',
                            etat == 1 ? 'Ce vendeur a été activé' : 'Ce vendeur a été désactivé',
                            'success'
                        );


                    }





                } catch (error) {
                    console.error(error.message);
                }
            },
        })
    }


    useEffect(() => {


        const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
        const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
        const valid_vendeur = Validvendeur == 1 || Validvendeur == 0 ? '&validvendeur=' + Validvendeur : '';
        const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';

        let url = keyword + tel_vendeur + valid_vendeur + wilaya_boutique

        setUrlPagination(url)



    }, [Word, Tel, Validvendeur, Wilaya])

    function SearchQUERY() {
        const fetchData = async () => {
            setLoading(true);

            const keyword = Word.trim() !== '' ? '&nom=' + Word : '';
            const tel_vendeur = Tel.trim() !== '' ? '&tel=' + Tel : '';
            const valid_vendeur = Validvendeur == 1 || Validvendeur == 0 ? '&validvendeur=' + Validvendeur : '';
            const wilaya_boutique = Wilaya?.toString().trim() !== '' && Wilaya?.toString().trim() !== 'all' ? '&wilaya=' + Wilaya : '';



            setSearchParams({ page: 1, ...(Word.trim() !== '' && { nom: Word }), ...(Tel.trim() !== '' && { tel: Tel }), ...((Validvendeur == 1 || Validvendeur == 0) && { validvendeur: Validvendeur }), ...((Wilaya.trim() !== '' && Wilaya.trim() !== 'all') && { wilaya: Wilaya }) })
            // setSearchParams({page : 1,nomdeboutique: NomBoutique.trim() !== '' ?  NomBoutique :  ''})

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/reclamations/social-media?page=${1}${keyword}${tel_vendeur}${valid_vendeur}${wilaya_boutique}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            SearchQUERY();
        }
    };


    const WilayaList = [{ "value": "ADRAR", 'text': "(01) ADRAR" },
    { "value": "CHLEF", 'text': "(02) CHLEF" },
    { "value": "LAGHOUAT", 'text': "(03) LAGHOUAT" },
    { "value": "OUM EL BOUAGHI", 'text': "(04) OUM-EL-BOUAGHI" },
    { "value": "BATNA", 'text': "(05) BATNA" },
    { "value": "BEJAIA", 'text': "(06) BEJAIA" },
    { "value": "BISKRA", 'text': "(07) BISKRA" },
    { "value": "BECHAR", 'text': "(08) BECHAR" },
    { "value": "BLIDA", 'text': "(09) BLIDA" },
    { "value": "BOUIRA", 'text': "(10) BOUIRA" },
    { "value": "TAMANRASSET", 'text': "(11) TAMANRASSET" },
    { "value": "TEBESSA", 'text': "(12) TEBESSA" },
    { "value": "TLEMCEN", 'text': "(13) TLEMCEN" },
    { "value": "TIARET", 'text': "(14) TIARET" },
    { "value": "TIZI OUZOU", 'text': "(15) TIZI-OUZOU" },
    { "value": "ALGER", 'text': "(16) ALGER" },
    { "value": "DJELFA", 'text': "(17) DJELFA" },
    { "value": "JIJEL", 'text': "(18) JIJEL" },
    { "value": "SETIF", 'text': "(19) SETIF" },
    { "value": "SAIDA", 'text': "(20) SAIDA" },
    { "value": "SKIKDA", 'text': "(21) SKIKDA" },
    { "value": "SIDI BEL ABBES", 'text': "(22) SIDI-BEL-ABBES" },
    { "value": "ANNABA", 'text': "(23) ANNABA" },
    { "value": "GUELMA", 'text': "(24) GUELMA" },
    { "value": "CONSTANTINE", 'text': "(25) CONSTANTINE" },
    { "value": "MEDEA", 'text': "(26) MEDEA" },
    { "value": "MOSTAGANEM", 'text': "(27) MOSTAGANEM" },
    { "value": "M SILA", 'text': "(28) M-SILA" },
    { "value": "MASCARA", 'text': "(29) MASCARA" },
    { "value": "OUARGLA", 'text': "(30) OUARGLA" },
    { "value": "ORAN", 'text': "(31) ORAN" },
    { "value": "EL BAYADH", 'text': "(32) EL-BAYADH" },
    { "value": "ILLIZI", 'text': "(33) ILLIZI" },
    { "value": "BORDJ BOU ARRERIDJ", 'text': "(34) BORDJ-BOU-ARRERIDJ" },
    { "value": "BOUMERDES", 'text': "(35) BOUMERDES" },
    { "value": "EL TARF", 'text': "(36) EL-TARF" },
    { "value": "TINDOUF", 'text': "(37) TINDOUF" },
    { "value": "TISSEMSILT", 'text': "(38) TISSEMSILT" },
    { "value": "EL OUED", 'text': "(39) EL-OUED" },
    { "value": "KHENCHELA", 'text': "(40) KHENCHELA" },
    { "value": "SOUK AHRAS", 'text': "(41) SOUK-AHRAS" },
    { "value": "TIPAZA", 'text': "(42) TIPAZA" },
    { "value": "MILA", 'text': "(43) MILA" },
    { "value": "AIN DEFLA", 'text': "(44) AIN-DEFLA" },
    { "value": "NAAMA", 'text': "(45) NAAMA" },
    { "value": "AIN TEMOUCHENT", 'text': "(46) AIN-TEMOUCHENT" },
    { "value": "GHARDAIA", 'text': "(47) GHARDAIA" },
    { "value": "RELIZANE", 'text': "(48) RELIZANE" },];


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Demandes de gestion de pages<span>Iamal</span></div>

                    <div className='store-search-section'>
                        <div>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Nom"} onChange={(e) => setWord(e.target.value)} value={Word} className="form-control me-3" id="inputEmail4" />
                        </div>
                        <div>
                            <input onKeyDown={handleKeyDown} type="tel" placeholder={"Mobile"} onChange={(e) => setTel(e.target.value)} value={Tel} className="form-control me-3" id="inputEmail4" />
                        </div>
                        {/* <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setValidvendeur(e.target.value)}>
                                <option className='custum-opt' selected={"selected"} disabled value="vide">Validité</option>
                                <option selected={Validvendeur == 'all'} value={'all'}>Tous</option>
                                <option selected={Validvendeur == 1} value={1}>Validé</option>
                                <option selected={Validvendeur == 0} value={0}>Non Validé</option>
                            </select>
                        </div> */}
                        {/* <div>
                            <select id="inputState" class="form-select me-3" onChange={(e) => setWilaya(e.target.value)}>
                                <option value={'all'} selected={Wilaya == "all" ? "selected" : ''}>Tous Les Wilayas</option>
                                {WilayaList?.length > 0 && WilayaList?.map((item, i) => (
                                    <option selected={Wilaya == item?.value} value={item?.value}>{item?.text}</option>
                                ))
                                }
                            </select>
                        </div> */}
                        <div className='search-btn-dashboard' onClick={SearchQUERY}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1.5 1.5)">
                                    <path id="Tracé_1" data-name="Tracé 1" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Tracé_2" data-name="Tracé 2" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>


                        </div>

                    </div>


                    <div className='dashboard-table'>
                        <table>

                            <thead>
                                <tr>
                                    {/* <th scope="col">Produits</th> */}
                                    <th scope="col">Nom</th>
                                    {/* <th className='d-xl-block d-none' scope="col">email</th> */}
                                    <th scope="col">Email</th>
                                    <th scope="col">Mobile</th>
                                    {/* <th scope="col">Valid</th> */}
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            {!Loading && <tbody>

                                {ListVentes?.length > 0 && ListVentes?.map((item, i) => (

                                    <tr>
                                        {/* <td data-label="Produits"><img src={`${process.env.REACT_APP_LOCAL_URL}/images/${item?.image1}`} /><div>{item?.titre_produit}</div></td> */}
                                        <td data-label="Etat">{item?.name}</td>
                                        {/* <td className='d-xl-block d-none' data-label="email">{item?.email_parrain}</td> */}
                                        <td data-label="Email">{item?.email}</td>
                                        <td data-label="Mobile">{item?.mobile}</td>
                                        {/* <td data-label="Valid">
                                            <a onClick={() => validVendeur(item?.id_vendeur, item?.active == 1 ? 0 : 1)} >
                                               
                                                <div>
                                                    <label className="switch">
                                                        <input type="checkbox" onChange={(e) => console.log('')} checked={item?.active == 1 ? true : false} />
                                                        <span className="slider round"><p className={item?.active == 1 ? 'checked-p' : 'unchecked-p'}>{item?.active == 1 ? 'Oui' : 'Non'}</p> </span>
                                                    </label>
                                                </div>
                                              
                                            </a>


                                        </td> */}
                                        <td className="vendeursadminlist" data-label=" ">
                                            {
                                                item?.iduser != null ?
                                                    <a href={`/updateadmin/${item?.iduser}?role=user`}> <div className='account-store-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="27.83" height="20.59" viewBox="0 0 27.83 20.59">
                                                        <g id="account-management-svgrepo-com" transform="translate(-8 -179.2)">
                                                            <path id="Tracé_9274" data-name="Tracé 9274" d="M10.834,199.79A2.893,2.893,0,0,1,8,196.845v-14.7a2.893,2.893,0,0,1,2.834-2.945H33a2.893,2.893,0,0,1,2.834,2.945v14.679A2.893,2.893,0,0,1,33,199.768H10.834Zm0-19.416a1.735,1.735,0,0,0-1.7,1.771v14.679a1.749,1.749,0,0,0,1.7,1.771H33a1.735,1.735,0,0,0,1.7-1.771V182.145a1.749,1.749,0,0,0-1.7-1.771Z" transform="translate(0)" fill="#fff" />
                                                            <path id="Tracé_9275" data-name="Tracé 9275" d="M48.139,339.105a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.3a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.52a.665.665,0,0,1,0-1.328H55.8a.665.665,0,0,1,0,1.328Zm-13.372,6.863a.613.613,0,0,1-.2-.022.711.711,0,0,1-.443-.886,7.846,7.846,0,0,1,4.1-4.871l.443-.221-.376-.332a5.09,5.09,0,0,1-1.749-3.852,4.808,4.808,0,1,1,9.609,0,5.09,5.09,0,0,1-1.749,3.852l-.376.332.443.221a9.176,9.176,0,0,1,4.362,5.38c.443,1.461-.465,1-.044.443-.089.133-.465-.155-.886-.155-.354,0-.531.686-.531.62-.863-2.878-3.343-5.624-6.022-5.624a6.292,6.292,0,0,0-5.978,4.605.6.6,0,0,1-.6.509Zm6.62-13.793a3.611,3.611,0,1,0,3.5,3.609A3.559,3.559,0,0,0,41.387,338.995Z" transform="translate(-25.372 -154.016)" fill="#fff" />
                                                        </g>
                                                    </svg>
                                                    </div></a>
                                                    :
                                                    // <a href={`/linkadminvendeur/${item?.id_vendeur}`}> <div style={{ background: "linear-gradient(45deg, #e252ea, #3b6ab7)" }} className='account-store-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="27.83" height="20.59" viewBox="0 0 27.83 20.59">
                                                    //     <g id="account-management-svgrepo-com" transform="translate(-8 -179.2)">
                                                    //         <path id="Tracé_9274" data-name="Tracé 9274" d="M10.834,199.79A2.893,2.893,0,0,1,8,196.845v-14.7a2.893,2.893,0,0,1,2.834-2.945H33a2.893,2.893,0,0,1,2.834,2.945v14.679A2.893,2.893,0,0,1,33,199.768H10.834Zm0-19.416a1.735,1.735,0,0,0-1.7,1.771v14.679a1.749,1.749,0,0,0,1.7,1.771H33a1.735,1.735,0,0,0,1.7-1.771V182.145a1.749,1.749,0,0,0-1.7-1.771Z" transform="translate(0)" fill="#fff" />
                                                    //         <path id="Tracé_9275" data-name="Tracé 9275" d="M48.139,339.105a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.3a.665.665,0,0,1,0-1.328h9.077a.665.665,0,0,1,0,1.328Zm0,3.52a.665.665,0,0,1,0-1.328H55.8a.665.665,0,0,1,0,1.328Zm-13.372,6.863a.613.613,0,0,1-.2-.022.711.711,0,0,1-.443-.886,7.846,7.846,0,0,1,4.1-4.871l.443-.221-.376-.332a5.09,5.09,0,0,1-1.749-3.852,4.808,4.808,0,1,1,9.609,0,5.09,5.09,0,0,1-1.749,3.852l-.376.332.443.221a9.176,9.176,0,0,1,4.362,5.38c.443,1.461-.465,1-.044.443-.089.133-.465-.155-.886-.155-.354,0-.531.686-.531.62-.863-2.878-3.343-5.624-6.022-5.624a6.292,6.292,0,0,0-5.978,4.605.6.6,0,0,1-.6.509Zm6.62-13.793a3.611,3.611,0,1,0,3.5,3.609A3.559,3.559,0,0,0,41.387,338.995Z" transform="translate(-25.372 -154.016)" fill="#fff" />
                                                    //     </g>
                                                    // </svg>
                                                    // </div></a>
                                                    ''

                                            }
                                            <a href={`/social-media-requests/${item?.id}`}> <div className='voir-btn my-1  mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                                <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                                    <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                                        <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                                        <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                                    </g>
                                                    <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                                        <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </svg>
                                            </div></a>


                                            {/* <a href={`/updatevendeur/${item?.id_vendeur}`}> <div className='update-btn mx-1'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                <g id="edit-246" transform="translate(-134.2 -129)">
                                                    <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                    <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                </g>
                                            </svg>
                                            </div></a> */}

                                            <a onClick={() => removeVendeur(item?.id)} > <div className='delete-btn my-1 '><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div></a></td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>
                        {Loading &&
                            <LoadData />
                        }

                        {
                            ListVentes?.length === 0 || !ListVentes && !Loading &&
                            <div className='no-results'>Il n'y a pas de données</div>

                        }

                    </div>
                    <div>
                        {PageNumber > 1 &&
                            <div class="text-center mt-5 pt-1 mb-5">
                                <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                                    {CurrentPage > 1 ? <a href={`?page=1${UrlPagination}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                                    {CurrentPage !== 1 ? <a href={`?page=${parseInt(CurrentPage) - 1}${UrlPagination}`}>&laquo;</a> : <a>&laquo;</a>}



                                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                        <>
                                            {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                                <a href={`?page=${item + 1}${UrlPagination}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                                : ''}
                                        </>

                                    ))}



                                    {CurrentPage < PageNumber ? <a href={`?page=${parseInt(CurrentPage) + 1}${UrlPagination}`}>&raquo;</a> : <a>&raquo;</a>}

                                    {CurrentPage < PageNumber ? <a href={`?page=${PageNumber}${UrlPagination}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default SocialMediaRequests