import { useState, useEffect, useContext, useRef } from 'react'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from '../../components/Global/Loader';
import GoBack from '../../components/Global/GoBack';



const AddAction = () => {

    const { id } = useParams();
    const [ActionInfo, setActionInfo] = useState('')


    useEffect(() => {

        const fetchData = async () => {
            // setLoading(true);
            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/client_last_actions/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                setActionInfo(response?.['data'])
                setMobile(response?.['data']?.mobile)
                if (response?.['data']?.Status_User && onlyNumbers(response?.['data']?.Status_User)) {
                    
                    setStatus(response?.['data']?.Status_User)
                    
                }
                // setNom(response['data']['nom'])
                // setEmail(response['data']['email'])
                // setRole(response['data']['role'])
                
                console.log(response);

            } catch (error) {
                console.error(error.message);
            }
            // setLoading(false);
        }

        fetchData();

    }, []);




    const { Auth, setAuth, ActionsFiltred, FindActionPerId } = useContext(AuthContext);
    let navigate = useNavigate();

    const MySwal = withReactContent(Swal)











    // useEffect(() => {


    //     if (Auth) {
    //         console.log('hi!');

    //     } else {
    //         console.log('no!');
    //         return navigate("/");
    //     }

    //     console.log(Auth);


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    const [Nom, setNom] = useState('')
    const [Email, setEmail] = useState('')
    const [Role, setRole] = useState('adminDZ')
    const [ShowPass, setShowPass] = useState(false)
    const [Shortdescription, setShortdescription] = useState('')
    const [Status, setStatus] = useState(0)

    const [Password, setPassword] = useState('')
    const [RePassword, setRePassword] = useState('')





    function onlyNumbers(str) {
        return /^[0-9]+$/.test(str);
    }

    function dateIsValid(date) {
        return date instanceof Date && !isNaN(date);
    }

    function afterToday(date, checkdate) {
        if (date <= checkdate) {
            return true;
        } else {
            return false;
        }
    }

    function isValidEmail(string) {
        // The regular expression used by [type="email"]
        var regex = /^([A-Za-z0-9\.])+\@([A-Za-z0-9])+\.([A-Za-z]{2,4})$/;
        // Test the string against the regular expression
        return regex.test(string)
    }



    const [Loading, setLoading] = useState(false)


    const [errors, seterrors] = useState({ Nom: '', Email: '', Role: '' })
    const [err, seterr] = useState([])

    const [Showerrors, setShowerrors] = useState(false)

    function Validate() {

        // if (Nom?.toString().trim().length > 100 || Nom?.toString().trim() == '') {
        //     seterrors((prev) => ({ ...prev, Nom: 'Veuillez entrer le nom !!' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Nom: '' }))
        // }

        // if (Email?.toString().trim().length > 200 || Email?.toString().trim() == '' || !isValidEmail(Email?.toString().trim())) {
        //     seterrors((prev) => ({ ...prev, Email: 'Veuillez saisir un e-mail valide !!' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Email: '' }))
        // }

        // if (Role?.toString().trim().length > 20 || Role?.toString().trim() == '' || Role?.toString().trim() !== 'adminDZ') {
        //     seterrors((prev) => ({ ...prev, Role: 'Veuillez entrer le rôle' }))
        // } else {
        //     seterrors((prev) => ({ ...prev, Role: '' }))
        // }



        if (Shortdescription?.toString().trim().length > 900) {
            seterrors((prev) => ({ ...prev, Shortdescription: 'Veuillez saisir une petite description 900 Char Max' }))
        } else {
            seterrors((prev) => ({ ...prev, Shortdescription: '' }))
        }






    }

    useEffect(() => {

        Validate();

    }, [Shortdescription])


    useEffect(() => {

        if (Showerrors) {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }
        }


    }, [Showerrors])








    const handleSubmit = async (event) => {
        event.preventDefault()



        setShowerrors(true)

        if (errors.Shortdescription !== '') {
            const matches = document.querySelectorAll(".err-cart-sum");

            for (let index = 0; index < matches.length; index++) {

                if (matches[index].textContent.trim() !== '') {

                    let position = matches[index].getBoundingClientRect();


                    window.scrollTo(position.left, position.top + window.scrollY - 120);
                    return
                }
                // if (matches[index].textContent.trim() !== '') {

                //     matches[index].scrollIntoView({behavior: "smooth"});
                //     return
                // }              
            }


            // matches[0].scrollIntoView({behavior: "smooth"});
            console.log(matches[0].offsetTop);
            // matches[0].focus();

            console.log(errors);
            return;
        }












        setLoading(true)


        axios.post(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/new_action`, { "status": Status,"notification" : Status == "1" ? Notification : "1" ,"mobile" :Mobile , "description": Shortdescription?.toString().trim(), "id_user": id }, {
            headers: {

                'Authorization': `Bearer ${Auth?.token}`
            }
        })
            .then(function (response) {
                if (response.data['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response.data);
                setLoading(false)
                if (response.data['error'] == true) {
                    // console.log(response.data['err']);
                    // setloading(false)
                    if (response.data['errorList'].constructor == Array) {
                        seterr(response.data['errorList'])
                    }



                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',
                        didClose: () => window.scrollTo(0, 0)



                    });

                    // window.scrollTo({
                    //     top: 0,
                    //     behavior: 'smooth',
                    //   })



                }

                if (response.data['error'] == false) {
                    console.log(response.data['err']);
                    Swal.fire({
                        title: 'Succès',
                        type: 'success',
                        text: 'Les données ont été ajoutées avec succès.',
                        timer: 2000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        icon: 'success',

                    }).then(function () {

                        return navigate(-1)
                        // window.location = "/listvendeuradmin";
                    });
                }



            }).catch(function (error) {
                // setLoading(false)
                console.log(error);
                if (error) {
                    setLoading(false)

                    Swal.fire({
                        title: 'Problème',
                        type: 'error',
                        text: "Il y a un problème lors de l'ajout de données.",
                        timer: 2000,
                        showConfirmButton: false,
                        icon: 'error',

                    });

                }
            });

    }




    // const Actions = [{ id: 0, name: 'En attente' }, { id: 1, name: 'Confirmé' }, { id: 2, name: 'Tentative échouée ' }, { id: 3, name: 'Ramassage ' }, { id: 4, name: 'Sortie ramassage' }, { id: 5, name: 'Sortie de livraison' }, { id: 6, name: 'Livré' }];

    // const Actions =[
    //     { id: 0, name: 'En attente' },
    //     { id: 13, name: "Intéressé par formation" },
    // { id: 3, name: "Client informé en attente paiement 1000" },
    // { id: 2, name: "Tentative échouée N01" },
    // { id: 4, name: "Paiement CCP effectuè" },
    // { id: 5, name: "Paiement en espèce effectuè" },

    // { id: 27, name: "Pas intéressé" },
    // { id: 28, name: "Annulation" },
    // { id: 29, name: "Client informer N01" },
    // { id: 30, name: "Faux numéro" },
    // { id: 31, name: "Test" },



    // { id: 6, name: "Confirmé en attente paiement 5000" },
    // { id: 7, name: "Confirmè WhatsApp" },
    // { id: 8, name: "Confirmè dossier incomplet" },
    // { id: 9, name: "Demande ramassage" },
    // { id: 10, name: "Demande ramassage WhatsApp" },
    // { id: 11, name: "Tentative échouée N02" },
    // { id: 32, name: "Faux numéro" },
    // { id: 33, name: "Test" },
    // { id: 12, name: "Annulation" },

    // { id: 1, name: "Accusé niveau 03" },
    // { id: 14, name: "Dossier complet" },
    // { id: 15, name: "Dossier incomplet" },
    // { id: 16, name: "Dossier en cours de traitement" },
    // { id: 17, name: "Dossier physique traité" },
    // { id: 18, name: "Panier en préparation" },
    // { id: 19, name: "Prêt pour expédition" },
    // { id: 20, name: "Sortie ramassage dossier" },
    // { id: 21, name: "Tentative échouée N03" },
    // { id: 22, name: "Ramassage dossier reçu" },
    // { id: 23, name: "Sortie ramassage pick up" },
    // { id: 24, name: "Ramassage pick up reçu" },
    // { id: 25, name: "Dossier reçu incomplet" },
    // { id: 26, name: "Programmé pour livraison" }
    // ];

    // console.log(Status);

    // function FindActionPerId(action) {

    //     let action_name = Actions?.find(o => o.id == action)

    //     if (action_name) {
    //         return action_name?.name;

    //     } else {
    //         return 'En attente'
    //     }


    // }

    const [Notification, setNotification] = useState('1')
    const [Mobile, setMobile] = useState('')

    console.log(Mobile);
    return (
        <div className='dashboard'>

            {Loading &&
                <Loader />
            }


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'> Nouvelle action <span>Iamal</span></div>
                    <GoBack url={"/listvendeuradmin"} />

                    <div className='page-content'>

                        {
                            ActionInfo ?
                                <div className='new-admin-btn mt-2 mb-4' onClick={() => navigate('/clientaction/' + id)}> <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="25.564" height="15.545" viewBox="0 0 28.564 15.545">
                                    <g id="eye-svgrepo-com" transform="translate(0 -100.736)">
                                        <g id="Groupe_430" data-name="Groupe 430" transform="translate(0 100.736)">
                                            <path id="Tracé_9271" data-name="Tracé 9271" d="M14.282,116.281a17.531,17.531,0,0,1-10-3.633A25.306,25.306,0,0,1,.2,109.037a.808.808,0,0,1,0-1.058,25.31,25.31,0,0,1,4.088-3.611,17.532,17.532,0,0,1,10-3.633,17.531,17.531,0,0,1,10,3.633,25.31,25.31,0,0,1,4.088,3.611.808.808,0,0,1,0,1.058,25.31,25.31,0,0,1-4.088,3.611A17.531,17.531,0,0,1,14.282,116.281ZM1.915,108.509a25.493,25.493,0,0,0,3.34,2.848,16.236,16.236,0,0,0,9.026,3.309,16.235,16.235,0,0,0,9.026-3.309,25.5,25.5,0,0,0,3.34-2.848,25.49,25.49,0,0,0-3.34-2.848,16.235,16.235,0,0,0-9.026-3.309,16.235,16.235,0,0,0-9.026,3.309A25.486,25.486,0,0,0,1.915,108.509Z" transform="translate(0 -100.736)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_431" data-name="Groupe 431" transform="translate(9.274 103.501)">
                                            <path id="Tracé_9272" data-name="Tracé 9272" d="M148.528,153.537a5.008,5.008,0,1,1,3.3-8.776.808.808,0,0,1-1.064,1.215,3.392,3.392,0,1,0,1.158,2.553.808.808,0,1,1,1.615,0A5.013,5.013,0,0,1,148.528,153.537Z" transform="translate(-143.52 -143.521)" fill="#fff" />
                                        </g>
                                        <g id="Groupe_432" data-name="Groupe 432" transform="translate(12.666 106.893)">
                                            <path id="Tracé_9273" data-name="Tracé 9273" d="M197.635,199.252a1.615,1.615,0,1,1,1.615-1.615A1.617,1.617,0,0,1,197.635,199.252Z" transform="translate(-196.02 -196.021)" fill="#fff" />
                                        </g>
                                    </g>
                                </svg> Voir toutes les actions  </div> : ''}
                        {/* {err.length > 0 && err.map((item, i) => (

                            <div className="alert alert-danger" role="alert">
                                {item}
                            </div>

                        ))
                        } */}
                        {
                            ActionInfo ?
                                <>
                                    <div className='last_action text-start mb-2 mt-0 pt-0'>
                                        <p className='mb-0 pb-0'>  Dernière action : <b> {FindActionPerId(ActionInfo?.Status_User)} </b>   </p>
                                    </div>
                                    <div>
                                        <span className='me-3'> Par : <b> {ActionInfo?.agent}</b></span> {ActionInfo?.description ? <><span>Description : </span>  <b>{ActionInfo?.description}</b></> : ''}
                                    </div>
                                </>
                                : ''
                        }
                        <form className="row g-5">
                            <div className='container py-3'>
                                {err?.length > 0 && err?.map((item, i) => (

                                    item.constructor == String ?
                                        <div className="alert-store" role="alert">
                                            - {item}
                                        </div>
                                        : ''

                                ))
                                }

                            </div>


                            {/* <div className="col-md-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" className='me-3' viewBox="0 0 24 24">
                                    <g id="Union_5" data-name="Union 5" fill="none" stroke-linejoin="round">
                                        <path d="M13.2,24V13.2H24V24ZM0,24V13.2H10.8V24ZM13.2,10.8V0H24V10.8ZM0,10.8V0H10.8V10.8Z" stroke="none" />
                                        <path d="M 22.10000038146973 22.10000038146973 L 22.10000038146973 15.09988975524902 L 15.09988975524902 15.09988975524902 L 15.09988975524902 22.10000038146973 L 22.10000038146973 22.10000038146973 M 8.900110244750977 22.10000038146973 L 8.900110244750977 15.09988975524902 L 1.899999976158142 15.09988975524902 L 1.899999976158142 22.10000038146973 L 8.900110244750977 22.10000038146973 M 22.10000038146973 8.900110244750977 L 22.10000038146973 1.899999976158142 L 15.09988975524902 1.899999976158142 L 15.09988975524902 8.900110244750977 L 22.10000038146973 8.900110244750977 M 8.900110244750977 8.900110244750977 L 8.900110244750977 1.899999976158142 L 1.899999976158142 1.899999976158142 L 1.899999976158142 8.900110244750977 L 8.900110244750977 8.900110244750977 M 24 24 L 13.19989013671875 24 L 13.19989013671875 13.19989013671875 L 24 13.19989013671875 L 24 24 Z M 10.80010986328125 24 L -1.77635683940025e-15 24 L -1.77635683940025e-15 13.19989013671875 L 10.80010986328125 13.19989013671875 L 10.80010986328125 24 Z M 24 10.80010986328125 L 13.19989013671875 10.80010986328125 L 13.19989013671875 -1.77635683940025e-15 L 24 -1.77635683940025e-15 L 24 10.80010986328125 Z M 10.80010986328125 10.80010986328125 L -1.77635683940025e-15 10.80010986328125 L -1.77635683940025e-15 -1.77635683940025e-15 L 10.80010986328125 -1.77635683940025e-15 L 10.80010986328125 10.80010986328125 Z" stroke="none" fill="#6d6d6d" />
                                    </g>
                                </svg>

                                <label htmlFor="inputEmail4" className="form-label">Nom de la personne (ADMIN)</label>
                                <input type="text" onChange={(e) => setNom(e.target.value.replace(/[^a-zA-Z\s]+/g, ''))} value={Nom} className="form-control" id="inputEmail4" />
                                <div className='err-cart-sum'>{Showerrors && errors.Nom !== '' ? errors.Nom : ''}</div>
                            </div> */}


                            <div className="col-md-6 mb-1 mt-0">
                                {/* <input type="text" onChange={(e) => setActedenaissance(e.target.value.replace(/[^0-9]+/g , ''))} value={Actedenaissance} className="form-control me-2" id="inputPassword4" /> */}
                                <label htmlFor="inputEmail4" className="form-label">Statut</label>
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setStatus(e.target.value)}>


                                    <option disabled selected={Status == null}>Statut</option>


                                    {Object?.entries(ActionsFiltred).map(([service, options]) => (
                                        <optgroup key={service} label={service}>
                                            {options?.map(item => (
                                                <option key={item?.id} selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                            ))}
                                        </optgroup>
                                    ))}

                                    {/* <optgroup label="Niveau 1 (centre d'appel)">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                     i < 11 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Niveau 2 (relation client)">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                    i >= 11 && i < 20 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Niveau 3 (relation banque)">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                    i >= 20 && i < 25 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Commercial">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                    i >= 25 && i < 27 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Ramassage">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                    i >= 27 && i < 33 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup>
                                <optgroup label="Livraison">
                                {Actions?.length > 0 && Actions?.map((item, i) => (
                                    i >= 33 && i < 35 ?
                                     <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    : ''
                                    ))
                                }
                                </optgroup> */}
                                    {/* {Actions?.length > 0 && Actions?.map((item, i) => (
                                        <option selected={Status == item?.id} value={item?.id}>{item?.name}</option>
                                    ))
                                    } */}
                                </select>
                                {/* <div className='alert-form'>{Showerr && (Typeactnaissnce == "" ? (infoperssonnelle['msgerr15'][Lang]) : '')}</div> */}
                            </div>

                            {
                                Status == 1  ?

                                <>
                                

                               
                            <div className="col-md-12 mb-1 mt-3">
                                <div className='mb-2'>Notification Par</div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions331" id="inlineRadio331" checked={Notification == '1'} onChange={(e) => setNotification(e.target.value)} defaultChecked={true} defaultValue="1" />
                                    <label className="form-check-label" htmlFor="inlineRadio331">SMS</label>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inlineRadioOptions131" id="inlineRadio131" checked={Notification == '2'} onChange={(e) => setNotification(e.target.value)} defaultValue="2" />
                                    <label className="form-check-label" htmlFor="inlineRadio131">WhatsApp</label>
                                </div>
                               

                            </div>

                            {Notification == '2' ?
                                        <>
                                            <label htmlFor="inputPassword4" className="form-label mt-2">Numéro (WhatsApp)</label>
                                            <div className="col-md-12 d-flex mt-0">
                                                <div className='row'>
                                                    <div className="col-md-12 col-12" >
                                                        <input maxlength="10" type="tel" placeholder="Mobile" onChange={(e) => setMobile(e.target.value.replace(/[^0-9]+/g, ''))} value={Mobile} className="form-control ms-2" id="inputPassword4" />
                                                        {/* <span className='note-form'>الكتابة بالأرقام</span> */}
                                                        {/* <div className='alert-form' dir={Lang === "ar" ? "rtl" : ""}>{Showerr && (CompteCCP == "" ? (situationprofessionnelle['msgerr18'][Lang]) : '')}</div> */}
                                                    </div>
                                                    </div>
                                            </div>
                                        </> : ''}
                            
                            </>
                             : ''
                            }








                            <div className="col-md-12">
                                <div className="form-floating">
                                    <textarea maxLength={6000} className="form-control" onChange={(e) => setShortdescription(e.target.value)} value={Shortdescription} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: '100px' }} />
                                    <label htmlFor="floatingTextarea2">Déscription</label>
                                    <div className='err-cart-sum'>{Showerrors && errors.Shortdescription !== '' ? errors.Shortdescription : ''}</div>
                                </div>
                            </div>










                            <div className="col-12 mt-5">
                                <button onClick={handleSubmit} className="btn btn-secondary">Créer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddAction