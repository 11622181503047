import { useState, useEffect, useContext } from 'react'
import { NavLink, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Menu from '../../components/DashboardAdmin/Menu';
import AuthContext from '../../Context/auth';
import axios from 'axios';
import Swal from 'sweetalert2'

const ListAdmins = () => {

    const { Auth, setAuth } = useContext(AuthContext);
    let navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page') ? searchParams.get('page') : '1';

    const [NomAmdin, setNomAmdin] = useState(searchParams.get('nomdeamdin') ? searchParams.get('nomdeamdin') : '')
    const [EmailAdmin, setEmailAdmin] = useState(searchParams.get('emailadmin') ? searchParams.get('emailadmin') : '')


    const [UrlPagination, setUrlPagination] = useState('')



    const [ListVentes, setListVentes] = useState([])

    const [PageNumber, setPageNumber] = useState(0)
    const [Loading, setLoading] = useState(false)
    const [CurrentPage, setCurrentPage] = useState(parseInt(page))

    const location = useLocation();

    console.log(location.pathname);

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);

            const AdminName = NomAmdin?.toString().trim() !== '' ? '&nomdeamdin=' + NomAmdin?.toString().trim() : '';
            const AdminEmail = EmailAdmin?.toString().trim() !== '' ? '&emailadmin=' + EmailAdmin?.toString().trim() : '';


            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair?page=${page}&role=adminDZ${AdminName}${AdminEmail}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                console.log(response);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }

        fetchData();

    }, []);


    console.log(ListVentes);



    // useEffect(() => {


    //     // if (Auth) {
    //     //   console.log('hi!');

    //     // }else{
    //     //   console.log('no!'); 
    //     //   return navigate("/");
    //     // }


    // }, [Auth])

    const [MenuShow, setMenuShow] = useState(true)

    const [windowSize, setWindowSize] = useState(getWindowSize());

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    console.log(windowSize);

    // const [MobileScreen, setMobileScreen] = useState(windowSize.innerWidth > 992 ? false : true)

    async function removeVendeur(id) {

        console.log(id);

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        Swal.fire({
            title: 'Êtes-vous sûr',
            text: "Voulez-vous vraiment supprimer ce vendeur ?!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui !',
            cancelButtonText: 'No !',
            // reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                // await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/delete_admin/${id}`);

                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair/del/delete_admin/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });

                await Swal.fire(
                    'Deleted!',
                    'Ce vendeur a été supprimé',
                    'success'
                );

                const updatefListVentes = ListVentes.filter(item => item.id !== id);
                setListVentes(updatefListVentes);
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                // Swal.fire(
                //     'Cancelled',
                //     'Your imaginary file is safe :)',
                //     'error'
                // )
            }
        })

        // Swal.fire({
        //     title: 'Succès',
        //     type: 'success',
        //     text: 'Les données ont été ajoutées avec succès.',
        //     timer: 2000,
        //     showConfirmButton: false,
        //     icon: 'success',

        // });




    }


    useEffect(() => {


        const AdminName = NomAmdin?.toString().trim() !== '' ? '&nomdeamdin=' + NomAmdin?.toString().trim() : '';
        const AdminEmail = EmailAdmin?.toString().trim() !== '' ? '&emailadmin=' + EmailAdmin?.toString().trim() : '';

        let url = AdminEmail + AdminName;
        setUrlPagination(url)



    }, [NomAmdin, EmailAdmin])

    function SearchQUERY() {
        const fetchData = async () => {
            setLoading(true);

            const AdminName = NomAmdin?.toString().trim() !== '' ? '&nomdeamdin=' + NomAmdin?.toString().trim() : '';
            const AdminEmail = EmailAdmin?.toString().trim() !== '' ? '&emailadmin=' + EmailAdmin?.toString().trim() : '';



            setSearchParams({ page: 1, ...(NomAmdin?.toString().trim() !== '' && { nomdeamdin: NomAmdin?.toString().trim() }), ...(EmailAdmin?.toString().trim() !== '' && { emailadmin: EmailAdmin?.toString().trim() })  })
            // setSearchParams({page : 1,nomdeboutique: NomBoutique.trim() !== '' ?  NomBoutique :  ''})

            try {
                const { data: response } = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/admin_DiarDzair?page=${1}&role=adminDZ${AdminName}${AdminEmail}`, {
                    headers: {
                        'Authorization': `Bearer ${Auth?.token}`
                    }
                });
                if (response['message'] == 'Token invalid') {
                    setAuth(false);
                    return navigate('/account')

                }
                // console.log(response[0]);
                setListVentes(response['data']);
                setPageNumber(response['AllNumberOfPage'])
                setCurrentPage(response['currentPage'])

                if (parseInt(page) !== response['currentPage']) {
                    setCurrentPage(1)
                    console.log("hhhhhhhhhhh");
                }
                // console.log(DarTaksitCat)

            } catch (error) {
                console.error(error.message);
            }
            setLoading(false);
        }
        fetchData();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            SearchQUERY();
        }
    };


    return (
        <div className='dashboard'>


            <div className=''>
                <div className=''>

                    <Menu MenuShow={MenuShow} setMenuShow={setMenuShow} />

                </div>
                <div className='page-client pt-lg-5' style={{ marginLeft: windowSize.innerWidth > 992 ? (MenuShow == true ? '328px' : '89px') : '0px' }}>
                    <div className='menu-mobile-section'>
                        <svg onClick={() => setMenuShow((p) => !p)} xmlns="http://www.w3.org/2000/svg" width="22.575" height="22.453" viewBox="0 0 22.575 22.453">
                            <g id="Groupe_429" data-name="Groupe 429" transform="translate(-177.5 120.5)">
                                <line id="Ligne_82" data-name="Ligne 82" x2="22.575" transform="translate(177.5 -119.5)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_83" data-name="Ligne 83" x2="22.575" transform="translate(177.5 -109.273)" fill="none" stroke="#fff" stroke-width="2" />
                                <line id="Ligne_84" data-name="Ligne 84" x2="22.575" transform="translate(177.5 -99.047)" fill="none" stroke="#fff" stroke-width="2" />
                            </g>
                        </svg>
                    </div>
                    <div className='page-title'>Liste Admins<span>Diar Dzair</span></div>

                   

                    <div className='new-admin-btn' onClick={() => navigate('/newadmin')}> Nouvel Admin </div>

                    <div className='store-search-section'>
                        <div>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Nom de Admin"} onChange={(e) => setNomAmdin(e.target.value)} value={NomAmdin} className="form-control me-3" id="inputEmail4" />
                        </div>

                        <div>
                            <input onKeyDown={handleKeyDown} type="text" placeholder={"Email"} onChange={(e) => setEmailAdmin(e.target.value)} value={EmailAdmin} className="form-control me-3" id="inputEmail4" />
                        </div>
                        
                       
                        <div className='search-btn-dashboard' onClick={SearchQUERY}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.621" height="30.621" viewBox="0 0 30.621 30.621">
                                <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(1.5 1.5)">
                                    <path id="Tracé_1" data-name="Tracé 1" d="M28.5,16.5a12,12,0,1,1-12-12,12,12,0,0,1,12,12Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Tracé_2" data-name="Tracé 2" d="M31.5,31.5l-6.525-6.525" transform="translate(-4.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>


                        </div>

                    </div>

                    <div className='dashboard-table'>
                        <table>

                            <thead>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Email</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>


                            {!Loading && <tbody>

                                {ListVentes?.length > 0 && ListVentes?.map((item, i) => (

                                    <tr>
                                        <td data-label="Nome">{item?.name}</td>
                                        <td data-label="Email">{item?.email}</td>

                                        <td className='d-flex justify-content-center align-items-center' data-label=" "><a href={`/updateadminpage/${item.id}?role=admin`}>
                                            <div className='update-btn'><svg xmlns="http://www.w3.org/2000/svg" width="21.167" height="20.59" viewBox="0 0 21.167 20.59">
                                                <g id="edit-246" transform="translate(-134.2 -129)">
                                                    <path id="Tracé_9269" data-name="Tracé 9269" d="M152.96,169.464a4,4,0,0,1-3.993,3.993h-9.63a4,4,0,0,1-3.993-3.993v-9.628a4,4,0,0,1,3.993-3.993h3.478V154.7h-3.478a5.143,5.143,0,0,0-5.137,5.137v9.628a5.143,5.143,0,0,0,5.137,5.137h9.628a5.143,5.143,0,0,0,5.137-5.137v-3.478h-1.144v3.478Z" transform="translate(0 -25.011)" fill="#fff" />
                                                    <path id="Tracé_9270" data-name="Tracé 9270" d="M278.443,129.922a3.15,3.15,0,0,0-4.453,0l-11.026,11.026a2.943,2.943,0,0,0-.836,1.675l-.509,3.558a.5.5,0,0,0,.493.571.66.66,0,0,0,.072-.005l3.558-.509a2.959,2.959,0,0,0,1.675-.836l11.026-11.026A3.149,3.149,0,0,0,278.443,129.922ZM264.3,141.227l9.333-9.333,2.832,2.835-9.33,9.333Zm1.278,3.877-2.709.386.386-2.709a1.835,1.835,0,0,1,.265-.715l2.771,2.771A1.747,1.747,0,0,1,265.581,145.1Zm12.053-11.541-.356.356-2.832-2.835.356-.356a2,2,0,1,1,2.832,2.835Z" transform="translate(-123.999)" fill="#fff" />
                                                </g>
                                            </svg>
                                            </div>
                                        </a>
                                            <a onClick={() => removeVendeur(item?.id)} > <div className='delete-btn my-1 ms-2 '><svg xmlns="http://www.w3.org/2000/svg" width="15" viewBox="0 0 46.27 55">
                                                <path id="icons8-trash" d="M27.587,1a1.31,1.31,0,1,0,0,2.619h13.1a1.31,1.31,0,1,0,0-2.619ZM16.675,7.111a5.675,5.675,0,0,0,0,11.349H50.286v27.5a7.431,7.431,0,0,1-7.421,7.421H25.4a7.431,7.431,0,0,1-7.421-7.421v-22.7a1.31,1.31,0,1,0-2.619,0v22.7A10.052,10.052,0,0,0,25.4,56h17.46A10.052,10.052,0,0,0,52.9,45.96V18.3A5.673,5.673,0,0,0,51.6,7.111Zm0,2.619H51.6a3.056,3.056,0,0,1,0,6.111H16.675a3.056,3.056,0,0,1,0-6.111ZM28.024,24.571a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,28.024,24.571Zm12.222,0a1.308,1.308,0,0,0-1.31,1.31V45.96a1.31,1.31,0,0,0,2.619,0V25.881A1.308,1.308,0,0,0,40.246,24.571Z" transform="translate(-11 -1)" fill="#fff" />
                                            </svg>
                                            </div>
                                            </a></td>
                                    </tr>
                                ))}
                            </tbody>}
                        </table>

                        {Loading &&
                            <div className='dashboard-loader'>
                                <div className='cart-sum-loader flex-column'>
                                    <p className='mb-1'>Chargement</p>
                                    <div><div className="spinner-grow text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                        <div className="spinner-grow text-danger" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow text-danger" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            ListVentes?.length === 0 || !ListVentes && !Loading &&
                            <div className='no-results'>Il n'y a pas de données</div>

                        }

                    </div>
                    <div>
                    {PageNumber > 1 &&
                            <div class="text-center mt-5 pt-1 mb-5">
                                <div class={`pagination ${Loading && 'd-none'} ${CurrentPage} ${PageNumber} ${CurrentPage < PageNumber}`}>
                                    {CurrentPage > 1 ? <a href={`?page=1${UrlPagination}`}  >{'<<'}</a> : <a>{'<<'}</a>}

                                    {CurrentPage !== 1 ? <a href={`?page=${parseInt(CurrentPage) - 1}${UrlPagination}`}>&laquo;</a> : <a>&laquo;</a>}



                                    {PageNumber > 0 && [...Array(PageNumber).keys()].map((item, i) => (

                                        <>
                                            {(item + 1 == (parseInt(CurrentPage) + 1) || item + 1 == (parseInt(CurrentPage) + 2) || item + 1 == (parseInt(CurrentPage) - 1) || item + 1 == (parseInt(CurrentPage) - 2) || item + 1 == CurrentPage) ?
                                                <a href={`?page=${item + 1}${UrlPagination}`} className={item + 1 == CurrentPage ? 'active' : ''}  >{item + 1}</a>
                                                : ''}
                                        </>

                                    ))}



                                    {CurrentPage < PageNumber ? <a href={`?page=${parseInt(CurrentPage) + 1}${UrlPagination}`}>&raquo;</a> : <a>&raquo;</a>}

                                    {CurrentPage < PageNumber ? <a href={`?page=${PageNumber}${UrlPagination}`}  >{'>>'}</a> : <a>{'>>'}</a>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ListAdmins