import React from 'react'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
// import Pdf from '../demande_d_ouverture_de_compte.pdf';

function PDF() {
    

  
    async function createPdf() {

        const hh = 'http://localhost:3000/demande_d_ouverture_de_compte.pdf'
  		const existingPdfBytes = await fetch(hh).then(res => res.arrayBuffer())
        // Create a new PDFDocument
        // const existingPdfBytes =  Pdf.arrayBuffer();
        // const pdfDocument = await PDFDocument.load(await file.arrayBuffer());
        // Load a PDFDocument from the existing PDF bytes
        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // Embed the Helvetica font
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

        // Get the first page of the document
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        // Get the width and height of the first page
        const { width, height } = firstPage.getSize()

        // Draw a string of text diagonally across the first page
        firstPage.drawText('This text was added with JavaScript!', {
            x: 5,
            y: height / 2 + 300,
            size: 50,
            font: helveticaFont,
            color: rgb(0.95, 0.1, 0.1),
            rotate: degrees(-45),
        })


        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()

        // Trigger the browser to download the PDF document

        const blob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        // download(pdfBytes, "pdf-lib_creation_example.pdf", "application/pdf");
    }

    return (
        <div onClick={() => createPdf()}>PDF</div>
    )
}

export default PDF